import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >

		<path fill="#010101" opacity="1.000000" stroke="none" 
	d="
M571.000000,1081.000000 
	C380.688568,1081.000000 190.877151,1081.000000 1.032859,1081.000000 
	C1.032859,721.063477 1.032859,361.126892 1.032859,1.095172 
	C360.894989,1.095172 720.790039,1.095172 1080.842529,1.095172 
	C1080.842529,360.999939 1080.842529,720.999939 1080.842529,1081.000000 
	C911.126465,1081.000000 741.313232,1081.000000 571.000000,1081.000000 
M834.207153,640.732910 
	C834.207153,640.732910 834.262207,640.412964 834.823669,639.987610 
	C836.036072,636.736206 837.555542,633.562744 838.405151,630.219116 
	C841.290161,618.865295 843.938354,607.451355 846.888672,595.301575 
	C847.194031,593.518677 847.499390,591.735718 848.222717,589.555908 
	C848.208557,589.017761 848.194397,588.479614 848.547607,587.181885 
	C849.327393,580.131775 850.107178,573.081665 851.111145,565.308960 
	C851.128052,564.556641 851.144958,563.804260 851.604919,562.457153 
	C851.851807,561.490784 852.313782,560.524414 852.313477,559.558105 
	C852.308594,542.935486 852.598083,526.301208 852.048340,509.695160 
	C851.775696,501.459381 849.937012,493.275452 848.874695,484.233612 
	C848.599121,483.181671 848.323547,482.129730 848.158813,480.315002 
	C842.668396,444.588684 830.266907,411.281708 812.667847,379.854004 
	C809.215454,373.688782 804.881104,368.017456 800.989990,362.049286 
	C800.989990,362.049286 800.918213,362.018494 800.904541,361.304749 
	C792.079651,346.320892 780.763428,333.296692 769.052734,320.557922 
	C747.504700,297.118225 722.860901,277.598846 695.265076,261.761108 
	C652.801636,237.390625 606.992432,223.947571 558.268250,220.809143 
	C523.442993,218.565964 489.084839,222.500336 455.403534,231.676697 
	C448.450623,233.570984 441.774902,236.482819 434.475922,238.923080 
	C434.475922,238.923080 434.027802,239.134705 433.236572,239.060715 
	C414.752960,244.930542 397.492554,253.473618 380.768402,263.163605 
	C354.539551,278.360596 331.029480,297.107208 310.375458,319.194519 
	C287.413452,343.749908 268.628967,371.275513 254.422104,401.876892 
	C241.778412,429.111206 233.055267,457.559692 228.759811,487.184692 
	C223.096130,526.246094 224.281982,565.218628 233.036392,603.837769 
	C242.358673,644.961975 259.440735,682.578552 283.784027,716.980408 
	C299.169556,738.723083 317.276581,757.942505 337.583557,775.057129 
	C345.284180,781.547119 353.830017,787.034241 362.255920,793.585510 
	C381.932434,807.727905 403.174896,819.102051 425.929962,827.215210 
	C442.335510,833.064514 459.167572,837.979309 476.135101,841.901978 
	C488.168976,844.684021 500.746368,845.114990 513.803406,846.801086 
	C514.552307,846.798706 515.301270,846.796265 516.716064,847.252869 
	C526.169678,847.381531 535.623291,847.510132 545.908325,847.747681 
	C547.573853,847.658264 549.239441,847.568848 551.800964,847.772766 
	C568.471313,845.997131 585.366150,845.378540 601.763550,842.196411 
	C632.563904,836.219238 661.865417,825.427185 689.439880,810.287598 
	C694.477112,807.521851 699.167664,804.124634 704.000000,801.000000 
	C704.000000,801.000000 704.013367,801.019653 704.740234,800.998352 
	C706.082092,800.349121 707.517090,799.841431 708.752075,799.029663 
	C733.999451,782.435791 756.821899,763.017578 776.646851,740.184937 
	C779.859192,736.485352 782.360046,732.167847 785.022095,728.145813 
	C785.022095,728.145813 785.188660,728.169556 785.829285,727.988770 
	C789.420227,723.500793 793.139587,719.107544 796.547607,714.484680 
	C798.248779,712.177246 799.380615,709.450134 800.975220,706.517883 
	C800.975220,706.517883 801.047424,706.078613 801.634827,705.883667 
	C802.056335,705.243225 802.477844,704.602722 803.104919,703.630981 
	C803.104919,703.630981 803.170959,703.246765 803.804688,703.015259 
	C810.701172,690.750000 817.692261,678.536194 824.423157,666.180664 
	C825.974121,663.333618 826.632874,660.000610 827.969788,656.520264 
	C827.969788,656.520264 828.047241,656.069519 828.601440,655.704712 
	C829.166931,654.114014 829.732361,652.523376 830.699280,650.399109 
	C830.881714,649.760620 831.064087,649.122192 831.753113,648.091003 
	C831.918396,647.328613 832.083679,646.566284 832.634521,645.192078 
	C832.775024,644.486511 832.915527,643.780884 833.514038,642.685120 
	C833.674866,642.116760 833.835693,641.548340 834.207153,640.732910 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M851.161865,563.051941 
	C851.144958,563.804260 851.128052,564.556641 850.685425,565.855469 
	C849.846680,567.609802 849.277161,568.788635 849.046692,570.030334 
	C847.797974,576.758423 846.540466,583.487427 845.473145,590.245667 
	C844.805847,594.470825 844.468506,598.748169 843.969116,603.385986 
	C843.603027,605.504089 843.251038,607.238342 842.601929,609.177979 
	C841.880249,611.250305 841.455750,613.117188 841.009399,615.355835 
	C840.906494,616.467896 840.825439,617.208191 840.587280,618.088623 
	C840.232117,618.439270 840.103333,618.686035 840.018250,619.306396 
	C839.669373,620.447998 839.346008,621.252258 838.753662,622.290039 
	C838.007507,624.677185 837.530273,626.830750 837.044189,629.003601 
	C837.035278,629.022827 837.013977,629.059570 836.709106,629.216614 
	C835.667114,631.278870 834.930054,633.184082 834.160400,635.415405 
	C834.091858,636.176880 834.055725,636.612366 833.758423,637.276489 
	C833.327881,638.651428 833.158569,639.797668 832.962158,641.300293 
	C832.975342,642.129456 833.015686,642.602417 833.056030,643.075317 
	C832.915527,643.780884 832.775024,644.486511 832.232727,645.522217 
	C831.553284,645.913269 831.274719,645.969482 830.740601,646.242065 
	C830.007507,647.979187 829.528931,649.495300 829.000977,651.307739 
	C828.643127,652.099792 828.334595,652.595520 827.868652,653.153687 
	C827.649780,653.309448 827.514648,653.445496 827.539734,653.488708 
	C827.646484,653.673035 827.800598,653.829956 827.927795,654.343689 
	C827.961243,655.150208 828.004272,655.609863 828.047241,656.069519 
	C828.047241,656.069519 827.969788,656.520264 827.561523,656.724609 
	C826.792175,656.999695 826.431152,657.070496 825.798401,657.315247 
	C825.367676,658.323181 825.208618,659.157104 824.888794,660.304260 
	C822.536194,664.805420 820.344360,668.993347 817.972168,673.201172 
	C817.711426,673.308655 817.631042,673.396179 817.550659,673.483765 
	C817.716492,673.635742 817.882324,673.787659 817.989990,674.287231 
	C817.286316,676.093994 816.640808,677.553101 815.675659,679.059570 
	C814.936157,679.765503 814.516296,680.424072 813.876465,681.346069 
	C812.778076,683.425903 811.899719,685.242249 810.805542,687.164368 
	C810.316711,687.474976 810.161743,687.747070 809.966248,688.402222 
	C808.498535,691.204102 807.083923,693.641663 805.911865,696.190735 
	C804.859741,698.479126 804.074158,700.889954 803.170959,703.246765 
	C803.170959,703.246765 803.104919,703.630981 802.686279,703.849243 
	C801.860840,704.737854 801.454163,705.408264 801.047485,706.078613 
	C801.047424,706.078613 800.975220,706.517883 800.509521,706.733032 
	C798.882568,707.688721 797.361145,708.173279 796.621826,709.213623 
	C793.231506,713.984375 789.998413,718.871338 786.851868,723.807678 
	C786.032715,725.092834 785.729126,726.706726 785.188660,728.169556 
	C785.188660,728.169556 785.022095,728.145813 784.755127,728.231323 
	C783.087646,729.366455 781.323547,730.152771 780.343323,731.506653 
	C770.865723,744.596497 759.096375,755.458252 746.753662,766.110657 
	C745.981201,766.778809 745.540466,767.433411 744.933533,768.370117 
	C743.217834,769.800232 741.668274,770.948303 739.723145,772.057800 
	C738.569397,770.989441 737.258423,769.979187 737.221069,768.923889 
	C737.188904,768.015320 738.488525,766.908020 739.414185,766.151428 
	C740.946655,764.898865 742.759155,763.981995 744.253967,762.692200 
	C745.747314,761.403870 746.744507,759.384888 748.393066,758.457581 
	C757.761230,753.188232 762.422546,743.199036 770.575806,736.589172 
	C774.284424,733.582397 775.597717,727.621094 778.295776,722.849915 
	C784.759766,717.643311 791.012756,712.718079 793.301636,704.462036 
	C794.594421,699.798767 796.416321,695.316711 801.166321,693.955017 
	C801.166321,690.762207 800.089050,687.277283 801.374146,685.554626 
	C804.830872,680.920959 809.920715,677.411804 809.396545,670.624268 
	C809.348938,670.007629 810.413818,669.361755 810.809326,668.645081 
	C813.194153,664.323120 816.648071,660.208008 817.609863,655.580078 
	C818.831970,649.700439 818.624573,643.661377 824.333069,639.807678 
	C824.761597,639.518250 824.828308,638.619568 824.965698,637.983093 
	C827.328125,627.039734 829.754028,616.109253 831.994385,605.140808 
	C833.216797,599.155823 832.828918,592.450684 835.472839,587.240295 
	C839.296265,579.705872 838.250488,571.911743 838.716919,564.390137 
	C839.663940,549.120239 839.017700,533.750305 838.970886,518.421753 
	C838.966431,516.965149 838.915405,515.324036 838.273926,514.085571 
	C835.345459,508.431305 833.096130,502.808563 834.817627,496.171173 
	C835.307678,494.281708 834.772888,491.755188 833.869751,489.937256 
	C828.838684,479.809723 828.724365,468.670135 827.127441,457.869415 
	C826.565796,454.071075 827.243408,450.309662 822.958801,448.045441 
	C821.849915,447.459412 821.301453,445.219116 821.076599,443.655151 
	C820.033203,436.398010 817.918762,429.599945 814.326843,423.124542 
	C811.889099,418.729889 810.404541,413.804382 808.521423,409.105103 
	C807.499329,406.554352 806.754150,403.865082 805.482544,401.449707 
	C804.250793,399.110199 802.249939,397.173004 801.038818,394.825928 
	C798.729553,390.350739 797.023499,385.548187 794.525757,381.191833 
	C792.734436,378.067535 789.930359,375.542725 787.812866,372.582520 
	C783.976624,367.219147 781.038879,360.978973 776.384216,356.499084 
	C771.428040,351.728943 767.638123,346.413055 764.161682,340.679321 
	C763.993774,340.402344 763.701111,340.200745 763.465515,339.965149 
	C758.669434,335.168732 753.986694,330.251953 749.051453,325.603333 
	C740.897766,317.923096 732.611694,310.382050 724.317871,302.852417 
	C721.036987,299.873871 717.829407,296.716797 714.164429,294.278320 
	C705.042053,288.208649 695.697815,282.469971 686.392273,276.679504 
	C684.089661,275.246674 681.639282,274.027679 679.178101,272.878906 
	C675.318420,271.077423 671.061829,269.917755 667.560181,267.599823 
	C659.521301,262.278595 651.573853,257.018433 642.970276,256.654449 
	C640.797485,253.626801 639.730957,250.445526 637.722778,249.654266 
	C631.219299,247.091843 624.390442,245.335236 617.644226,243.418823 
	C614.964722,242.657684 612.192871,242.141907 609.428284,241.778442 
	C604.698425,241.156586 600.450012,238.194443 596.256531,237.470291 
	C582.308472,235.061691 568.086365,233.606598 553.930237,233.220428 
	C540.745972,232.860779 527.500183,234.168320 514.304810,235.080185 
	C508.750580,235.463989 503.260956,236.748810 497.731323,237.550430 
	C496.458282,237.735001 494.556946,238.183105 493.940247,237.546127 
	C490.135376,233.616226 485.669495,234.020020 481.087158,234.638657 
	C487.451202,232.498825 493.045044,228.854797 500.280640,229.743179 
	C506.840027,230.548523 513.640076,229.452606 520.330139,229.117767 
	C527.018616,228.783020 533.710510,228.456467 540.385010,227.925644 
	C544.483032,227.599731 548.551086,226.897018 552.632629,226.363480 
	C551.842651,224.957397 551.025757,224.205994 550.223572,224.221451 
	C542.270203,224.374695 534.319946,224.709961 526.366455,224.811935 
	C525.588623,224.821930 524.795349,223.616943 524.442139,222.970398 
	C535.288879,222.964554 545.750793,222.332321 556.104614,223.120316 
	C569.712646,224.156006 583.320129,225.819656 596.768188,228.138809 
	C607.395264,229.971481 617.788635,233.172150 628.273254,235.815399 
	C650.486694,241.415619 671.129517,250.964142 691.070435,261.902252 
	C702.104309,267.954559 712.553711,275.190918 722.772461,282.577240 
	C732.927979,289.917847 742.831421,297.706116 752.151489,306.071136 
	C759.099182,312.306793 765.124817,319.616699 771.228149,326.733704 
	C778.429382,335.131104 785.228210,343.872284 792.291809,352.389618 
	C795.040649,355.704254 798.035461,358.814911 800.918213,362.018494 
	C800.918213,362.018494 800.989990,362.049286 800.968384,362.460297 
	C801.611694,364.522278 802.078369,366.291138 802.977112,367.803040 
	C806.158875,373.155060 809.801636,378.254944 812.687195,383.755920 
	C816.829590,391.652771 820.932068,399.631439 824.205505,407.909668 
	C829.826538,422.124512 835.092346,436.498871 839.899902,451.007507 
	C842.055603,457.513214 842.541199,464.554840 844.183472,471.255524 
	C845.013428,474.641907 846.731445,477.810638 848.047974,481.077759 
	C848.323547,482.129730 848.599121,483.181671 848.599976,484.959534 
	C849.037720,497.373016 849.979492,509.052490 850.392883,520.750610 
	C850.749084,530.830627 850.446289,540.932434 850.551514,551.023376 
	C850.593384,555.034546 850.949097,559.042542 851.161865,563.051941 
z"/>
<path fill="#0673B7" opacity="1.000000" stroke="none" 
	d="
M777.997559,723.000000 
	C775.597717,727.621094 774.284424,733.582397 770.575806,736.589172 
	C762.422546,743.199036 757.761230,753.188232 748.393066,758.457581 
	C746.744507,759.384888 745.747314,761.403870 744.253967,762.692200 
	C742.759155,763.981995 740.946655,764.898865 739.414185,766.151428 
	C738.488525,766.908020 737.188904,768.015320 737.221069,768.923889 
	C737.258423,769.979187 738.569397,770.989441 739.568054,772.324585 
	C738.901062,773.460388 737.993469,774.290894 737.008057,774.776001 
	C736.027161,774.128906 735.124023,773.827209 734.220947,773.525513 
	C734.147766,775.012146 734.074646,776.498718 733.785278,778.015991 
	C733.569153,778.046631 733.132935,778.065491 732.799438,777.917542 
	C730.826660,777.769592 729.187378,777.769592 727.548096,777.769592 
	C727.545044,778.348267 727.542053,778.926941 727.539001,779.505615 
	C728.731445,779.695251 729.923950,779.884888 730.902100,780.333130 
	C726.527161,783.582947 722.286926,786.470703 718.226318,789.592102 
	C713.407959,793.296265 708.744202,797.201599 704.013428,801.019653 
	C704.013367,801.019653 704.000000,801.000000 703.621582,800.977783 
	C702.133667,801.224548 700.917847,801.286682 699.931152,801.794189 
	C689.406433,807.207764 679.160339,813.240479 668.356323,818.013489 
	C655.717773,823.596924 642.759766,828.579041 629.637451,832.903076 
	C620.776917,835.822754 611.450317,837.476929 602.229004,839.117004 
	C589.424866,841.394287 576.518555,843.093323 563.667603,845.113586 
	C559.395020,845.785278 555.158142,846.684326 550.904968,847.479431 
	C549.239441,847.568848 547.573853,847.658264 545.332520,847.347595 
	C535.187866,846.896301 525.619019,846.845093 516.050171,846.793823 
	C515.301270,846.796265 514.552307,846.798706 513.297363,846.365967 
	C511.886444,845.630737 511.002472,845.191833 510.073395,845.051636 
	C498.803314,843.351624 487.356689,842.425720 476.293091,839.850220 
	C461.210541,836.339050 446.072723,832.505676 431.596771,827.101074 
	C416.218506,821.359680 401.481018,813.836365 386.687073,806.632446 
	C381.732758,804.219910 377.465546,800.396240 372.929443,796.831909 
	C376.985229,792.616882 377.104614,789.095764 372.176666,785.929749 
	C371.994080,785.812439 372.053406,785.318420 372.374939,785.011169 
	C378.062744,787.425171 383.569183,789.483337 388.634155,792.327576 
	C393.728699,795.188354 398.214996,799.121826 403.271240,802.063660 
	C410.930756,806.520081 418.882141,810.471802 426.652313,814.742065 
	C431.186890,817.234192 435.365082,820.583252 440.143280,822.372803 
	C446.603638,824.792175 453.427917,826.357971 460.200348,827.800598 
	C473.117981,830.551941 485.665497,835.609253 499.308014,834.176758 
	C501.138000,833.984619 503.115417,835.136597 505.016541,835.693604 
	C508.735596,836.783386 512.424805,838.735168 516.169006,838.842407 
	C529.648315,839.228516 543.200256,839.704895 556.610535,838.669006 
	C562.500061,838.213989 568.134583,837.094604 573.964050,838.698914 
	C574.665222,838.891785 576.179321,838.241577 576.428711,837.623779 
	C578.085815,833.518921 580.695618,835.213684 583.392883,836.137146 
	C587.376099,837.500977 591.447693,839.400635 595.569031,836.118530 
	C594.785767,835.279053 594.113892,834.558899 593.650146,834.061890 
	C607.396362,831.371338 620.949646,828.825500 634.448853,826.019714 
	C639.327026,825.005798 644.265198,823.598083 644.685974,816.998108 
	C646.500244,816.998108 647.994141,817.120361 649.461853,816.976318 
	C654.942749,816.438171 660.599854,817.039062 664.521912,811.339661 
	C666.091431,809.058960 669.415588,806.609253 673.236816,809.329834 
	C673.865723,809.777588 675.861938,808.887451 676.857788,808.130737 
	C680.152405,805.627136 683.083496,802.610352 686.507629,800.318848 
	C694.776306,794.785706 703.284973,789.612549 711.621155,784.178223 
	C718.866028,779.455383 723.395020,771.104248 732.334412,768.595520 
	C732.722900,768.486511 732.921509,767.808228 733.280884,767.472595 
	C740.359131,760.862244 747.505066,754.323059 754.505615,747.631653 
	C757.142151,745.111450 760.760254,742.614380 761.648743,739.463379 
	C764.019775,731.054199 770.323853,727.014648 777.243896,722.998718 
	C777.662598,722.999939 777.830078,722.999939 777.997559,723.000000 
M564.389282,842.348206 
	C563.550659,842.045166 562.712097,841.742188 561.873474,841.439148 
	C561.792664,841.880493 561.711853,842.321777 561.631042,842.763123 
	C562.395630,842.763123 563.160217,842.763123 564.389282,842.348206 
M520.428833,844.484558 
	C520.428833,844.484558 520.520142,844.434265 520.428833,844.484558 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M361.990601,792.978455 
	C353.830017,787.034241 345.284180,781.547119 337.583557,775.057129 
	C317.276581,757.942505 299.169556,738.723083 283.784027,716.980408 
	C259.440735,682.578552 242.358673,644.961975 233.036392,603.837769 
	C224.281982,565.218628 223.096130,526.246094 228.759811,487.184692 
	C233.055267,457.559692 241.778412,429.111206 254.422104,401.876892 
	C268.628967,371.275513 287.413452,343.749908 310.375458,319.194519 
	C331.029480,297.107208 354.539551,278.360596 380.768402,263.163605 
	C397.492554,253.473618 414.752960,244.930542 433.582611,239.443787 
	C430.951904,241.544205 427.975159,243.261551 424.701630,245.002045 
	C423.932373,245.342117 423.459961,245.659058 422.989075,245.987076 
	C422.990570,245.998169 422.973938,245.976929 422.627899,245.981567 
	C421.165436,246.966415 420.048981,247.946609 418.612244,248.927368 
	C417.864929,248.923248 417.437927,248.918549 416.824921,248.698517 
	C415.736542,248.957291 414.834137,249.431381 413.552063,249.925751 
	C406.577209,252.775177 399.906769,255.444824 393.420166,258.504242 
	C390.411682,259.923187 387.760956,262.100647 384.637207,263.978760 
	C383.542358,264.643890 382.758362,265.264587 381.654083,266.043823 
	C374.530304,270.768799 367.726807,275.335205 360.696899,279.920471 
	C360.470459,279.939331 360.016510,279.959290 359.696533,280.089813 
	C351.581909,286.148712 343.787231,292.077087 335.687073,298.161743 
	C333.566406,300.187897 331.751190,302.057709 329.630920,304.077576 
	C327.142578,306.183441 324.840240,308.023682 322.802643,310.121094 
	C319.404846,313.618713 316.176483,317.280975 312.570129,320.937500 
	C311.507172,321.666412 310.750946,322.331757 309.925659,323.333588 
	C306.019440,328.154358 302.140594,332.603729 298.352295,337.128937 
	C281.915619,356.762756 268.500580,378.367767 257.247162,401.295135 
	C253.793915,408.330688 251.520355,415.943878 248.681992,423.283051 
	C245.830597,430.655914 242.105972,437.812012 240.254120,445.425537 
	C236.312592,461.630188 232.722824,477.974792 230.176529,494.445648 
	C228.411896,505.860229 228.385345,517.574036 228.037369,529.167786 
	C227.732742,539.316956 227.150635,549.592041 228.326126,559.619873 
	C230.094391,574.704346 232.672684,589.762390 236.055862,604.569092 
	C239.246460,618.532837 243.115387,632.462280 248.175247,645.843689 
	C253.120483,658.922058 259.738159,671.394043 266.036743,683.923584 
	C269.198914,690.214050 273.283478,696.040833 277.001831,702.414307 
	C278.350983,704.509827 279.649170,706.268311 281.114746,708.331787 
	C286.352325,715.495972 291.250244,722.490662 296.524200,729.189331 
	C314.034515,751.429993 334.020782,771.068176 357.595123,786.932922 
	C359.507904,788.220215 360.649078,790.654114 362.096863,792.656372 
	C362.044861,792.757568 361.990601,792.978455 361.990601,792.978455 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M800.911377,361.661621 
	C798.035461,358.814911 795.040649,355.704254 792.291809,352.389618 
	C785.228210,343.872284 778.429382,335.131104 771.228149,326.733704 
	C765.124817,319.616699 759.099182,312.306793 752.151489,306.071136 
	C742.831421,297.706116 732.927979,289.917847 722.772461,282.577240 
	C712.553711,275.190918 702.104309,267.954559 691.070435,261.902252 
	C671.129517,250.964142 650.486694,241.415619 628.273254,235.815399 
	C617.788635,233.172150 607.395264,229.971481 596.768188,228.138809 
	C583.320129,225.819656 569.712646,224.156006 556.104614,223.120316 
	C545.750793,222.332321 535.288879,222.964554 524.040283,222.979156 
	C520.821289,223.310287 518.437012,223.627151 515.848633,223.713043 
	C514.746277,223.637421 513.847961,223.792755 512.499512,223.948853 
	C504.366333,224.635849 496.683319,225.322067 488.670380,226.138458 
	C486.214508,227.163498 484.088501,228.058365 481.536682,228.955246 
	C478.726135,229.281662 476.341431,229.606064 473.627991,229.944794 
	C472.530029,230.275452 471.760803,230.591797 470.560425,230.917358 
	C464.099457,231.999802 458.069641,233.073044 451.733643,234.160004 
	C450.625183,234.756805 449.822906,235.339890 448.633667,235.945328 
	C443.821625,236.953674 439.396545,237.939667 434.971436,238.925659 
	C441.774902,236.482819 448.450623,233.570984 455.403534,231.676697 
	C489.084839,222.500336 523.442993,218.565964 558.268250,220.809143 
	C606.992432,223.947571 652.801636,237.390625 695.265076,261.761108 
	C722.860901,277.598846 747.504700,297.118225 769.052734,320.557922 
	C780.763428,333.296692 792.079651,346.320892 800.911377,361.661621 
z"/>
<path fill="#027ECA" opacity="1.000000" stroke="none" 
	d="
M434.723694,238.924377 
	C439.396545,237.939667 443.821625,236.953674 448.617401,236.330688 
	C448.627319,237.380219 448.427826,238.259445 447.877777,238.719711 
	C445.581757,240.640915 443.187927,242.446030 440.813324,244.272003 
	C440.057953,244.852829 439.315430,245.516693 438.456635,245.885498 
	C433.985077,247.805679 429.550690,249.851746 424.962738,251.450455 
	C420.350128,253.057755 415.491577,253.967682 410.900970,255.626434 
	C408.609009,256.454590 406.811249,258.580261 404.551880,259.582245 
	C398.518158,262.258148 393.266968,265.433044 392.604767,273.001953 
	C391.137604,273.004974 390.070587,273.007263 388.635803,273.006592 
	C386.638763,272.375031 385.009460,271.746429 382.157043,270.645966 
	C381.354919,271.569031 379.898621,273.392334 378.287598,275.066742 
	C375.348969,278.120819 372.375488,281.147858 369.271027,284.029388 
	C368.749512,284.513458 367.438385,284.440765 366.590363,284.229950 
	C362.162872,283.129211 358.540955,285.424774 356.865692,289.458099 
	C355.896606,291.791321 353.849121,294.784851 351.738708,295.320282 
	C346.230225,296.717865 344.368408,299.766724 345.003052,305.001099 
	C345.000000,305.000000 345.002136,305.006104 344.591187,305.003906 
	C339.140167,305.373993 339.268066,299.698242 335.992554,298.005463 
	C343.787231,292.077087 351.581909,286.148712 359.782166,280.254364 
	C360.432892,280.159454 360.678101,280.030518 360.923309,279.901611 
	C367.726807,275.335205 374.530304,270.768799 381.693359,266.380981 
	C382.647827,267.127472 383.242737,267.695374 383.837646,268.263275 
	C384.207794,266.820282 384.577911,265.377258 384.948059,263.934265 
	C387.760956,262.100647 390.411682,259.923187 393.420166,258.504242 
	C399.906769,255.444824 406.577209,252.775177 413.841034,250.066360 
	C415.343414,249.762405 416.177155,249.338135 417.010895,248.913849 
	C417.437927,248.918549 417.864929,248.923248 418.982300,248.945541 
	C420.773071,247.967743 421.873505,246.972336 422.973938,245.976929 
	C422.973938,245.976929 422.990570,245.998169 423.287781,245.970535 
	C424.056122,245.621567 424.527283,245.300232 424.998444,244.978882 
	C427.975159,243.261551 430.951904,241.544205 433.978210,239.480774 
	C434.027802,239.134705 434.475922,238.923080 434.723694,238.924377 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M551.352966,847.626099 
	C555.158142,846.684326 559.395020,845.785278 563.667603,845.113586 
	C576.518555,843.093323 589.424866,841.394287 602.229004,839.117004 
	C611.450317,837.476929 620.776917,835.822754 629.637451,832.903076 
	C642.759766,828.579041 655.717773,823.596924 668.356323,818.013489 
	C679.160339,813.240479 689.406433,807.207764 699.931152,801.794189 
	C700.917847,801.286682 702.133667,801.224548 703.630737,800.987305 
	C699.167664,804.124634 694.477112,807.521851 689.439880,810.287598 
	C661.865417,825.427185 632.563904,836.219238 601.763550,842.196411 
	C585.366150,845.378540 568.471313,845.997131 551.352966,847.626099 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M372.884277,797.217590 
	C377.465546,800.396240 381.732758,804.219910 386.687073,806.632446 
	C401.481018,813.836365 416.218506,821.359680 431.596771,827.101074 
	C446.072723,832.505676 461.210541,836.339050 476.293091,839.850220 
	C487.356689,842.425720 498.803314,843.351624 510.073395,845.051636 
	C511.002472,845.191833 511.886444,845.630737 512.936279,846.263062 
	C500.746368,845.114990 488.168976,844.684021 476.135101,841.901978 
	C459.167572,837.979309 442.335510,833.064514 425.929962,827.215210 
	C403.174896,819.102051 381.932434,807.727905 362.123260,793.281982 
	C361.990601,792.978455 362.044861,792.757568 362.413574,792.796387 
	C364.196899,793.561951 365.611572,794.288635 367.190613,795.245483 
	C367.880035,795.650818 368.405121,795.825928 368.947510,796.010864 
	C368.964783,796.020752 369.004211,796.025879 369.112946,796.237183 
	C369.422363,796.710815 369.682709,796.882874 370.364288,796.993652 
	C371.445343,797.087646 372.164795,797.152588 372.884277,797.217590 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M848.103394,480.696381 
	C846.731445,477.810638 845.013428,474.641907 844.183472,471.255524 
	C842.541199,464.554840 842.055603,457.513214 839.899902,451.007507 
	C835.092346,436.498871 829.826538,422.124512 824.205505,407.909668 
	C820.932068,399.631439 816.829590,391.652771 812.687195,383.755920 
	C809.801636,378.254944 806.158875,373.155060 802.977112,367.803040 
	C802.078369,366.291138 801.611694,364.522278 800.950195,362.494812 
	C804.881104,368.017456 809.215454,373.688782 812.667847,379.854004 
	C830.266907,411.281708 842.668396,444.588684 848.103394,480.696381 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M737.085938,775.121460 
	C737.993469,774.290894 738.901062,773.460388 739.963623,772.363098 
	C741.668274,770.948303 743.217834,769.800232 745.236633,768.304016 
	C746.165649,767.336304 746.625488,766.716675 747.085266,766.097107 
	C759.096375,755.458252 770.865723,744.596497 780.343323,731.506653 
	C781.323547,730.152771 783.087646,729.366455 784.838928,728.226379 
	C782.360046,732.167847 779.859192,736.485352 776.646851,740.184937 
	C756.821899,763.017578 733.999451,782.435791 708.752075,799.029663 
	C707.517090,799.841431 706.082092,800.349121 704.376831,801.009033 
	C708.744202,797.201599 713.407959,793.296265 718.226318,789.592102 
	C722.286926,786.470703 726.527161,783.582947 731.152222,780.191101 
	C732.122070,779.215393 732.627502,778.640442 733.132935,778.065491 
	C733.132935,778.065491 733.569153,778.046631 734.064087,777.888672 
	C735.401367,776.860962 736.243652,775.991211 737.085938,775.121460 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M851.383423,562.754517 
	C850.949097,559.042542 850.593384,555.034546 850.551514,551.023376 
	C850.446289,540.932434 850.749084,530.830627 850.392883,520.750610 
	C849.979492,509.052490 849.037720,497.373016 848.566040,485.376770 
	C849.937012,493.275452 851.775696,501.459381 852.048340,509.695160 
	C852.598083,526.301208 852.308594,542.935486 852.313477,559.558105 
	C852.313782,560.524414 851.851807,561.490784 851.383423,562.754517 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M811.021240,687.058533 
	C811.899719,685.242249 812.778076,683.425903 814.177734,681.271973 
	C815.131104,680.293701 815.563232,679.652954 815.995361,679.012207 
	C816.640808,677.553101 817.286316,676.093994 818.011292,674.097412 
	C818.090759,673.559937 818.152527,673.181274 818.152527,673.181274 
	C820.344360,668.993347 822.536194,664.805420 825.128418,660.097900 
	C825.709167,658.765930 825.889648,657.953552 826.070129,657.141235 
	C826.431152,657.070496 826.792175,656.999695 827.427734,656.910034 
	C826.632874,660.000610 825.974121,663.333618 824.423157,666.180664 
	C817.692261,678.536194 810.701172,690.750000 803.487793,703.130981 
	C804.074158,700.889954 804.859741,698.479126 805.911865,696.190735 
	C807.083923,693.641663 808.498535,691.204102 810.162842,688.276367 
	C810.766907,687.628601 810.934631,687.369873 811.021240,687.058533 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M843.983154,603.002075 
	C844.468506,598.748169 844.805847,594.470825 845.473145,590.245667 
	C846.540466,583.487427 847.797974,576.758423 849.046692,570.030334 
	C849.277161,568.788635 849.846680,567.609802 850.573364,566.216797 
	C850.107178,573.081665 849.327393,580.131775 848.167725,587.658325 
	C847.793518,588.740723 847.799133,589.346802 847.804749,589.952820 
	C847.499390,591.735718 847.194031,593.518677 846.557617,595.929565 
	C845.478699,598.705688 844.730896,600.853882 843.983154,603.002075 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M843.969055,603.385986 
	C844.730896,600.853882 845.478699,598.705688 846.451782,596.308899 
	C843.938354,607.451355 841.290161,618.865295 838.405151,630.219116 
	C837.555542,633.562744 836.036072,636.736206 834.460815,639.781250 
	C834.071777,638.732483 834.045715,637.890137 834.019592,637.047791 
	C834.055725,636.612366 834.091858,636.176880 834.480591,635.308105 
	C835.560120,632.936279 836.287048,630.997925 837.013916,629.059570 
	C837.013977,629.059570 837.035278,629.022827 837.298279,628.755981 
	C838.048462,626.344910 838.535583,624.200684 839.022705,622.056519 
	C839.346008,621.252258 839.669373,620.447998 840.205444,619.191895 
	C840.649109,618.526550 840.757812,618.262695 840.744385,617.948425 
	C840.825439,617.208191 840.906494,616.467896 841.259277,615.112671 
	C841.987061,612.655945 842.443115,610.814270 842.899109,608.972534 
	C843.251038,607.238342 843.603027,605.504089 843.969055,603.385986 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M785.508972,728.079163 
	C785.729126,726.706726 786.032715,725.092834 786.851868,723.807678 
	C789.998413,718.871338 793.231506,713.984375 796.621826,709.213623 
	C797.361145,708.173279 798.882568,707.688721 800.406860,706.930481 
	C799.380615,709.450134 798.248779,712.177246 796.547607,714.484680 
	C793.139587,719.107544 789.420227,723.500793 785.508972,728.079163 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M516.383118,847.023376 
	C525.619019,846.845093 535.187866,846.896301 544.916809,847.293091 
	C535.623291,847.510132 526.169678,847.381531 516.383118,847.023376 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M828.324341,655.887085 
	C828.004272,655.609863 827.961243,655.150208 827.958313,654.118164 
	C827.998413,653.545837 828.026001,653.091248 828.026001,653.091248 
	C828.334595,652.595520 828.643127,652.099792 829.209412,651.294678 
	C829.743042,650.951233 830.019897,650.933716 830.297791,650.932678 
	C829.732361,652.523376 829.166931,654.114014 828.324341,655.887085 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M830.498535,650.665894 
	C830.019897,650.933716 829.743042,650.951233 829.258789,650.998291 
	C829.528931,649.495300 830.007507,647.979187 830.755371,646.653809 
	C831.098694,647.390869 831.172607,647.937317 831.246460,648.483704 
	C831.064087,649.122192 830.881714,649.760620 830.498535,650.665894 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M801.341187,705.981140 
	C801.454163,705.408264 801.860840,704.737854 802.583496,704.014832 
	C802.477844,704.602722 802.056335,705.243225 801.341187,705.981140 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M848.013733,589.754395 
	C847.799133,589.346802 847.793518,588.740723 847.984009,588.038086 
	C848.194397,588.479614 848.208557,589.017761 848.013733,589.754395 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M833.758423,637.276489 
	C834.045715,637.890137 834.071777,638.732483 834.180054,639.993896 
	C834.262207,640.412964 834.207153,640.732910 833.850037,640.845459 
	C833.325012,640.953308 833.157166,640.948669 832.989258,640.943970 
	C833.158569,639.797668 833.327881,638.651428 833.758423,637.276489 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M831.499817,648.287354 
	C831.172607,647.937317 831.098694,647.390869 831.009888,646.432739 
	C831.274719,645.969482 831.553284,645.913269 832.039917,645.828125 
	C832.083679,646.566284 831.918396,647.328613 831.499817,648.287354 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M832.962158,641.300293 
	C833.157166,640.948669 833.325012,640.953308 833.744751,640.968994 
	C833.835693,641.548340 833.674866,642.116760 833.285034,642.880249 
	C833.015686,642.602417 832.975342,642.129456 832.962158,641.300293 
z"/>
<path fill="#0175BB" opacity="1.000000" stroke="none" 
	d="
M372.929443,796.831909 
	C372.164795,797.152588 371.445343,797.087646 370.248474,796.788574 
	C369.571350,796.297180 369.315735,796.120972 369.004211,796.025879 
	C369.004211,796.025879 368.964783,796.020752 368.963806,795.775879 
	C368.962860,795.530945 368.970398,795.059753 368.981750,794.794250 
	C368.999054,794.351624 369.005035,794.174561 369.295715,793.962891 
	C369.722321,793.287537 369.864197,792.646912 370.150879,791.671753 
	C370.039520,790.266907 369.895020,788.306885 369.510620,788.258667 
	C362.878357,787.426575 358.280457,783.303589 353.584900,779.099426 
	C346.577515,772.825562 339.196472,766.966675 332.238647,760.640625 
	C326.958191,755.839722 321.902069,750.756775 317.094025,745.482178 
	C312.905121,740.886719 309.102478,735.924133 305.332214,730.969238 
	C303.693726,728.815979 302.476624,726.320435 301.232391,723.895996 
	C300.346039,722.168945 300.019958,720.107117 298.949768,718.529907 
	C294.383514,711.800354 287.454315,706.765015 285.604706,698.036743 
	C284.924530,694.827087 281.934692,695.324585 279.705963,697.973267 
	C278.942657,697.632568 278.474243,697.316040 278.265381,696.772827 
	C279.600189,694.290222 281.960175,691.681824 281.459839,689.858276 
	C280.638794,686.866028 277.786194,684.487000 276.109558,681.659973 
	C273.912231,677.955078 271.540466,674.236633 270.126160,670.213806 
	C268.326294,665.094299 269.253235,658.862366 263.303711,655.605713 
	C262.527527,655.180908 262.336670,653.618958 261.954376,652.554993 
	C260.680969,649.011230 260.455109,644.086975 257.948395,642.233276 
	C254.462769,639.655701 254.308197,637.343750 255.356628,633.923645 
	C256.129578,632.517761 256.546539,631.188293 256.963470,629.858765 
	C256.393219,629.768494 255.822952,629.678223 255.252701,629.587891 
	C255.168259,631.058594 255.083832,632.529297 254.652771,633.937073 
	C253.206528,631.916626 252.106873,629.959106 251.006195,627.667175 
	C251.004059,626.886902 251.002945,626.440979 251.001465,625.567688 
	C249.676529,618.354492 247.866425,611.620850 247.132233,604.771790 
	C245.758850,591.959717 245.046326,579.077698 243.956650,566.233398 
	C243.809952,564.504395 243.091400,562.819763 242.602051,561.124878 
	C241.749557,558.172180 240.123367,555.225769 240.130402,552.279785 
	C240.160110,539.838989 240.599319,527.396667 241.044693,514.960510 
	C241.225098,509.922455 240.537292,504.315002 242.518906,500.014648 
	C245.055527,494.509888 244.893021,488.769531 246.539948,483.485657 
	C248.108765,478.452362 248.987473,473.694946 249.051605,468.578339 
	C249.063385,467.638184 249.497421,466.675476 249.860031,465.772766 
	C251.067581,462.766693 252.454849,459.827179 253.540894,456.779358 
	C254.778381,453.306488 256.763275,449.708008 256.635071,446.230011 
	C256.429901,440.665558 256.456909,435.633698 259.927460,430.861053 
	C262.122131,427.842987 263.712982,424.304962 265.132751,420.820129 
	C268.706665,412.047974 271.767883,403.060211 275.529999,394.373413 
	C279.063019,386.215454 287.736053,380.761139 288.124908,370.852509 
	C288.141418,370.431915 288.626343,370.006378 288.942810,369.624359 
	C292.323212,365.544128 295.667664,361.432861 299.112000,357.407135 
	C304.497223,351.112976 311.151855,345.695862 313.039551,336.660919 
	C314.053040,335.550293 315.026978,334.777527 316.201660,334.226440 
	C316.934967,333.632477 317.467468,332.816833 318.006439,331.632629 
	C321.546204,327.331207 325.042419,323.363586 328.659943,319.509796 
	C329.110107,319.030243 330.203461,319.154358 331.344818,318.959106 
	C334.452301,315.071777 337.123505,311.205566 341.514374,308.858948 
	C342.952545,308.090332 343.854797,306.318909 345.002136,305.006104 
	C345.002136,305.006104 345.000000,305.000000 345.373779,304.947571 
	C347.971680,301.717010 350.344971,298.860809 354.906860,299.769440 
	C355.352539,299.858215 356.040039,299.168732 356.493103,298.717743 
	C361.384399,293.848816 364.830261,287.188446 372.565033,285.842896 
	C372.980957,285.770538 373.284668,285.175079 373.688202,284.890625 
	C378.239899,281.682587 382.824585,278.520508 387.335205,275.255951 
	C388.055786,274.734406 388.455475,273.769501 389.003540,273.009552 
	C390.070587,273.007263 391.137604,273.004974 392.975677,272.995728 
	C398.841339,269.817505 403.888977,266.567200 409.046143,263.501099 
	C412.893982,261.213440 416.710266,258.719330 420.848480,257.107788 
	C427.809265,254.397064 435.018127,252.324921 442.116089,249.964340 
	C448.070374,247.984131 454.050171,246.070969 459.940216,243.913925 
	C461.447174,243.362030 462.657196,241.999344 464.253662,241.006775 
	C464.670319,241.003357 464.837006,241.002289 465.414185,240.990204 
	C467.887756,240.638046 469.997253,240.466278 472.003021,239.916260 
	C475.040131,239.083389 478.005096,237.987305 481.001587,236.669891 
	C481.004486,235.892593 481.006531,235.448456 481.008545,235.004303 
	C485.669495,234.020020 490.135376,233.616226 493.940247,237.546127 
	C494.556946,238.183105 496.458282,237.735001 497.731323,237.550430 
	C503.260956,236.748810 508.750580,235.463989 514.304810,235.080185 
	C527.500183,234.168320 540.745972,232.860779 553.930237,233.220428 
	C568.086365,233.606598 582.308472,235.061691 596.256531,237.470291 
	C600.450012,238.194443 604.698425,241.156586 609.428284,241.778442 
	C612.192871,242.141907 614.964722,242.657684 617.644226,243.418823 
	C624.390442,245.335236 631.219299,247.091843 637.722778,249.654266 
	C639.730957,250.445526 640.797485,253.626801 642.970276,256.654449 
	C651.573853,257.018433 659.521301,262.278595 667.560181,267.599823 
	C671.061829,269.917755 675.318420,271.077423 679.178101,272.878906 
	C681.639282,274.027679 684.089661,275.246674 686.392273,276.679504 
	C695.697815,282.469971 705.042053,288.208649 714.164429,294.278320 
	C717.829407,296.716797 721.036987,299.873871 724.317871,302.852417 
	C732.611694,310.382050 740.897766,317.923096 749.051453,325.603333 
	C753.986694,330.251953 758.669434,335.168732 763.465515,339.965149 
	C763.701111,340.200745 763.993774,340.402344 764.161682,340.679321 
	C767.638123,346.413055 771.428040,351.728943 776.384216,356.499084 
	C781.038879,360.978973 783.976624,367.219147 787.812866,372.582520 
	C789.930359,375.542725 792.734436,378.067535 794.525757,381.191833 
	C797.023499,385.548187 798.729553,390.350739 801.038818,394.825928 
	C802.249939,397.173004 804.250793,399.110199 805.482544,401.449707 
	C806.754150,403.865082 807.499329,406.554352 808.521423,409.105103 
	C810.404541,413.804382 811.889099,418.729889 814.326843,423.124542 
	C817.918762,429.599945 820.033203,436.398010 821.076599,443.655151 
	C821.301453,445.219116 821.849915,447.459412 822.958801,448.045441 
	C827.243408,450.309662 826.565796,454.071075 827.127441,457.869415 
	C828.724365,468.670135 828.838684,479.809723 833.869751,489.937256 
	C834.772888,491.755188 835.307678,494.281708 834.817627,496.171173 
	C833.096130,502.808563 835.345459,508.431305 838.273926,514.085571 
	C838.915405,515.324036 838.966431,516.965149 838.970886,518.421753 
	C839.017700,533.750305 839.663940,549.120239 838.716919,564.390137 
	C838.250488,571.911743 839.296265,579.705872 835.472839,587.240295 
	C832.828918,592.450684 833.216797,599.155823 831.994385,605.140808 
	C829.754028,616.109253 827.328125,627.039734 824.965698,637.983093 
	C824.828308,638.619568 824.761597,639.518250 824.333069,639.807678 
	C818.624573,643.661377 818.831970,649.700439 817.609863,655.580078 
	C816.648071,660.208008 813.194153,664.323120 810.809326,668.645081 
	C810.413818,669.361755 809.348938,670.007629 809.396545,670.624268 
	C809.920715,677.411804 804.830872,680.920959 801.374146,685.554626 
	C800.089050,687.277283 801.166321,690.762207 801.166321,693.955017 
	C796.416321,695.316711 794.594421,699.798767 793.301636,704.462036 
	C791.012756,712.718079 784.759766,717.643311 778.295837,722.849915 
	C777.830078,722.999939 777.662598,722.999939 777.245483,722.596741 
	C777.429688,715.898071 781.256653,711.457947 787.139221,710.902832 
	C783.177246,706.357361 785.515259,702.792419 788.396973,698.968140 
	C790.629272,696.005798 792.260742,692.565369 793.963318,689.237244 
	C795.366333,686.494507 796.177002,683.414307 797.795044,680.823975 
	C800.315186,676.789795 803.821350,673.308899 805.944885,669.106079 
	C808.366028,664.314270 809.698853,658.976624 811.570557,653.900574 
	C812.138428,652.360413 812.996582,650.929077 813.598083,649.399231 
	C815.430054,644.739685 817.225647,640.065369 818.988586,635.379211 
	C820.130066,632.344910 821.217529,629.289429 822.277344,626.225525 
	C823.221069,623.497009 824.431641,620.804016 824.932373,617.989990 
	C826.281738,610.406433 827.168884,602.740601 828.515442,595.156433 
	C829.830017,587.752136 832.486450,580.448792 832.786926,573.029602 
	C833.447083,556.730286 833.363098,540.373962 832.839905,524.064697 
	C832.563354,515.444275 828.519470,507.366425 828.403198,498.448212 
	C828.305603,490.971497 826.362183,483.491577 824.938232,476.070251 
	C824.150024,471.962646 822.885681,467.928345 821.588257,463.941650 
	C820.268982,459.888000 817.562439,455.983734 817.373962,451.925293 
	C816.942749,442.640350 812.312805,434.855682 809.583008,426.421906 
	C808.571716,423.297516 806.040100,420.679657 804.306030,417.768707 
	C803.410278,416.264954 802.614807,414.673767 801.995361,413.038208 
	C799.906433,407.522736 798.305176,401.782928 795.761169,396.492523 
	C793.863647,392.546539 790.592285,389.286285 788.302856,385.497070 
	C785.511597,380.877533 783.615234,375.647217 780.427246,371.352539 
	C774.451782,363.302765 767.830139,355.732269 761.466980,347.970795 
	C759.785828,345.920319 758.201965,343.767273 756.343384,341.889038 
	C747.016418,332.463593 737.825989,322.886353 728.150696,313.829132 
	C722.993652,309.001526 716.912964,305.168762 711.321167,300.794922 
	C706.191650,296.782623 702.255188,291.015381 694.917725,290.323456 
	C693.403320,290.180664 691.783875,289.053101 690.589966,287.965485 
	C684.717773,282.616302 679.291626,277.522095 670.482239,276.118958 
	C664.325439,275.138275 658.844055,269.904022 653.066650,266.559784 
	C649.409546,264.442810 645.450317,260.147980 642.175354,260.621918 
	C634.930725,261.670319 630.073547,256.493591 623.614319,255.701431 
	C617.773438,254.985092 612.451050,250.729691 606.591187,249.304718 
	C599.269470,247.524246 591.629578,247.059082 584.131470,245.995087 
	C578.901978,245.252991 573.691711,244.363770 568.451111,243.715515 
	C563.550415,243.109314 558.628540,242.447693 553.702820,242.320786 
	C545.277771,242.103745 536.839905,242.408295 528.412537,242.239761 
	C524.957275,242.170654 521.475281,240.914474 518.079285,241.172180 
	C515.138306,241.395355 512.367371,243.328690 509.404327,243.910660 
	C504.610077,244.852264 499.723389,245.332153 494.869568,245.959351 
	C486.848724,246.995804 478.815247,247.939499 470.806610,249.061356 
	C469.426392,249.254700 468.128174,250.105530 466.817474,250.707932 
	C463.748596,252.118408 460.779022,253.792709 457.617828,254.944748 
	C451.145905,257.303284 444.169556,258.642090 438.202423,261.878601 
	C434.853058,263.695251 431.932281,264.701538 428.258148,265.186798 
	C424.908325,265.629272 421.672485,267.461578 418.569946,269.041718 
	C416.851898,269.916748 415.460846,272.678802 414.007385,272.616211 
	C407.140869,272.320465 403.041504,277.649475 397.577576,280.084259 
	C391.475830,282.803314 385.730194,286.332275 379.883240,289.604614 
	C378.455902,290.403442 377.154541,291.485077 375.938812,292.594055 
	C371.183441,296.931915 366.593445,301.458466 361.712433,305.647400 
	C359.804138,307.285126 357.143585,308.014679 355.079895,309.508972 
	C349.247589,313.732056 345.340607,320.685455 337.674438,321.699738 
	C337.020508,323.822784 336.904175,326.036163 335.790405,327.480469 
	C332.092987,332.275360 328.200165,336.946503 324.063416,341.366669 
	C321.067902,344.567352 317.454071,347.180847 314.327240,350.270020 
	C311.988922,352.580170 309.792938,355.094330 307.903259,357.777985 
	C306.605743,359.620697 305.942108,361.909760 304.637024,364.046722 
	C303.392090,365.005890 302.274323,365.784637 301.659271,366.852844 
	C299.291809,370.964996 297.144073,375.203339 294.792877,379.325287 
	C293.668884,381.295868 292.272858,383.111267 290.636230,385.078003 
	C290.006165,385.346283 289.614471,385.473419 289.491730,385.729462 
	C285.503510,394.049927 282.082031,402.706665 277.363800,410.587433 
	C274.706055,415.026642 272.391846,418.971619 272.907104,424.270782 
	C273.029572,425.530243 272.372223,427.266968 271.460754,428.155579 
	C267.647003,431.873688 265.197174,435.729645 264.396454,441.474884 
	C263.085571,450.880432 259.877472,460.006714 257.803650,469.325104 
	C255.896790,477.893402 254.180023,486.520935 252.836014,495.193115 
	C251.295547,505.132782 249.565750,515.122559 249.163162,525.140625 
	C248.548615,540.433350 248.965729,555.769348 249.058075,571.086182 
	C249.067429,572.639587 249.767593,574.217834 250.296646,575.732300 
	C251.146271,578.164490 252.149216,580.542603 253.035782,582.962463 
	C254.380600,586.633118 255.681198,590.320007 257.000244,594.437378 
	C257.002014,596.915100 257.004150,598.955566 257.004181,601.460999 
	C257.462097,606.829651 257.324493,611.886414 258.531952,616.598511 
	C260.076904,622.627563 262.834259,628.336487 264.795654,634.273682 
	C265.452271,636.261292 264.432587,639.451782 265.606873,640.513855 
	C273.209625,647.390015 271.968506,658.156921 277.665649,666.121521 
	C283.994629,674.969482 287.155579,686.053894 293.303741,695.067932 
	C301.986267,707.797546 307.923340,722.386963 320.022827,732.926270 
	C328.039154,739.908875 334.450378,748.701538 341.938507,756.332275 
	C349.675720,764.216919 357.838623,771.684204 365.832672,779.315918 
	C367.854584,781.246094 369.942474,783.107117 372.000000,785.000000 
	C372.053406,785.318420 371.994080,785.812439 372.176666,785.929749 
	C377.104614,789.095764 376.985229,792.616882 372.929443,796.831909 
z"/>
<path fill="#027ECA" opacity="1.000000" stroke="none" 
	d="
M481.087158,234.638657 
	C481.006531,235.448456 481.004486,235.892593 480.611237,236.668152 
	C474.360504,236.154800 473.075867,234.704865 473.956757,229.930481 
	C476.341431,229.606064 478.726135,229.281662 481.876770,229.114136 
	C485.911713,230.423828 488.606628,230.458099 489.000275,226.008301 
	C496.683319,225.322067 504.366333,224.635849 512.887573,223.966431 
	C514.501465,223.970169 515.277161,223.957108 516.052795,223.944031 
	C518.437012,223.627151 520.821289,223.310287 523.607361,222.984650 
	C524.795349,223.616943 525.588623,224.821930 526.366455,224.811935 
	C534.319946,224.709961 542.270203,224.374695 550.223572,224.221451 
	C551.025757,224.205994 551.842651,224.957397 552.632629,226.363480 
	C548.551086,226.897018 544.483032,227.599731 540.385010,227.925644 
	C533.710510,228.456467 527.018616,228.783020 520.330139,229.117767 
	C513.640076,229.452606 506.840027,230.548523 500.280640,229.743179 
	C493.045044,228.854797 487.451202,232.498825 481.087158,234.638657 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M836.709106,629.216614 
	C836.287048,630.997925 835.560120,632.936279 834.513062,634.981995 
	C834.930054,633.184082 835.667114,631.278870 836.709106,629.216614 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M838.753662,622.290039 
	C838.535583,624.200684 838.048462,626.344910 837.307190,628.736694 
	C837.530273,626.830750 838.007507,624.677185 838.753662,622.290039 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M842.601929,609.177979 
	C842.443115,610.814270 841.987061,612.655945 841.281128,614.740845 
	C841.455750,613.117188 841.880249,611.250305 842.601929,609.177979 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M815.675659,679.059570 
	C815.563232,679.652954 815.131104,680.293701 814.397705,681.008545 
	C814.516296,680.424072 814.936157,679.765503 815.675659,679.059570 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M746.753662,766.110657 
	C746.625488,766.716675 746.165649,767.336304 745.402771,768.021973 
	C745.540466,767.433411 745.981201,766.778809 746.753662,766.110657 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M825.798401,657.315247 
	C825.889648,657.953552 825.709167,658.765930 825.289185,659.784668 
	C825.208618,659.157104 825.367676,658.323181 825.798401,657.315247 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M840.587280,618.088623 
	C840.757812,618.262695 840.649109,618.526550 840.231018,618.854492 
	C840.103333,618.686035 840.232117,618.439270 840.587280,618.088623 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M810.805542,687.164368 
	C810.934631,687.369873 810.766907,687.628601 810.321533,687.960632 
	C810.161743,687.747070 810.316711,687.474976 810.805542,687.164368 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M817.972168,673.201172 
	C818.152527,673.181274 818.090759,673.559937 818.069458,673.749756 
	C817.882324,673.787659 817.716492,673.635742 817.550659,673.483765 
	C817.631042,673.396179 817.711426,673.308655 817.972168,673.201172 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M827.868591,653.153687 
	C828.026001,653.091248 827.998413,653.545837 827.967896,653.771362 
	C827.800598,653.829956 827.646484,653.673035 827.539734,653.488708 
	C827.514648,653.445496 827.649780,653.309448 827.868591,653.153687 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M304.995117,363.999268 
	C305.942108,361.909760 306.605743,359.620697 307.903259,357.777985 
	C309.792938,355.094330 311.988922,352.580170 314.327240,350.270020 
	C317.454071,347.180847 321.067902,344.567352 324.063416,341.366669 
	C328.200165,336.946503 332.092987,332.275360 335.790405,327.480469 
	C336.904175,326.036163 337.020508,323.822784 337.674438,321.699738 
	C345.340607,320.685455 349.247589,313.732056 355.079895,309.508972 
	C357.143585,308.014679 359.804138,307.285126 361.712433,305.647400 
	C366.593445,301.458466 371.183441,296.931915 375.938812,292.594055 
	C377.154541,291.485077 378.455902,290.403442 379.883240,289.604614 
	C385.730194,286.332275 391.475830,282.803314 397.577576,280.084259 
	C403.041504,277.649475 407.140869,272.320465 414.007385,272.616211 
	C415.460846,272.678802 416.851898,269.916748 418.569946,269.041718 
	C421.672485,267.461578 424.908325,265.629272 428.258148,265.186798 
	C431.932281,264.701538 434.853058,263.695251 438.202423,261.878601 
	C444.169556,258.642090 451.145905,257.303284 457.617828,254.944748 
	C460.779022,253.792709 463.748596,252.118408 466.817474,250.707932 
	C468.128174,250.105530 469.426392,249.254700 470.806610,249.061356 
	C478.815247,247.939499 486.848724,246.995804 494.869568,245.959351 
	C499.723389,245.332153 504.610077,244.852264 509.404327,243.910660 
	C512.367371,243.328690 515.138306,241.395355 518.079285,241.172180 
	C521.475281,240.914474 524.957275,242.170654 528.412537,242.239761 
	C536.839905,242.408295 545.277771,242.103745 553.702820,242.320786 
	C558.628540,242.447693 563.550415,243.109314 568.451111,243.715515 
	C573.691711,244.363770 578.901978,245.252991 584.131470,245.995087 
	C591.629578,247.059082 599.269470,247.524246 606.591187,249.304718 
	C612.451050,250.729691 617.773438,254.985092 623.614319,255.701431 
	C630.073547,256.493591 634.930725,261.670319 642.175354,260.621918 
	C645.450317,260.147980 649.409546,264.442810 653.066650,266.559784 
	C658.844055,269.904022 664.325439,275.138275 670.482239,276.118958 
	C679.291626,277.522095 684.717773,282.616302 690.589966,287.965485 
	C691.783875,289.053101 693.403320,290.180664 694.917725,290.323456 
	C702.255188,291.015381 706.191650,296.782623 711.321167,300.794922 
	C716.912964,305.168762 722.993652,309.001526 728.150696,313.829132 
	C737.825989,322.886353 747.016418,332.463593 756.343384,341.889038 
	C758.201965,343.767273 759.785828,345.920319 761.466980,347.970795 
	C767.830139,355.732269 774.451782,363.302765 780.427246,371.352539 
	C783.615234,375.647217 785.511597,380.877533 788.302856,385.497070 
	C790.592285,389.286285 793.863647,392.546539 795.761169,396.492523 
	C798.305176,401.782928 799.906433,407.522736 801.995361,413.038208 
	C802.614807,414.673767 803.410278,416.264954 804.306030,417.768707 
	C806.040100,420.679657 808.571716,423.297516 809.583008,426.421906 
	C812.312805,434.855682 816.942749,442.640350 817.373962,451.925293 
	C817.562439,455.983734 820.268982,459.888000 821.588257,463.941650 
	C822.885681,467.928345 824.150024,471.962646 824.938232,476.070251 
	C826.362183,483.491577 828.305603,490.971497 828.403198,498.448212 
	C828.519470,507.366425 832.563354,515.444275 832.839905,524.064697 
	C833.363098,540.373962 833.447083,556.730286 832.786926,573.029602 
	C832.486450,580.448792 829.830017,587.752136 828.515442,595.156433 
	C827.168884,602.740601 826.281738,610.406433 824.932373,617.989990 
	C824.431641,620.804016 823.221069,623.497009 822.277344,626.225525 
	C821.217529,629.289429 820.130066,632.344910 818.988586,635.379211 
	C817.225647,640.065369 815.430054,644.739685 813.598083,649.399231 
	C812.996582,650.929077 812.138428,652.360413 811.570557,653.900574 
	C809.698853,658.976624 808.366028,664.314270 805.944885,669.106079 
	C803.821350,673.308899 800.315186,676.789795 797.795044,680.823975 
	C796.177002,683.414307 795.366333,686.494507 793.963318,689.237244 
	C792.260742,692.565369 790.629272,696.005798 788.396973,698.968140 
	C785.515259,702.792419 783.177246,706.357361 787.139221,710.902832 
	C781.256653,711.457947 777.429688,715.898071 776.994263,722.595581 
	C770.323853,727.014648 764.019775,731.054199 761.648743,739.463379 
	C760.760254,742.614380 757.142151,745.111450 754.505615,747.631653 
	C747.505066,754.323059 740.359131,760.862244 733.280884,767.472595 
	C732.921509,767.808228 732.722900,768.486511 732.334412,768.595520 
	C723.395020,771.104248 718.866028,779.455383 711.621155,784.178223 
	C703.284973,789.612549 694.776306,794.785706 686.507629,800.318848 
	C683.083496,802.610352 680.152405,805.627136 676.857788,808.130737 
	C675.861938,808.887451 673.865723,809.777588 673.236816,809.329834 
	C669.415588,806.609253 666.091431,809.058960 664.521912,811.339661 
	C660.599854,817.039062 654.942749,816.438171 649.461853,816.976318 
	C647.994141,817.120361 646.500244,816.998108 644.685974,816.998108 
	C644.265198,823.598083 639.327026,825.005798 634.448853,826.019714 
	C620.949646,828.825500 607.396362,831.371338 593.650146,834.061890 
	C594.113892,834.558899 594.785767,835.279053 595.569031,836.118530 
	C591.447693,839.400635 587.376099,837.500977 583.392883,836.137146 
	C580.695618,835.213684 578.085815,833.518921 576.428711,837.623779 
	C576.179321,838.241577 574.665222,838.891785 573.964050,838.698914 
	C568.134583,837.094604 562.500061,838.213989 556.610535,838.669006 
	C543.200256,839.704895 529.648315,839.228516 516.169006,838.842407 
	C512.424805,838.735168 508.735596,836.783386 505.016541,835.693604 
	C503.115417,835.136597 501.138000,833.984619 499.308014,834.176758 
	C485.665497,835.609253 473.117981,830.551941 460.200348,827.800598 
	C453.427917,826.357971 446.603638,824.792175 440.143280,822.372803 
	C435.365082,820.583252 431.186890,817.234192 426.652313,814.742065 
	C418.882141,810.471802 410.930756,806.520081 403.271240,802.063660 
	C398.214996,799.121826 393.728699,795.188354 388.634155,792.327576 
	C383.569183,789.483337 378.062744,787.425171 372.374939,785.011169 
	C369.942474,783.107117 367.854584,781.246094 365.832672,779.315918 
	C357.838623,771.684204 349.675720,764.216919 341.938507,756.332275 
	C334.450378,748.701538 328.039154,739.908875 320.022827,732.926270 
	C307.923340,722.386963 301.986267,707.797546 293.303741,695.067932 
	C287.155579,686.053894 283.994629,674.969482 277.665649,666.121521 
	C271.968506,658.156921 273.209625,647.390015 265.606873,640.513855 
	C264.432587,639.451782 265.452271,636.261292 264.795654,634.273682 
	C262.834259,628.336487 260.076904,622.627563 258.531952,616.598511 
	C257.324493,611.886414 257.462097,606.829651 257.381897,601.555176 
	C259.592072,605.226501 261.159302,609.416199 263.321991,613.272034 
	C265.796753,617.684326 263.619873,623.744080 268.497498,627.261292 
	C268.982147,627.610779 268.788116,628.839355 269.004089,629.634094 
	C269.993073,633.273132 270.712158,637.023865 272.098969,640.507324 
	C274.098969,645.530945 276.575806,650.365906 278.879303,655.267212 
	C282.704773,663.406738 286.458557,671.582214 290.437775,679.646179 
	C292.176605,683.169922 294.370056,686.472351 296.405029,689.846313 
	C300.527222,696.680786 304.233429,703.825317 308.941742,710.231567 
	C314.322662,717.553101 321.232697,723.779785 326.372375,731.243774 
	C338.144104,748.338928 354.455872,760.746338 370.063568,773.647095 
	C381.625671,783.203796 395.255310,790.252869 407.917389,798.490540 
	C410.401611,800.106750 412.441803,802.565613 415.083984,803.749268 
	C423.670197,807.596069 432.794586,809.928650 441.317291,814.269348 
	C448.493164,817.924133 457.055206,818.857239 465.001831,820.997559 
	C465.000000,821.000000 465.004883,820.995117 465.013916,821.399963 
	C466.551788,822.869202 467.989624,824.670532 469.625000,824.872437 
	C477.426605,825.835693 485.283234,826.349487 493.115967,827.070496 
	C493.919983,827.144531 494.690979,827.547729 495.484131,827.774170 
	C505.703339,830.691956 514.892822,837.262695 526.716431,834.281738 
	C532.726074,832.766541 539.565063,833.449890 545.848022,834.372803 
	C554.657715,835.666748 562.982849,833.390015 571.429138,832.211426 
	C579.852966,831.035889 588.106262,828.687073 596.489258,827.152893 
	C601.939575,826.155518 607.630981,826.235535 612.949280,824.829895 
	C617.495483,823.628235 621.664246,820.998169 626.374695,819.000000 
	C627.498779,818.998413 628.248169,818.996765 629.387695,818.988953 
	C639.681885,815.067932 649.733643,811.482239 659.440735,807.128235 
	C666.461792,803.979004 673.139587,800.001282 679.765625,796.049683 
	C689.232788,790.403870 698.790771,784.839233 707.792847,778.504822 
	C716.417358,772.436096 724.450867,765.512451 732.582458,758.769592 
	C734.399719,757.262695 735.543030,754.942993 736.998291,752.998779 
	C737.000000,753.000000 736.997559,752.996704 737.342529,752.958618 
	C743.152344,747.547180 748.868591,742.401794 754.008606,736.733887 
	C758.810364,731.438843 763.052551,725.629272 767.413818,719.948425 
	C768.245483,718.865112 768.483276,717.325989 768.995544,715.747192 
	C768.995972,715.497314 768.997559,714.997559 769.344727,714.958008 
	C772.126282,711.277344 774.560730,707.636230 776.995422,703.745728 
	C776.995728,703.496338 776.997559,702.997559 777.334473,702.942688 
	C778.163391,702.476501 778.822388,702.161316 779.122742,701.639648 
	C784.597778,692.131714 790.334045,682.755249 795.344482,673.006470 
	C797.907776,668.019043 799.155273,662.355469 800.997803,656.664673 
	C800.998779,655.887817 800.999390,655.443909 801.348022,654.958984 
	C808.628662,650.347229 809.529602,643.448914 808.998108,635.664673 
	C808.999146,634.887817 808.999573,634.443909 809.360901,633.957886 
	C814.323303,632.622986 814.311401,628.604248 814.994385,624.545410 
	C814.997375,621.397156 814.998718,618.698608 815.369202,615.951660 
	C816.822754,614.416199 818.595581,613.049438 818.880249,611.422302 
	C820.829346,600.281372 822.465027,589.085632 824.210449,577.908936 
	C824.261047,577.584961 824.469727,577.282776 824.504395,576.959656 
	C825.344543,569.122498 826.799561,561.286926 826.836426,553.444885 
	C826.879700,544.240601 825.134460,535.028870 825.161987,525.823303 
	C825.203674,511.874023 823.007812,498.297089 819.865479,484.825806 
	C819.516418,483.329376 817.339478,482.259369 816.001221,480.623901 
	C815.661438,478.837463 815.330688,477.418732 815.273804,475.726807 
	C815.693909,474.482574 816.113281,473.460358 815.942444,472.548737 
	C814.812561,466.520782 813.701904,460.481873 812.282532,454.518494 
	C811.504272,451.248657 810.082336,448.136505 809.045349,444.922729 
	C807.460632,440.011475 806.587585,434.752106 804.273682,430.222229 
	C800.744446,423.313110 797.229065,416.517883 795.685852,408.828339 
	C795.423889,407.523438 794.128662,406.404999 793.244385,405.251282 
	C792.150757,403.824249 790.930298,402.492340 789.870911,401.041687 
	C788.812134,399.591675 787.855652,398.063812 786.905945,396.537964 
	C785.220398,393.830017 783.522705,391.127350 781.928223,388.365845 
	C780.775574,386.369720 779.934998,384.182373 778.684204,382.256348 
	C776.259338,378.522522 774.123230,374.411224 770.969666,371.394928 
	C767.925171,368.483002 769.104004,362.913544 763.014771,361.523682 
	C759.893188,360.811218 757.897217,355.212463 755.366821,351.841309 
	C754.393188,350.544128 753.347900,349.300720 752.876526,348.710541 
	C751.018188,349.204285 749.472534,349.614929 747.724731,349.912720 
	C747.270630,349.606720 747.088196,349.361755 747.134338,348.746521 
	C750.992310,343.052063 750.757874,339.972931 746.291748,339.131592 
	C741.239258,338.179810 738.913452,334.285248 737.117371,330.526062 
	C734.273376,324.573425 732.153381,322.901764 727.640076,323.888275 
	C723.252014,320.445892 719.637695,317.793488 716.250854,314.876862 
	C714.541443,313.404755 713.292175,311.409882 711.747009,309.732269 
	C710.261780,308.119720 708.803467,306.426575 707.072083,305.113190 
	C705.548218,303.957184 703.258118,303.657349 702.005798,302.322998 
	C695.668335,295.570251 687.297974,292.863129 680.568665,290.161652 
	C674.822571,285.664032 670.410034,281.501923 665.326782,278.461212 
	C661.440002,276.136261 656.653015,275.360809 652.358459,273.663208 
	C644.804504,270.677155 637.504700,266.844299 629.729919,264.706818 
	C625.866882,263.644745 621.746704,264.142090 618.745300,260.636444 
	C618.229553,260.034088 616.488647,260.080444 615.474670,260.377197 
	C611.891174,261.425964 609.147827,260.336243 605.722595,258.797516 
	C600.005493,256.229156 593.731140,253.859314 587.878540,252.521927 
	C573.580872,249.254715 558.758057,248.582230 543.908875,248.838394 
	C524.991394,249.164703 506.149445,249.473862 487.640167,253.897400 
	C480.851227,255.519867 474.103394,257.314087 467.334351,259.020203 
	C454.818085,262.174866 441.923492,264.219177 431.088257,272.136108 
	C429.625885,273.204620 427.330353,273.115021 425.433655,273.611542 
	C422.989136,274.251526 419.342194,274.167053 418.335052,275.747101 
	C414.365723,281.974579 407.105347,282.297668 401.742584,285.906555 
	C398.202179,288.289093 393.618378,289.121155 389.501770,290.647400 
	C388.373230,291.418701 387.713074,293.709320 386.191498,294.824921 
	C382.400116,297.604645 378.215088,299.846954 374.191284,302.310242 
	C370.863068,304.347748 367.288849,306.082703 364.279205,308.515350 
	C360.772797,311.349579 357.774719,314.810089 354.530640,317.972748 
	C352.882477,319.579559 351.439362,321.828918 349.449890,322.570221 
	C344.044800,324.584198 340.428467,327.932831 338.666382,333.441254 
	C338.250092,334.742523 337.399475,336.478699 336.314240,336.902557 
	C326.371307,340.786255 322.327209,350.083771 317.775696,358.277039 
	C314.540588,364.100647 311.215546,368.693909 305.001038,370.564636 
	C304.998138,368.084290 304.996643,366.041779 304.995117,363.999268 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M732.799438,777.917542 
	C732.627502,778.640442 732.122070,779.215393 731.366516,779.932495 
	C729.923950,779.884888 728.731445,779.695251 727.539001,779.505615 
	C727.542053,778.926941 727.545044,778.348267 727.548096,777.769592 
	C729.187378,777.769592 730.826660,777.769592 732.799438,777.917542 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M737.008057,774.776001 
	C736.243652,775.991211 735.401367,776.860962 734.280273,777.858032 
	C734.074646,776.498718 734.147766,775.012146 734.220947,773.525513 
	C735.124023,773.827209 736.027161,774.128906 737.008057,774.776001 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M564.157043,842.555664 
	C563.160217,842.763123 562.395630,842.763123 561.631042,842.763123 
	C561.711853,842.321777 561.792664,841.880493 561.873474,841.439148 
	C562.712097,841.742188 563.550659,842.045166 564.157043,842.555664 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M520.474487,844.459412 
	C520.520142,844.434265 520.428833,844.484558 520.474487,844.459412 
z"/>
<path fill="#027ECA" opacity="1.000000" stroke="none" 
	d="
M251.001831,625.995117 
	C251.002945,626.440979 251.004059,626.886902 250.728760,627.882935 
	C248.837158,631.506348 245.657349,634.686584 251.380951,637.092529 
	C252.050629,637.374084 252.313171,638.989807 252.480316,640.032532 
	C252.975739,643.122986 252.642670,646.559509 253.913437,649.257874 
	C255.464539,652.551575 258.389130,655.180420 260.563446,658.208923 
	C261.287811,659.217896 261.895782,660.561035 261.934052,661.771118 
	C262.175934,669.421570 265.158600,675.908142 269.449127,682.125916 
	C271.869751,685.633850 273.173370,689.897949 275.163940,693.725830 
	C275.812286,694.972534 277.042023,695.916870 278.005829,696.999573 
	C278.474243,697.316040 278.942657,697.632568 279.705872,698.376099 
	C280.000519,699.876892 280.000336,700.950623 279.619385,702.020264 
	C278.476044,702.036499 277.713470,702.056946 276.950897,702.077271 
	C273.283478,696.040833 269.198914,690.214050 266.036743,683.923584 
	C259.738159,671.394043 253.120483,658.922058 248.175247,645.843689 
	C243.115387,632.462280 239.246460,618.532837 236.055862,604.569092 
	C232.672684,589.762390 230.094391,574.704346 228.326126,559.619873 
	C227.150635,549.592041 227.732742,539.316956 228.037369,529.167786 
	C228.385345,517.574036 228.411896,505.860229 230.176529,494.445648 
	C232.722824,477.974792 236.312592,461.630188 240.254120,445.425537 
	C242.105972,437.812012 245.830597,430.655914 248.681992,423.283051 
	C251.520355,415.943878 253.793915,408.330688 257.247162,401.295135 
	C268.500580,378.367767 281.915619,356.762756 298.352295,337.128937 
	C302.140594,332.603729 306.019440,328.154358 310.282288,323.325684 
	C311.430908,322.278809 312.153870,321.576385 312.876831,320.873962 
	C316.176483,317.280975 319.404846,313.618713 322.802643,310.121094 
	C324.840240,308.023682 327.142578,306.183441 329.965027,304.148682 
	C332.487579,305.464172 334.371033,306.858582 336.478638,308.418915 
	C332.421234,310.419434 326.267548,309.165039 323.685852,316.151825 
	C326.875183,315.036255 329.160614,314.236847 332.111877,313.204529 
	C331.656616,315.574310 331.327698,317.286560 330.998779,318.998779 
	C330.203461,319.154358 329.110107,319.030243 328.659943,319.509796 
	C325.042419,323.363586 321.546204,327.331207 317.742401,331.756592 
	C316.981567,332.834381 316.491241,333.419556 316.000916,334.004761 
	C315.026978,334.777527 314.053040,335.550293 312.654480,336.661865 
	C310.333038,338.309570 307.926910,339.242432 306.632233,340.995941 
	C302.252167,346.928131 298.471100,353.303619 294.072418,359.220764 
	C289.543518,365.313080 282.490234,369.256714 279.611298,376.869934 
	C277.034485,383.684143 273.891724,390.294098 270.764404,396.882996 
	C270.038086,398.413208 268.545746,400.263947 267.081665,400.614929 
	C264.187073,401.308929 263.696045,402.947144 264.018951,405.303925 
	C265.063171,412.925323 261.294647,418.796783 257.784485,425.115997 
	C253.577148,432.690308 250.661148,440.982239 247.199570,448.969299 
	C247.071609,449.264557 247.085144,449.621918 247.037460,449.951019 
	C245.653717,459.503571 244.282791,469.057983 242.865097,478.605469 
	C242.745850,479.408630 242.239960,480.146698 242.037582,480.946381 
	C240.343964,487.638916 237.976242,494.258148 237.196533,501.058289 
	C236.355972,508.389252 237.002289,515.891602 237.017990,523.319153 
	C237.022125,525.269409 236.997742,527.226929 237.149170,529.168457 
	C238.034653,540.521179 236.108154,551.821106 236.719833,563.220032 
	C237.031769,569.033325 235.751678,574.666199 237.715103,580.710327 
	C239.548767,586.354980 241.642075,592.343384 240.304108,598.813110 
	C239.728958,601.594238 240.785660,604.839294 241.570953,607.746582 
	C242.840073,612.445068 245.033661,616.950500 245.815323,621.705383 
	C246.403702,625.284546 247.853485,626.270569 251.001831,625.995117 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M280.000183,702.024414 
	C280.000336,700.950623 280.000519,699.876892 280.000793,698.400330 
	C281.934692,695.324585 284.924530,694.827087 285.604706,698.036743 
	C287.454315,706.765015 294.383514,711.800354 298.949768,718.529907 
	C300.019958,720.107117 300.346039,722.168945 301.232391,723.895996 
	C302.476624,726.320435 303.693726,728.815979 305.332214,730.969238 
	C309.102478,735.924133 312.905121,740.886719 317.094025,745.482178 
	C321.902069,750.756775 326.958191,755.839722 332.238647,760.640625 
	C339.196472,766.966675 346.577515,772.825562 353.584900,779.099426 
	C358.280457,783.303589 362.878357,787.426575 369.510620,788.258667 
	C369.895020,788.306885 370.039520,790.266907 369.912598,791.844055 
	C369.356659,792.899841 369.183838,793.448669 369.010986,793.997559 
	C369.005035,794.174561 368.999054,794.351624 368.658112,794.778564 
	C367.890839,795.024048 367.458557,795.019653 367.026245,795.015320 
	C365.611572,794.288635 364.196899,793.561951 362.465546,792.695251 
	C360.649078,790.654114 359.507904,788.220215 357.595123,786.932922 
	C334.020782,771.068176 314.034515,751.429993 296.524200,729.189331 
	C291.250244,722.490662 286.352325,715.495972 281.130066,707.939148 
	C280.652039,705.502441 280.326111,703.763428 280.000183,702.024414 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M331.344818,318.959106 
	C331.327698,317.286560 331.656616,315.574310 332.111877,313.204529 
	C329.160614,314.236847 326.875183,315.036255 323.685852,316.151825 
	C326.267548,309.165039 332.421234,310.419434 336.478638,308.418915 
	C334.371033,306.858582 332.487579,305.464172 330.270050,303.998657 
	C331.751190,302.057709 333.566406,300.187897 335.687073,298.161743 
	C339.268066,299.698242 339.140167,305.373993 344.591187,305.003906 
	C343.854797,306.318909 342.952545,308.090332 341.514374,308.858948 
	C337.123505,311.205566 334.452301,315.071777 331.344818,318.959106 
z"/>
<path fill="#0673B7" opacity="1.000000" stroke="none" 
	d="
M279.619385,702.020264 
	C280.326111,703.763428 280.652039,705.502441 280.962677,707.634155 
	C279.649170,706.268311 278.350983,704.509827 277.001831,702.414307 
	C277.713470,702.056946 278.476044,702.036499 279.619385,702.020264 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M384.637207,263.978760 
	C384.577911,265.377258 384.207794,266.820282 383.837646,268.263275 
	C383.242737,267.695374 382.647827,267.127472 382.013611,266.222412 
	C382.758362,265.264587 383.542358,264.643890 384.637207,263.978760 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M422.627899,245.981567 
	C421.873505,246.972336 420.773071,247.967743 419.302612,248.944977 
	C420.048981,247.946609 421.165436,246.966415 422.627899,245.981567 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M416.824921,248.698517 
	C416.177155,249.338135 415.343414,249.762405 414.220703,250.046082 
	C414.834137,249.431381 415.736542,248.957291 416.824921,248.698517 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M312.570129,320.937500 
	C312.153870,321.576385 311.430908,322.278809 310.351318,322.989197 
	C310.750946,322.331757 311.507172,321.666412 312.570129,320.937500 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M424.701630,245.002045 
	C424.527283,245.300232 424.056122,245.621567 423.286255,245.959442 
	C423.459961,245.659058 423.932373,245.342117 424.701630,245.002045 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M360.696899,279.920471 
	C360.678101,280.030518 360.432892,280.159454 360.102112,280.123840 
	C360.016510,279.959290 360.470459,279.939331 360.696899,279.920471 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M464.003662,241.009155 
	C462.657196,241.999344 461.447174,243.362030 459.940216,243.913925 
	C454.050171,246.070969 448.070374,247.984131 442.116089,249.964340 
	C435.018127,252.324921 427.809265,254.397064 420.848480,257.107788 
	C416.710266,258.719330 412.893982,261.213440 409.046143,263.501099 
	C403.888977,266.567200 398.841339,269.817505 393.375793,272.994995 
	C393.266968,265.433044 398.518158,262.258148 404.551880,259.582245 
	C406.811249,258.580261 408.609009,256.454590 410.900970,255.626434 
	C415.491577,253.967682 420.350128,253.057755 424.962738,251.450455 
	C429.550690,249.851746 433.985077,247.805679 438.456635,245.885498 
	C439.315430,245.516693 440.057953,244.852829 440.813324,244.272003 
	C443.187927,242.446030 445.581757,240.640915 447.877777,238.719711 
	C448.427826,238.259445 448.627319,237.380219 449.004333,236.308334 
	C449.822906,235.339890 450.625183,234.756805 451.711060,234.521805 
	C451.833466,236.081879 451.672302,237.293854 451.492371,238.646790 
	C455.864319,239.472290 459.933990,240.240723 464.003662,241.009155 
z"/>
<path fill="#027ECA" opacity="1.000000" stroke="none" 
	d="
M464.253662,241.006775 
	C459.933990,240.240723 455.864319,239.472290 451.492371,238.646790 
	C451.672302,237.293854 451.833466,236.081879 452.017242,234.508087 
	C458.069641,233.073044 464.099457,231.999802 470.492493,231.289474 
	C469.726013,232.133240 468.404694,232.392090 467.519592,233.156204 
	C466.600525,233.949722 465.826782,235.152557 465.491180,236.320526 
	C465.064697,237.804901 465.143005,239.434341 465.003662,241.001221 
	C464.837006,241.002289 464.670319,241.003357 464.253662,241.006775 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M465.414185,240.990204 
	C465.143005,239.434341 465.064697,237.804901 465.491180,236.320526 
	C465.826782,235.152557 466.600525,233.949722 467.519592,233.156204 
	C468.404694,232.392090 469.726013,232.133240 470.923645,231.280273 
	C471.760803,230.591797 472.530029,230.275452 473.627991,229.944794 
	C473.075867,234.704865 474.360504,236.154800 480.610352,237.001297 
	C478.005096,237.987305 475.040131,239.083389 472.003021,239.916260 
	C469.997253,240.466278 467.887756,240.638046 465.414185,240.990204 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M488.670380,226.138458 
	C488.606628,230.458099 485.911713,230.423828 482.302643,229.112122 
	C484.088501,228.058365 486.214508,227.163498 488.670380,226.138458 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M515.848633,223.713058 
	C515.277161,223.957108 514.501465,223.970169 513.337769,223.965668 
	C513.847961,223.792755 514.746277,223.637421 515.848633,223.713058 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M388.635803,273.006592 
	C388.455475,273.769501 388.055786,274.734406 387.335205,275.255951 
	C382.824585,278.520508 378.239899,281.682587 373.688202,284.890625 
	C373.284668,285.175079 372.980957,285.770538 372.565033,285.842896 
	C364.830261,287.188446 361.384399,293.848816 356.493103,298.717743 
	C356.040039,299.168732 355.352539,299.858215 354.906860,299.769440 
	C350.344971,298.860809 347.971680,301.717010 345.376831,304.948669 
	C344.368408,299.766724 346.230225,296.717865 351.738708,295.320282 
	C353.849121,294.784851 355.896606,291.791321 356.865692,289.458099 
	C358.540955,285.424774 362.162872,283.129211 366.590363,284.229950 
	C367.438385,284.440765 368.749512,284.513458 369.271027,284.029388 
	C372.375488,281.147858 375.348969,278.120819 378.287598,275.066742 
	C379.898621,273.392334 381.354919,271.569031 382.157043,270.645966 
	C385.009460,271.746429 386.638763,272.375031 388.635803,273.006592 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M367.190613,795.245483 
	C367.458557,795.019653 367.890839,795.024048 368.646790,795.044067 
	C368.970398,795.059753 368.962860,795.530945 368.946533,795.765991 
	C368.405121,795.825928 367.880035,795.650818 367.190613,795.245483 
z"/>
<path fill="#0673B7" opacity="1.000000" stroke="none" 
	d="
M369.112946,796.237183 
	C369.315735,796.120972 369.571350,796.297180 369.886902,796.759583 
	C369.682709,796.882874 369.422363,796.710815 369.112946,796.237183 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M305.002441,371.002441 
	C311.215546,368.693909 314.540588,364.100647 317.775696,358.277039 
	C322.327209,350.083771 326.371307,340.786255 336.314240,336.902557 
	C337.399475,336.478699 338.250092,334.742523 338.666382,333.441254 
	C340.428467,327.932831 344.044800,324.584198 349.449890,322.570221 
	C351.439362,321.828918 352.882477,319.579559 354.530640,317.972748 
	C357.774719,314.810089 360.772797,311.349579 364.279205,308.515350 
	C367.288849,306.082703 370.863068,304.347748 374.191284,302.310242 
	C378.215088,299.846954 382.400116,297.604645 386.191498,294.824921 
	C387.713074,293.709320 388.373230,291.418701 389.501770,290.647400 
	C393.618378,289.121155 398.202179,288.289093 401.742584,285.906555 
	C407.105347,282.297668 414.365723,281.974579 418.335052,275.747101 
	C419.342194,274.167053 422.989136,274.251526 425.433655,273.611542 
	C427.330353,273.115021 429.625885,273.204620 431.088257,272.136108 
	C441.923492,264.219177 454.818085,262.174866 467.334351,259.020203 
	C474.103394,257.314087 480.851227,255.519867 487.640167,253.897400 
	C506.149445,249.473862 524.991394,249.164703 543.908875,248.838394 
	C558.758057,248.582230 573.580872,249.254715 587.878540,252.521927 
	C593.731140,253.859314 600.005493,256.229156 605.722595,258.797516 
	C609.147827,260.336243 611.891174,261.425964 615.474670,260.377197 
	C616.488647,260.080444 618.229553,260.034088 618.745300,260.636444 
	C621.746704,264.142090 625.866882,263.644745 629.729919,264.706818 
	C637.504700,266.844299 644.804504,270.677155 652.358459,273.663208 
	C656.653015,275.360809 661.440002,276.136261 665.326782,278.461212 
	C670.410034,281.501923 674.822571,285.664032 680.568665,290.161652 
	C687.297974,292.863129 695.668335,295.570251 702.005798,302.322998 
	C703.258118,303.657349 705.548218,303.957184 707.072083,305.113190 
	C708.803467,306.426575 710.261780,308.119720 711.747009,309.732269 
	C713.292175,311.409882 714.541443,313.404755 716.250854,314.876862 
	C719.637695,317.793488 723.252014,320.445892 727.640076,323.888275 
	C732.153381,322.901764 734.273376,324.573425 737.117371,330.526062 
	C738.913452,334.285248 741.239258,338.179810 746.291748,339.131592 
	C750.757874,339.972931 750.992310,343.052063 746.840027,348.586700 
	C737.731445,340.258270 729.606262,331.126465 720.278625,323.457092 
	C709.990479,314.997986 699.028442,307.158020 687.582153,300.357483 
	C672.363525,291.315674 656.552795,283.213257 640.646545,275.421448 
	C635.972229,273.131683 630.111389,273.263916 624.810364,272.276978 
	C624.826355,272.267761 624.794922,272.265808 624.804565,271.897339 
	C624.248901,271.001526 623.767700,270.185059 623.105164,269.992584 
	C617.880127,268.474518 612.498657,267.414185 607.393799,265.577942 
	C590.413940,259.470123 572.591003,258.242950 554.920410,257.275024 
	C536.162537,256.247589 517.273071,256.453857 498.637604,259.995209 
	C489.868805,261.661591 480.874817,262.499298 472.338989,264.951935 
	C459.497406,268.641785 446.965454,273.410858 434.307526,277.737732 
	C423.534546,281.420258 413.185455,285.962189 403.575714,292.175690 
	C395.930481,297.118988 387.946991,301.540710 380.324036,306.516266 
	C374.943390,310.028229 369.783539,313.919067 364.807617,317.986359 
	C363.277222,319.237305 362.679230,321.628967 361.598938,323.530853 
	C361.543549,323.563385 361.436249,323.633972 361.069824,323.465637 
	C359.630585,323.574554 358.210724,323.505585 357.536987,324.180939 
	C347.602631,334.139404 337.749390,344.179108 327.918671,354.240387 
	C324.547058,357.691071 320.850128,360.940125 318.074158,364.832184 
	C312.439514,372.732269 306.899414,380.762726 302.115021,389.190552 
	C296.058716,399.859009 290.174194,410.711792 285.359467,421.974091 
	C280.420532,433.527008 276.805176,445.650879 272.779022,457.581696 
	C272.338379,458.887421 273.008606,460.568054 273.173615,462.404297 
	C273.074585,463.158234 272.965515,463.582672 272.660858,464.165039 
	C272.414215,464.642578 272.363159,464.962189 272.343811,465.232788 
	C272.375549,465.183807 272.351898,465.298187 271.965820,465.266174 
	C270.734894,466.880066 269.443451,468.442871 269.119141,470.185638 
	C267.287170,480.030243 265.740021,489.927399 263.985565,499.787048 
	C263.134399,504.570465 262.043121,509.311188 260.879974,513.918091 
	C260.811035,514.516235 260.922699,515.267517 261.038208,516.442810 
	C261.368378,520.237244 261.694641,523.607727 261.628052,527.084351 
	C261.042877,533.942139 260.839722,540.693604 260.715973,547.446411 
	C260.712646,547.629089 261.566162,547.827454 262.024719,548.428589 
	C262.028625,550.205383 262.027893,551.572205 262.017090,553.311035 
	C262.338379,554.795898 262.669800,555.908691 263.007690,557.434204 
	C263.022552,559.222595 263.030884,560.598328 262.761230,562.243286 
	C262.992859,564.681763 263.502472,566.850952 264.018311,569.388428 
	C264.030243,570.493469 264.035919,571.230164 263.819153,572.143188 
	C264.052399,572.887146 264.508087,573.454895 264.976654,574.433350 
	C265.335602,577.554932 265.681641,580.265747 265.639038,583.042358 
	C262.024353,586.267456 262.113464,595.427002 265.981720,599.713867 
	C268.168060,602.136719 268.179443,603.905396 266.978027,606.698303 
	C264.840546,611.667175 269.522614,618.539978 275.024963,619.452148 
	C275.752747,622.973572 276.245575,626.136292 277.245300,629.129700 
	C279.027100,634.464844 281.115295,639.697693 282.754852,645.026245 
	C282.077515,645.554443 281.325104,646.142090 281.423370,646.488220 
	C282.821350,651.412048 284.015930,656.431702 285.923553,661.160400 
	C287.372314,664.751526 289.704590,667.999390 291.781464,671.313721 
	C292.241882,672.048523 293.217743,672.735291 294.064911,672.906494 
	C298.309509,673.763977 298.550415,676.416260 296.908478,679.522339 
	C295.234619,682.688904 296.822083,683.951233 299.030273,686.039185 
	C302.089996,688.932312 304.399017,692.691650 306.689178,696.296265 
	C307.863770,698.145020 307.838318,700.821838 309.149017,702.507080 
	C314.738251,709.693298 320.500793,716.754639 326.431183,723.663147 
	C334.510498,733.075195 342.257629,742.869507 351.159546,751.449585 
	C359.314484,759.309692 368.802795,765.788940 377.725739,772.848633 
	C382.324951,776.487427 386.793732,780.329773 391.642303,783.605164 
	C398.095917,787.964722 404.754150,792.051331 411.524231,795.904114 
	C418.479828,799.862427 425.513428,803.764343 432.817657,807.003845 
	C438.737915,809.629456 445.581879,810.409973 451.122192,813.574158 
	C455.078705,815.833801 458.600586,817.251526 463.035980,817.242249 
	C463.689270,817.240906 464.347626,819.683350 465.003662,820.995117 
	C457.055206,818.857239 448.493164,817.924133 441.317291,814.269348 
	C432.794586,809.928650 423.670197,807.596069 415.083984,803.749268 
	C412.441803,802.565613 410.401611,800.106750 407.917389,798.490540 
	C395.255310,790.252869 381.625671,783.203796 370.063568,773.647095 
	C354.455872,760.746338 338.144104,748.338928 326.372375,731.243774 
	C321.232697,723.779785 314.322662,717.553101 308.941742,710.231567 
	C304.233429,703.825317 300.527222,696.680786 296.405029,689.846313 
	C294.370056,686.472351 292.176605,683.169922 290.437775,679.646179 
	C286.458557,671.582214 282.704773,663.406738 278.879303,655.267212 
	C276.575806,650.365906 274.098969,645.530945 272.098969,640.507324 
	C270.712158,637.023865 269.993073,633.273132 269.004089,629.634094 
	C268.788116,628.839355 268.982147,627.610779 268.497498,627.261292 
	C263.619873,623.744080 265.796753,617.684326 263.321991,613.272034 
	C261.159302,609.416199 259.592072,605.226501 257.384033,601.090088 
	C257.004150,598.955566 257.002014,596.915100 257.208557,594.168945 
	C258.665894,592.535095 259.914551,591.606934 261.108551,590.719421 
	C259.595917,588.100098 257.477020,586.042969 257.289490,583.822693 
	C256.543030,574.985535 256.395386,566.097595 256.033020,557.228210 
	C255.853455,552.833374 255.649826,548.438904 255.529205,544.042297 
	C255.406738,539.577820 255.240204,535.107239 255.367157,530.647095 
	C255.416550,528.912048 257.124817,526.739990 256.524811,525.549500 
	C252.747589,518.054565 257.608398,510.957458 257.152252,503.662140 
	C257.030884,501.721649 258.104492,499.726654 258.120636,497.752533 
	C258.144226,494.871216 257.017151,491.840851 257.587646,489.127869 
	C259.997681,477.666473 262.482391,466.193207 265.681915,454.935211 
	C267.749420,447.660522 271.218750,440.793640 273.825623,433.658417 
	C274.930237,430.635040 274.952026,427.166809 276.297546,424.289642 
	C281.058533,414.109222 288.533661,405.396332 291.719391,394.243103 
	C292.791870,390.488342 294.834167,386.416382 299.184845,383.734924 
	C302.319794,381.802734 306.036407,378.161011 304.996399,372.663971 
	C304.999237,371.888580 305.000854,371.445526 305.002441,371.002441 
M277.100800,637.314087 
	C276.477020,638.108643 275.853241,638.903198 275.229462,639.697754 
	C276.006561,640.071350 276.783691,640.444946 277.560791,640.818542 
	C277.601715,639.790344 277.642639,638.762146 277.100800,637.314087 
M258.792358,552.043030 
	C259.449219,552.013916 260.106079,551.984802 260.762939,551.955688 
	C260.660950,551.499084 260.558960,551.042542 260.456970,550.585938 
	C259.839294,550.885681 259.221619,551.185425 258.792358,552.043030 
M498.520569,258.877930 
	C498.705322,258.716034 498.890076,258.554138 499.074860,258.392242 
	C498.795990,258.323059 498.517151,258.253876 498.238312,258.184692 
	C498.199707,258.394257 498.161102,258.603851 498.520569,258.877930 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M251.001465,625.567688 
	C247.853485,626.270569 246.403702,625.284546 245.815323,621.705383 
	C245.033661,616.950500 242.840073,612.445068 241.570953,607.746582 
	C240.785660,604.839294 239.728958,601.594238 240.304108,598.813110 
	C241.642075,592.343384 239.548767,586.354980 237.715103,580.710327 
	C235.751678,574.666199 237.031769,569.033325 236.719833,563.220032 
	C236.108154,551.821106 238.034653,540.521179 237.149170,529.168457 
	C236.997742,527.226929 237.022125,525.269409 237.017990,523.319153 
	C237.002289,515.891602 236.355972,508.389252 237.196533,501.058289 
	C237.976242,494.258148 240.343964,487.638916 242.037582,480.946381 
	C242.239960,480.146698 242.745850,479.408630 242.865097,478.605469 
	C244.282791,469.057983 245.653717,459.503571 247.037460,449.951019 
	C247.085144,449.621918 247.071609,449.264557 247.199570,448.969299 
	C250.661148,440.982239 253.577148,432.690308 257.784485,425.115997 
	C261.294647,418.796783 265.063171,412.925323 264.018951,405.303925 
	C263.696045,402.947144 264.187073,401.308929 267.081665,400.614929 
	C268.545746,400.263947 270.038086,398.413208 270.764404,396.882996 
	C273.891724,390.294098 277.034485,383.684143 279.611298,376.869934 
	C282.490234,369.256714 289.543518,365.313080 294.072418,359.220764 
	C298.471100,353.303619 302.252167,346.928131 306.632233,340.995941 
	C307.926910,339.242432 310.333038,338.309570 312.614929,336.999695 
	C311.151855,345.695862 304.497223,351.112976 299.112000,357.407135 
	C295.667664,361.432861 292.323212,365.544128 288.942810,369.624359 
	C288.626343,370.006378 288.141418,370.431915 288.124908,370.852509 
	C287.736053,380.761139 279.063019,386.215454 275.529999,394.373413 
	C271.767883,403.060211 268.706665,412.047974 265.132751,420.820129 
	C263.712982,424.304962 262.122131,427.842987 259.927460,430.861053 
	C256.456909,435.633698 256.429901,440.665558 256.635071,446.230011 
	C256.763275,449.708008 254.778381,453.306488 253.540894,456.779358 
	C252.454849,459.827179 251.067581,462.766693 249.860031,465.772766 
	C249.497421,466.675476 249.063385,467.638184 249.051605,468.578339 
	C248.987473,473.694946 248.108765,478.452362 246.539948,483.485657 
	C244.893021,488.769531 245.055527,494.509888 242.518906,500.014648 
	C240.537292,504.315002 241.225098,509.922455 241.044693,514.960510 
	C240.599319,527.396667 240.160110,539.838989 240.130402,552.279785 
	C240.123367,555.225769 241.749557,558.172180 242.602051,561.124878 
	C243.091400,562.819763 243.809952,564.504395 243.956650,566.233398 
	C245.046326,579.077698 245.758850,591.959717 247.132233,604.771790 
	C247.866425,611.620850 249.676529,618.354492 251.001465,625.567688 
z"/>
<path fill="#016EB0" opacity="1.000000" stroke="none" 
	d="
M304.995117,372.996277 
	C306.036407,378.161011 302.319794,381.802734 299.184845,383.734924 
	C294.834167,386.416382 292.791870,390.488342 291.719391,394.243103 
	C288.533661,405.396332 281.058533,414.109222 276.297546,424.289642 
	C274.952026,427.166809 274.930237,430.635040 273.825623,433.658417 
	C271.218750,440.793640 267.749420,447.660522 265.681915,454.935211 
	C262.482391,466.193207 259.997681,477.666473 257.587646,489.127869 
	C257.017151,491.840851 258.144226,494.871216 258.120636,497.752533 
	C258.104492,499.726654 257.030884,501.721649 257.152252,503.662140 
	C257.608398,510.957458 252.747589,518.054565 256.524811,525.549500 
	C257.124817,526.739990 255.416550,528.912048 255.367157,530.647095 
	C255.240204,535.107239 255.406738,539.577820 255.529205,544.042297 
	C255.649826,548.438904 255.853455,552.833374 256.033020,557.228210 
	C256.395386,566.097595 256.543030,574.985535 257.289490,583.822693 
	C257.477020,586.042969 259.595917,588.100098 261.108551,590.719421 
	C259.914551,591.606934 258.665894,592.535095 257.208923,593.731628 
	C255.681198,590.320007 254.380600,586.633118 253.035782,582.962463 
	C252.149216,580.542603 251.146271,578.164490 250.296646,575.732300 
	C249.767593,574.217834 249.067429,572.639587 249.058075,571.086182 
	C248.965729,555.769348 248.548615,540.433350 249.163162,525.140625 
	C249.565750,515.122559 251.295547,505.132782 252.836014,495.193115 
	C254.180023,486.520935 255.896790,477.893402 257.803650,469.325104 
	C259.877472,460.006714 263.085571,450.880432 264.396454,441.474884 
	C265.197174,435.729645 267.647003,431.873688 271.460754,428.155579 
	C272.372223,427.266968 273.029572,425.530243 272.907104,424.270782 
	C272.391846,418.971619 274.706055,415.026642 277.363800,410.587433 
	C282.082031,402.706665 285.503510,394.049927 289.491730,385.729462 
	C289.614471,385.473419 290.006165,385.346283 290.849640,385.326782 
	C293.346893,383.877838 295.335602,382.334900 297.170837,380.626984 
	C299.836975,378.145782 302.392456,375.545685 304.995117,372.996277 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M278.265381,696.772827 
	C277.042023,695.916870 275.812286,694.972534 275.163940,693.725830 
	C273.173370,689.897949 271.869751,685.633850 269.449127,682.125916 
	C265.158600,675.908142 262.175934,669.421570 261.934052,661.771118 
	C261.895782,660.561035 261.287811,659.217896 260.563446,658.208923 
	C258.389130,655.180420 255.464539,652.551575 253.913437,649.257874 
	C252.642670,646.559509 252.975739,643.122986 252.480316,640.032532 
	C252.313171,638.989807 252.050629,637.374084 251.380951,637.092529 
	C245.657349,634.686584 248.837158,631.506348 250.729767,628.217407 
	C252.106873,629.959106 253.206528,631.916626 254.653076,633.937073 
	C255.000000,634.000000 255.000610,634.000000 255.000610,634.000000 
	C254.308197,637.343750 254.462769,639.655701 257.948395,642.233276 
	C260.455109,644.086975 260.680969,649.011230 261.954376,652.554993 
	C262.336670,653.618958 262.527527,655.180908 263.303711,655.605713 
	C269.253235,658.862366 268.326294,665.094299 270.126160,670.213806 
	C271.540466,674.236633 273.912231,677.955078 276.109558,681.659973 
	C277.786194,684.487000 280.638794,686.866028 281.459839,689.858276 
	C281.960175,691.681824 279.600189,694.290222 278.265381,696.772827 
z"/>
<path fill="#0673B7" opacity="1.000000" stroke="none" 
	d="
M304.996399,372.663971 
	C302.392456,375.545685 299.836975,378.145782 297.170837,380.626984 
	C295.335602,382.334900 293.346893,383.877838 291.213379,385.245728 
	C292.272858,383.111267 293.668884,381.295868 294.792877,379.325287 
	C297.144073,375.203339 299.291809,370.964996 301.659271,366.852844 
	C302.274323,365.784637 303.392090,365.005890 304.637024,364.046722 
	C304.996643,366.041779 304.998138,368.084290 305.001038,370.564636 
	C305.000854,371.445526 304.999237,371.888580 304.996399,372.663971 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M254.999695,634.000000 
	C255.083832,632.529297 255.168259,631.058594 255.252701,629.587891 
	C255.822952,629.678223 256.393219,629.768494 256.963470,629.858765 
	C256.546539,631.188293 256.129578,632.517761 255.356628,633.923645 
	C255.000610,634.000000 255.000000,634.000000 254.999695,634.000000 
z"/>
<path fill="#0479C1" opacity="1.000000" stroke="none" 
	d="
M316.201660,334.226440 
	C316.491241,333.419556 316.981567,332.834381 317.735931,332.125183 
	C317.467468,332.816833 316.934967,333.632477 316.201660,334.226440 
z"/>
<path fill="#0673B7" opacity="1.000000" stroke="none" 
	d="
M369.295715,793.962891 
	C369.183838,793.448669 369.356659,792.899841 369.767792,792.178589 
	C369.864197,792.646912 369.722321,793.287537 369.295715,793.962891 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M465.001831,820.997559 
	C464.347626,819.683350 463.689270,817.240906 463.035980,817.242249 
	C458.600586,817.251526 455.078705,815.833801 451.122192,813.574158 
	C445.581879,810.409973 438.737915,809.629456 432.817657,807.003845 
	C425.513428,803.764343 418.479828,799.862427 411.524231,795.904114 
	C404.754150,792.051331 398.095917,787.964722 391.642303,783.605164 
	C386.793732,780.329773 382.324951,776.487427 377.725739,772.848633 
	C368.802795,765.788940 359.314484,759.309692 351.159546,751.449585 
	C342.257629,742.869507 334.510498,733.075195 326.431183,723.663147 
	C320.500793,716.754639 314.738251,709.693298 309.149017,702.507080 
	C307.838318,700.821838 307.863770,698.145020 306.689178,696.296265 
	C304.399017,692.691650 302.089996,688.932312 299.030273,686.039185 
	C296.822083,683.951233 295.234619,682.688904 296.908478,679.522339 
	C298.550415,676.416260 298.309509,673.763977 294.064911,672.906494 
	C293.217743,672.735291 292.241882,672.048523 291.781464,671.313721 
	C289.704590,667.999390 287.372314,664.751526 285.923553,661.160400 
	C284.015930,656.431702 282.821350,651.412048 281.423370,646.488220 
	C281.325104,646.142090 282.077515,645.554443 283.049011,645.191101 
	C285.189392,647.919556 286.730225,650.527405 288.237305,653.154541 
	C290.495300,657.091003 292.729584,661.041077 295.048828,665.333069 
	C295.886932,666.084839 296.650177,666.488831 297.611176,667.003296 
	C298.032562,667.353699 298.114380,667.634888 297.896027,668.102295 
	C298.010742,668.455811 298.283813,668.664490 298.767090,669.041382 
	C299.173950,669.700684 299.370636,670.191589 299.314301,670.813843 
	C299.688965,671.644836 300.316650,672.344482 301.196411,673.187622 
	C301.636169,673.871948 301.823853,674.412903 301.960876,675.203979 
	C301.882172,675.622009 301.854156,675.789856 301.601868,676.213989 
	C302.735291,680.080444 303.409882,684.181702 305.583588,687.205139 
	C311.268524,695.112305 317.543854,702.601929 323.750610,710.120300 
	C324.801544,711.393311 326.681061,711.982361 328.130005,712.903198 
	C328.082214,712.919067 328.077637,712.818481 328.175842,713.077393 
	C328.577637,713.531433 328.881165,713.726562 329.137390,713.921143 
	C329.090027,713.920593 329.119843,713.830688 329.082092,714.221130 
	C331.481689,718.368164 333.355286,722.722290 336.519897,725.707947 
	C339.494446,728.514282 342.856293,730.409180 341.342865,734.397583 
	C344.003845,735.762939 346.270691,736.754944 348.352936,738.044922 
	C350.251770,739.221375 352.192474,740.497803 353.689941,742.121887 
	C357.491913,746.245300 363.630646,747.760681 365.619781,754.199768 
	C366.328857,756.495056 370.847015,757.622131 373.638580,759.261902 
	C374.959045,760.037659 376.476288,760.571594 377.607056,761.556396 
	C379.638000,763.325256 381.123322,766.014465 383.415283,767.125366 
	C387.553802,769.131470 391.606934,770.675110 389.962494,776.716675 
	C395.768982,774.552856 398.919006,775.275818 398.849762,778.653320 
	C398.752258,783.409485 401.406158,783.053284 404.623901,782.967834 
	C406.556366,782.916504 408.835968,783.085266 410.381561,784.066101 
	C413.357727,785.954651 415.786987,788.668213 419.775177,788.544983 
	C420.448059,788.524292 421.393707,789.647034 421.815613,790.450378 
	C424.356079,795.287720 427.306732,796.515991 432.271820,794.050903 
	C434.816528,792.787537 436.350769,792.900146 438.263245,795.125244 
	C440.775116,798.047668 443.369659,801.290894 446.648438,803.068237 
	C451.302765,805.591248 456.514191,807.271118 461.663788,808.656250 
	C471.566681,811.319763 481.520813,813.909241 491.590668,815.799805 
	C500.121094,817.401367 508.820618,818.337830 517.488770,818.910156 
	C528.334595,819.626343 539.230408,820.047607 550.094360,819.889221 
	C559.749329,819.748474 569.408936,818.828186 579.026733,817.857788 
	C582.216919,817.535889 585.243958,815.313599 588.414062,815.127686 
	C598.761292,814.520935 608.554382,811.622620 618.366516,808.724243 
	C623.029785,807.346802 627.621948,805.725769 632.232422,804.173767 
	C635.261963,803.153992 638.250488,802.010437 641.290527,801.024353 
	C642.523132,800.624512 643.960876,800.743347 645.104919,800.202515 
	C651.373596,797.238586 657.644226,794.265442 663.781555,791.042725 
	C666.994141,789.355896 669.806274,785.769348 673.025452,785.469421 
	C679.681641,784.849121 681.729797,781.033142 682.557007,774.975586 
	C690.781372,776.597412 696.068604,771.627869 701.560120,767.016663 
	C704.217896,764.785095 706.986023,762.673279 709.806091,760.649292 
	C715.868713,756.297852 722.324158,752.422058 727.981201,747.603149 
	C731.594971,744.524719 734.067932,740.107117 737.052429,736.289917 
	C736.798767,736.017334 736.545044,735.744812 736.291382,735.472229 
	C734.734741,735.807251 733.178101,736.142334 731.621460,736.477417 
	C731.496277,736.000732 731.371155,735.524109 731.246033,735.047424 
	C733.456116,734.365601 735.717957,732.984009 737.865784,733.143127 
	C742.796570,733.508240 743.546875,731.113403 742.878723,727.151917 
	C742.732300,726.283875 742.695801,725.397339 742.724731,725.689453 
	C745.221252,724.659607 747.058167,723.901855 748.895081,723.144104 
	C746.633362,719.538208 750.127869,718.732300 751.777893,716.843628 
	C754.699707,713.499146 757.301514,709.874451 760.020691,706.354187 
	C760.418579,705.839050 760.619751,704.891846 761.089478,704.755249 
	C766.358032,703.223694 766.388733,697.627258 768.826172,694.108215 
	C774.127930,686.454163 777.758423,677.659058 782.350952,669.487061 
	C783.786377,666.932800 786.114990,664.880493 787.719971,662.972595 
	C788.332031,660.664673 788.607544,658.439575 789.498596,656.496887 
	C791.826782,651.421570 794.970093,646.660583 796.799438,641.428528 
	C800.491394,630.869568 803.842163,620.166016 806.749756,609.364929 
	C809.952942,597.465942 813.797302,585.610596 813.055908,572.614014 
	C815.467957,565.948547 816.278809,559.572571 814.230957,552.692383 
	C814.267822,551.921753 814.338806,551.484314 814.758423,550.891296 
	C815.363037,550.321838 815.853882,549.900635 815.841248,549.495056 
	C815.340454,533.449463 814.747559,517.406555 814.332092,501.358917 
	C814.294434,499.904144 815.360046,498.420746 816.214355,497.188049 
	C818.341736,506.409058 821.398743,515.348022 821.726929,524.386047 
	C822.329407,540.975830 821.478027,557.629395 820.898804,574.244751 
	C820.711914,579.607117 820.091736,585.113647 818.584839,590.234985 
	C816.092834,598.704834 814.369385,607.154297 815.000000,616.000000 
	C814.998718,618.698608 814.997375,621.397156 814.595825,624.547729 
	C807.865417,625.345276 810.083557,630.625610 809.000000,634.000000 
	C808.999573,634.443909 808.999146,634.887817 808.637207,635.712830 
	C803.532104,641.503784 802.648987,648.399231 801.000000,655.000000 
	C800.999390,655.443909 800.998779,655.887817 800.650757,656.707886 
	C799.555298,657.686890 798.495728,658.136353 798.110840,658.917969 
	C795.948120,663.311096 793.596069,667.659912 791.969177,672.257507 
	C789.051025,680.504272 780.744995,685.658447 779.433899,694.806396 
	C779.034119,697.595886 777.832153,700.270386 776.997559,702.997559 
	C776.997559,702.997559 776.995728,703.496338 776.650635,703.786316 
	C772.208984,706.605530 768.734192,709.550842 768.997559,714.997559 
	C768.997559,714.997559 768.995972,715.497314 768.646973,715.788513 
	C767.843384,716.274902 767.282532,716.360107 766.949829,716.681458 
	C759.102051,724.263428 751.070129,731.670227 744.996460,740.880371 
	C743.934937,742.490112 742.434265,743.974365 740.814636,745.016418 
	C737.795471,746.958923 736.517517,749.497192 736.997559,752.996704 
	C736.997559,752.996704 737.000000,753.000000 736.609497,752.999512 
	C734.560181,753.595276 732.582214,753.803040 731.299805,754.857117 
	C726.568481,758.745972 722.323608,763.258667 717.427551,766.908691 
	C706.272583,775.224915 694.846008,783.177246 683.510986,791.251099 
	C682.582458,791.912537 681.541992,792.440796 680.501587,792.917358 
	C673.844238,795.966614 666.503418,798.051880 660.668396,802.239197 
	C652.723083,807.941040 643.916870,810.474854 634.901367,813.205017 
	C632.563354,813.913025 630.945984,817.000549 628.997559,818.995117 
	C628.248169,818.996765 627.498779,818.998413 626.141602,818.713989 
	C619.420349,814.246521 613.973938,818.889832 610.239319,821.651611 
	C606.737366,824.241272 603.821777,824.794067 600.115662,825.027588 
	C596.993408,825.224304 593.885559,825.665039 590.775085,826.030029 
	C582.424438,827.009949 574.095459,828.582458 565.722046,828.868042 
	C551.780273,829.343689 537.762939,828.302124 523.869568,829.249756 
	C514.916565,829.860474 506.758026,826.781494 498.096619,826.217651 
	C486.999725,825.495300 476.029602,822.825745 465.004883,820.995117 
	C465.004883,820.995117 465.000000,821.000000 465.001831,820.997559 
M435.095093,797.299988 
	C434.573517,797.485718 434.051941,797.671387 433.530365,797.857178 
	C433.651733,798.126526 433.773102,798.395874 433.894470,798.665222 
	C434.431427,798.350952 434.968353,798.036682 435.095093,797.299988 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M747.926819,350.025604 
	C749.472534,349.614929 751.018188,349.204285 752.876526,348.710541 
	C753.347900,349.300720 754.393188,350.544128 755.366821,351.841309 
	C757.897217,355.212463 759.893188,360.811218 763.014771,361.523682 
	C769.104004,362.913544 767.925171,368.483002 770.969666,371.394928 
	C774.123230,374.411224 776.259338,378.522522 778.684204,382.256348 
	C779.934998,384.182373 780.775574,386.369720 781.928223,388.365845 
	C783.522705,391.127350 785.220398,393.830017 786.905945,396.537964 
	C787.855652,398.063812 788.812134,399.591675 789.870911,401.041687 
	C790.930298,402.492340 792.150757,403.824249 793.244385,405.251282 
	C794.128662,406.404999 795.423889,407.523438 795.685852,408.828339 
	C797.229065,416.517883 800.744446,423.313110 804.273682,430.222229 
	C806.587585,434.752106 807.460632,440.011475 809.045349,444.922729 
	C810.082336,448.136505 811.504272,451.248657 812.282532,454.518494 
	C813.701904,460.481873 814.812561,466.520782 815.942444,472.548737 
	C816.113281,473.460358 815.693909,474.482574 815.009766,475.529297 
	C812.652710,474.062500 810.833435,472.520081 808.950745,470.589844 
	C807.578430,464.488708 806.269653,458.775452 805.118286,452.714417 
	C805.780151,450.967804 806.440796,449.603607 806.760376,448.163727 
	C807.807129,443.447662 804.778931,439.423157 799.816711,438.657104 
	C795.362915,428.616211 791.044373,418.912292 786.773804,409.187256 
	C785.744812,406.844025 784.874878,404.430878 784.195740,401.819275 
	C785.435547,397.891418 782.460449,399.444305 780.965576,398.683655 
	C778.243286,394.930298 775.555237,391.478241 772.752319,387.968811 
	C772.637390,387.911438 772.596069,387.657867 772.695923,387.244080 
	C772.409790,385.081177 772.509521,382.988708 771.556519,381.640381 
	C766.562439,374.574646 761.317078,367.685913 756.127319,360.759399 
	C753.427612,357.156158 750.662415,353.601929 747.926819,350.025604 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M465.013916,821.400024 
	C476.029602,822.825745 486.999725,825.495300 498.096619,826.217651 
	C506.758026,826.781494 514.916565,829.860474 523.869568,829.249756 
	C537.762939,828.302124 551.780273,829.343689 565.722046,828.868042 
	C574.095459,828.582458 582.424438,827.009949 590.775085,826.030029 
	C593.885559,825.665039 596.993408,825.224304 600.115662,825.027588 
	C603.821777,824.794067 606.737366,824.241272 610.239319,821.651611 
	C613.973938,818.889832 619.420349,814.246521 625.766846,818.713867 
	C621.664246,820.998169 617.495483,823.628235 612.949280,824.829895 
	C607.630981,826.235535 601.939575,826.155518 596.489258,827.152893 
	C588.106262,828.687073 579.852966,831.035889 571.429138,832.211426 
	C562.982849,833.390015 554.657715,835.666748 545.848022,834.372803 
	C539.565063,833.449890 532.726074,832.766541 526.716431,834.281738 
	C514.892822,837.262695 505.703339,830.691956 495.484131,827.774170 
	C494.690979,827.547729 493.919983,827.144531 493.115967,827.070496 
	C485.283234,826.349487 477.426605,825.835693 469.625000,824.872437 
	C467.989624,824.670532 466.551788,822.869202 465.013916,821.400024 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M815.369202,615.951660 
	C814.369385,607.154297 816.092834,598.704834 818.584839,590.234985 
	C820.091736,585.113647 820.711914,579.607117 820.898804,574.244751 
	C821.478027,557.629395 822.329407,540.975830 821.726929,524.386047 
	C821.398743,515.348022 818.341736,506.409058 816.230957,496.831726 
	C815.950378,495.525574 815.954407,494.813049 816.122559,493.720459 
	C816.194458,489.224091 816.102356,485.107849 816.010254,480.991608 
	C817.339478,482.259369 819.516418,483.329376 819.865479,484.825806 
	C823.007812,498.297089 825.203674,511.874023 825.161987,525.823303 
	C825.134460,535.028870 826.879700,544.240601 826.836426,553.444885 
	C826.799561,561.286926 825.344543,569.122498 824.504395,576.959656 
	C824.469727,577.282776 824.261047,577.584961 824.210449,577.908936 
	C822.465027,589.085632 820.829346,600.281372 818.880249,611.422302 
	C818.595581,613.049438 816.822754,614.416199 815.369202,615.951660 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M747.724731,349.912720 
	C750.662415,353.601929 753.427612,357.156158 756.127319,360.759399 
	C761.317078,367.685913 766.562439,374.574646 771.556519,381.640381 
	C772.509521,382.988708 772.409790,385.081177 772.423218,387.028809 
	C771.574646,386.546600 771.076233,385.880432 770.626038,385.183075 
	C746.073242,347.153748 714.230957,316.660065 674.621460,294.850311 
	C658.698608,286.082825 641.443237,279.735168 624.794373,272.286163 
	C630.111389,273.263916 635.972229,273.131683 640.646545,275.421448 
	C656.552795,283.213257 672.363525,291.315674 687.582153,300.357483 
	C699.028442,307.158020 709.990479,314.997986 720.278625,323.457092 
	C729.606262,331.126465 737.731445,340.258270 746.681091,348.905121 
	C747.088196,349.361755 747.270630,349.606720 747.724731,349.912720 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M629.387695,818.988892 
	C630.945984,817.000549 632.563354,813.913025 634.901367,813.205017 
	C643.916870,810.474854 652.723083,807.941040 660.668396,802.239197 
	C666.503418,798.051880 673.844238,795.966614 680.501587,792.917358 
	C681.541992,792.440796 682.582458,791.912537 683.510986,791.251099 
	C694.846008,783.177246 706.272583,775.224915 717.427551,766.908691 
	C722.323608,763.258667 726.568481,758.745972 731.299805,754.857117 
	C732.582214,753.803040 734.560181,753.595276 736.607788,752.998291 
	C735.543030,754.942993 734.399719,757.262695 732.582458,758.769592 
	C724.450867,765.512451 716.417358,772.436096 707.792847,778.504822 
	C698.790771,784.839233 689.232788,790.403870 679.765625,796.049683 
	C673.139587,800.001282 666.461792,803.979004 659.440735,807.128235 
	C649.733643,811.482239 639.681885,815.067932 629.387695,818.988892 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M777.334473,702.942688 
	C777.832153,700.270386 779.034119,697.595886 779.433899,694.806396 
	C780.744995,685.658447 789.051025,680.504272 791.969177,672.257507 
	C793.596069,667.659912 795.948120,663.311096 798.110840,658.917969 
	C798.495728,658.136353 799.555298,657.686890 800.650391,657.040771 
	C799.155273,662.355469 797.907776,668.019043 795.344482,673.006470 
	C790.334045,682.755249 784.597778,692.131714 779.122742,701.639648 
	C778.822388,702.161316 778.163391,702.476501 777.334473,702.942688 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M737.342529,752.958618 
	C736.517517,749.497192 737.795471,746.958923 740.814636,745.016418 
	C742.434265,743.974365 743.934937,742.490112 744.996460,740.880371 
	C751.070129,731.670227 759.102051,724.263428 766.949829,716.681458 
	C767.282532,716.360107 767.843384,716.274902 768.646606,716.038452 
	C768.483276,717.325989 768.245483,718.865112 767.413818,719.948425 
	C763.052551,725.629272 758.810364,731.438843 754.008606,736.733887 
	C748.868591,742.401794 743.152344,747.547180 737.342529,752.958618 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M816.001221,480.623901 
	C816.102356,485.107849 816.194458,489.224091 815.827942,493.804138 
	C814.580200,493.083160 813.367615,491.994476 813.073364,490.697449 
	C811.588806,484.154022 810.343506,477.556305 809.014221,470.977661 
	C810.833435,472.520081 812.652710,474.062500 814.735962,475.802490 
	C815.330688,477.418732 815.661438,478.837463 816.001221,480.623901 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M801.348083,654.958984 
	C802.648987,648.399231 803.532104,641.503784 808.636658,636.045776 
	C809.529602,643.448914 808.628662,650.347229 801.348083,654.958984 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M769.344727,714.958008 
	C768.734192,709.550842 772.208984,706.605530 776.650330,704.035706 
	C774.560730,707.636230 772.126282,711.277344 769.344727,714.958008 
z"/>
<path fill="#0169A8" opacity="1.000000" stroke="none" 
	d="
M809.360901,633.957886 
	C810.083557,630.625610 807.865417,625.345276 814.594116,624.997437 
	C814.311401,628.604248 814.323303,632.622986 809.360901,633.957886 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M624.810364,272.276978 
	C641.443237,279.735168 658.698608,286.082825 674.621460,294.850311 
	C714.230957,316.660065 746.073242,347.153748 770.626038,385.183075 
	C771.076233,385.880432 771.574646,386.546600 772.323364,387.442566 
	C772.596069,387.657867 772.637390,387.911438 772.813599,388.318726 
	C796.250916,426.313385 809.965637,467.119080 813.492188,511.172821 
	C814.551392,524.403809 814.144897,537.752197 814.409790,551.046814 
	C814.338806,551.484314 814.267822,551.921753 814.063965,553.091736 
	C813.331787,560.223511 812.732544,566.622742 812.026001,573.418945 
	C806.632629,605.583557 797.765991,636.259338 781.641968,664.284790 
	C773.044556,679.228210 763.789856,694.024841 753.045959,707.459473 
	C717.651428,751.718201 672.461243,782.123840 618.050537,798.110413 
	C553.101685,817.193054 489.355621,813.007446 427.275208,785.960388 
	C407.136047,777.186218 388.145416,766.245178 371.072174,752.317566 
	C362.075470,744.978516 353.486298,737.127075 344.901550,729.300293 
	C339.461517,724.340576 334.368469,719.000305 329.119843,713.830688 
	C329.119843,713.830688 329.090027,713.920593 329.047302,713.655823 
	C328.695557,713.200195 328.386597,713.009338 328.077637,712.818481 
	C328.077637,712.818481 328.082214,712.919067 328.084137,712.555054 
	C319.759369,700.457703 311.432648,688.724365 303.148193,676.675232 
	C302.797455,675.890869 302.404480,675.422363 302.011536,674.953796 
	C301.823853,674.412903 301.636169,673.871948 301.169342,672.903809 
	C300.449249,671.878601 300.008270,671.280579 299.567322,670.682556 
	C299.370636,670.191589 299.173950,669.700684 298.710876,668.774170 
	C298.314453,668.211487 298.184418,668.084412 298.054413,667.957397 
	C298.114380,667.634888 298.032562,667.353699 297.558960,666.706543 
	C297.011597,665.794495 296.714172,665.289734 296.339844,664.426636 
	C293.783020,658.989624 290.937073,654.053467 288.894226,648.804749 
	C283.478821,634.891174 278.410553,620.842407 273.203857,606.537842 
	C273.083252,605.834656 272.963013,605.441040 272.872162,604.649902 
	C265.052612,574.433777 262.049896,544.102600 264.955658,513.434265 
	C266.484375,497.300171 269.827850,481.337982 272.351868,465.298187 
	C272.351898,465.298187 272.375549,465.183807 272.555359,465.086792 
	C272.775574,464.662201 272.816010,464.334656 272.856445,464.007080 
	C272.965515,463.582672 273.074585,463.158234 273.406250,462.124451 
	C286.046936,416.779205 308.225891,377.561951 340.347046,344.041290 
	C347.110260,336.983429 354.392944,330.423401 361.436249,323.633972 
	C361.436249,323.633972 361.543549,323.563385 361.905396,323.435547 
	C397.137085,294.472656 436.512299,274.838257 480.807617,265.242279 
	C513.368225,258.188477 546.190735,256.709778 579.067993,262.043274 
	C594.458069,264.539917 609.561523,268.803192 624.794922,272.265808 
	C624.794922,272.265808 624.826355,272.267761 624.810364,272.276978 
M424.193512,290.547272 
	C403.027527,300.240692 383.587708,312.677063 365.945160,327.829315 
	C306.708130,378.704712 274.061798,443.162292 270.085663,521.123413 
	C266.371368,593.950989 288.727173,658.822876 337.565399,712.936584 
	C399.721069,781.806396 478.111084,811.518494 570.550415,801.939514 
	C626.140869,796.178955 675.164368,774.075439 716.863831,736.963318 
	C776.328003,684.040466 807.111145,617.348389 808.729797,537.702271 
	C809.722412,488.865173 797.768066,443.038483 773.369568,400.650116 
	C743.793396,349.266479 701.768066,311.452606 647.646912,287.384186 
	C604.590942,268.236633 559.373657,261.591675 512.504944,266.037659 
	C481.875641,268.943176 452.645630,276.958191 424.193512,290.547272 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M624.804565,271.897339 
	C609.561523,268.803192 594.458069,264.539917 579.067993,262.043274 
	C546.190735,256.709778 513.368225,258.188477 480.807617,265.242279 
	C436.512299,274.838257 397.137085,294.472656 361.960785,323.403015 
	C362.679230,321.628967 363.277222,319.237305 364.807617,317.986359 
	C369.783539,313.919067 374.943390,310.028229 380.324036,306.516266 
	C387.946991,301.540710 395.930481,297.118988 403.575714,292.175690 
	C413.185455,285.962189 423.534546,281.420258 434.307526,277.737732 
	C446.965454,273.410858 459.497406,268.641785 472.338989,264.951935 
	C480.874817,262.499298 489.868805,261.661591 498.637604,259.995209 
	C517.273071,256.453857 536.162537,256.247589 554.920410,257.275024 
	C572.591003,258.242950 590.413940,259.470123 607.393799,265.577942 
	C612.498657,267.414185 617.880127,268.474518 623.105164,269.992584 
	C623.767700,270.185059 624.248901,271.001526 624.804565,271.897339 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M361.069824,323.465637 
	C354.392944,330.423401 347.110260,336.983429 340.347046,344.041290 
	C308.225891,377.561951 286.046936,416.779205 273.396210,461.794952 
	C273.008606,460.568054 272.338379,458.887421 272.779022,457.581696 
	C276.805176,445.650879 280.420532,433.527008 285.359467,421.974091 
	C290.174194,410.711792 296.058716,399.859009 302.115021,389.190552 
	C306.899414,380.762726 312.439514,372.732269 318.074158,364.832184 
	C320.850128,360.940125 324.547058,357.691071 327.918671,354.240387 
	C337.749390,344.179108 347.602631,334.139404 357.536987,324.180939 
	C358.210724,323.505585 359.630585,323.574554 361.069824,323.465637 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M271.965790,465.266174 
	C269.827850,481.337982 266.484375,497.300171 264.955658,513.434265 
	C262.049896,544.102600 265.052612,574.433777 272.528778,604.556763 
	C270.264923,600.631531 267.989655,596.521484 266.620453,592.129089 
	C265.736206,589.292297 266.179352,586.041687 266.027649,582.976562 
	C265.681641,580.265747 265.335602,577.554932 264.968567,574.134399 
	C264.645569,572.938721 264.343567,572.452759 264.041595,571.966858 
	C264.035919,571.230164 264.030243,570.493469 264.024231,568.968750 
	C263.695648,566.111816 263.367432,564.042969 263.039215,561.974060 
	C263.030884,560.598328 263.022552,559.222595 263.014832,557.064392 
	C262.686066,555.167603 262.356628,554.053284 262.027191,552.938965 
	C262.027893,551.572205 262.028625,550.205383 262.029663,547.957764 
	C262.026978,540.377380 262.023956,533.677734 262.020935,526.978149 
	C261.694641,523.607727 261.368378,520.237244 261.041260,516.117432 
	C261.047150,514.935791 261.053864,514.503479 261.060577,514.071228 
	C262.043121,509.311188 263.134399,504.570465 263.985565,499.787048 
	C265.740021,489.927399 267.287170,480.030243 269.119141,470.185638 
	C269.443451,468.442871 270.734894,466.880066 271.965790,465.266174 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M273.204193,606.847473 
	C278.410553,620.842407 283.478821,634.891174 288.894226,648.804749 
	C290.937073,654.053467 293.783020,658.989624 295.981262,664.493652 
	C295.457764,664.941162 295.215881,664.963257 294.973999,664.985352 
	C292.729584,661.041077 290.495300,657.091003 288.237305,653.154541 
	C286.730225,650.527405 285.189392,647.919556 283.369507,645.138184 
	C281.115295,639.697693 279.027100,634.464844 277.245300,629.129700 
	C276.245575,626.136292 275.752747,622.973572 274.982178,619.071289 
	C274.356659,614.452820 273.780426,610.650146 273.204193,606.847473 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M273.203857,606.537842 
	C273.780426,610.650146 274.356659,614.452820 274.975616,618.636292 
	C269.522614,618.539978 264.840546,611.667175 266.978027,606.698303 
	C268.179443,603.905396 268.168060,602.136719 265.981720,599.713867 
	C262.113464,595.427002 262.024353,586.267456 265.639038,583.042358 
	C266.179352,586.041687 265.736206,589.292297 266.620453,592.129089 
	C267.989655,596.521484 270.264923,600.631531 272.499390,604.954346 
	C272.963013,605.441040 273.083252,605.834656 273.203857,606.537842 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M261.628052,527.084351 
	C262.023956,533.677734 262.026978,540.377380 262.025055,547.547729 
	C261.566162,547.827454 260.712646,547.629089 260.715973,547.446411 
	C260.839722,540.693604 261.042877,533.942139 261.628052,527.084351 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M262.761230,562.243286 
	C263.367432,564.042969 263.695648,566.111816 264.017944,568.600464 
	C263.502472,566.850952 262.992859,564.681763 262.761230,562.243286 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M277.392151,637.524048 
	C277.642639,638.762146 277.601715,639.790344 277.560791,640.818542 
	C276.783691,640.444946 276.006561,640.071350 275.229462,639.697754 
	C275.853241,638.903198 276.477020,638.108643 277.392151,637.524048 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M258.698120,551.764099 
	C259.221619,551.185425 259.839294,550.885681 260.456970,550.585938 
	C260.558960,551.042542 260.660950,551.499084 260.762939,551.955688 
	C260.106079,551.984802 259.449219,552.013916 258.698120,551.764099 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M262.017090,553.311035 
	C262.356628,554.053284 262.686066,555.167603 263.008362,556.651733 
	C262.669800,555.908691 262.338379,554.795898 262.017090,553.311035 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M263.819153,572.143188 
	C264.343567,572.452759 264.645569,572.938721 264.955658,573.723633 
	C264.508087,573.454895 264.052399,572.887146 263.819153,572.143188 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M498.321533,258.845673 
	C498.161102,258.603851 498.199707,258.394257 498.238281,258.184692 
	C498.517151,258.253876 498.795990,258.323059 499.074860,258.392242 
	C498.890076,258.554138 498.705322,258.716034 498.321533,258.845673 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M260.879974,513.918091 
	C261.053864,514.503479 261.047150,514.935791 261.037384,515.693420 
	C260.922699,515.267517 260.811035,514.516235 260.879974,513.918091 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M272.660828,464.165039 
	C272.816010,464.334656 272.775574,464.662201 272.523621,465.135803 
	C272.363159,464.962189 272.414215,464.642578 272.660828,464.165039 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M329.082092,714.221130 
	C334.368469,719.000305 339.461517,724.340576 344.901550,729.300293 
	C353.486298,737.127075 362.075470,744.978516 371.072174,752.317566 
	C388.145416,766.245178 407.136047,777.186218 427.275208,785.960388 
	C489.355621,813.007446 553.101685,817.193054 618.050537,798.110413 
	C672.461243,782.123840 717.651428,751.718201 753.045959,707.459473 
	C763.789856,694.024841 773.044556,679.228210 781.641968,664.284790 
	C797.765991,636.259338 806.632629,605.583557 812.245178,573.414307 
	C812.571594,573.012695 813.009766,572.997559 813.009766,572.997559 
	C813.797302,585.610596 809.952942,597.465942 806.749756,609.364929 
	C803.842163,620.166016 800.491394,630.869568 796.799438,641.428528 
	C794.970093,646.660583 791.826782,651.421570 789.498596,656.496887 
	C788.607544,658.439575 788.332031,660.664673 787.719971,662.972595 
	C786.114990,664.880493 783.786377,666.932800 782.350952,669.487061 
	C777.758423,677.659058 774.127930,686.454163 768.826172,694.108215 
	C766.388733,697.627258 766.358032,703.223694 761.089478,704.755249 
	C760.619751,704.891846 760.418579,705.839050 760.020691,706.354187 
	C757.301514,709.874451 754.699707,713.499146 751.777893,716.843628 
	C750.127869,718.732300 746.633362,719.538208 748.895081,723.144104 
	C747.058167,723.901855 745.221252,724.659607 742.724731,725.689453 
	C742.695801,725.397339 742.732300,726.283875 742.878723,727.151917 
	C743.546875,731.113403 742.796570,733.508240 737.865784,733.143127 
	C735.717957,732.984009 733.456116,734.365601 731.246033,735.047424 
	C731.371155,735.524109 731.496277,736.000732 731.621460,736.477417 
	C733.178101,736.142334 734.734741,735.807251 736.291382,735.472229 
	C736.545044,735.744812 736.798767,736.017334 737.052429,736.289917 
	C734.067932,740.107117 731.594971,744.524719 727.981201,747.603149 
	C722.324158,752.422058 715.868713,756.297852 709.806091,760.649292 
	C706.986023,762.673279 704.217896,764.785095 701.560120,767.016663 
	C696.068604,771.627869 690.781372,776.597412 682.557007,774.975586 
	C681.729797,781.033142 679.681641,784.849121 673.025452,785.469421 
	C669.806274,785.769348 666.994141,789.355896 663.781555,791.042725 
	C657.644226,794.265442 651.373596,797.238586 645.104919,800.202515 
	C643.960876,800.743347 642.523132,800.624512 641.290527,801.024353 
	C638.250488,802.010437 635.261963,803.153992 632.232422,804.173767 
	C627.621948,805.725769 623.029785,807.346802 618.366516,808.724243 
	C608.554382,811.622620 598.761292,814.520935 588.414062,815.127686 
	C585.243958,815.313599 582.216919,817.535889 579.026733,817.857788 
	C569.408936,818.828186 559.749329,819.748474 550.094360,819.889221 
	C539.230408,820.047607 528.334595,819.626343 517.488770,818.910156 
	C508.820618,818.337830 500.121094,817.401367 491.590668,815.799805 
	C481.520813,813.909241 471.566681,811.319763 461.663788,808.656250 
	C456.514191,807.271118 451.302765,805.591248 446.648438,803.068237 
	C443.369659,801.290894 440.775116,798.047668 438.263245,795.125244 
	C436.350769,792.900146 434.816528,792.787537 432.271820,794.050903 
	C427.306732,796.515991 424.356079,795.287720 421.815613,790.450378 
	C421.393707,789.647034 420.448059,788.524292 419.775177,788.544983 
	C415.786987,788.668213 413.357727,785.954651 410.381561,784.066101 
	C408.835968,783.085266 406.556366,782.916504 404.623901,782.967834 
	C401.406158,783.053284 398.752258,783.409485 398.849762,778.653320 
	C398.919006,775.275818 395.768982,774.552856 389.962494,776.716675 
	C391.606934,770.675110 387.553802,769.131470 383.415283,767.125366 
	C381.123322,766.014465 379.638000,763.325256 377.607056,761.556396 
	C376.476288,760.571594 374.959045,760.037659 373.638580,759.261902 
	C370.847015,757.622131 366.328857,756.495056 365.619781,754.199768 
	C363.630646,747.760681 357.491913,746.245300 353.689941,742.121887 
	C352.192474,740.497803 350.251770,739.221375 348.352936,738.044922 
	C346.270691,736.754944 344.003845,735.762939 341.342865,734.397583 
	C342.856293,730.409180 339.494446,728.514282 336.519897,725.707947 
	C333.355286,722.722290 331.481689,718.368164 329.082092,714.221130 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M814.758423,550.891296 
	C814.144897,537.752197 814.551392,524.403809 813.492188,511.172821 
	C809.965637,467.119080 796.250916,426.313385 772.928467,388.376099 
	C775.555237,391.478241 778.243286,394.930298 781.092590,398.966461 
	C782.146545,400.383667 783.039185,401.216766 783.931824,402.049866 
	C784.874878,404.430878 785.744812,406.844025 786.773804,409.187256 
	C791.044373,418.912292 795.362915,428.616211 799.823608,439.049011 
	C801.642578,444.200806 803.301697,448.631470 804.960815,453.062164 
	C806.269653,458.775452 807.578430,464.488708 808.950745,470.589844 
	C810.343506,477.556305 811.588806,484.154022 813.073364,490.697449 
	C813.367615,491.994476 814.580200,493.083160 815.663818,494.184265 
	C815.954407,494.813049 815.950378,495.525574 815.929810,496.594360 
	C815.360046,498.420746 814.294434,499.904144 814.332092,501.358917 
	C814.747559,517.406555 815.340454,533.449463 815.841248,549.495056 
	C815.853882,549.900635 815.363037,550.321838 814.758423,550.891296 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M303.105957,676.991028 
	C311.432648,688.724365 319.759369,700.457703 328.131927,712.539185 
	C326.681061,711.982361 324.801544,711.393311 323.750610,710.120300 
	C317.543854,702.601929 311.268524,695.112305 305.583588,687.205139 
	C303.409882,684.181702 302.735291,680.080444 301.775116,676.436279 
	C302.483765,676.598511 302.794861,676.794800 303.105957,676.991028 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M813.055908,572.614014 
	C813.009766,572.997559 812.571594,573.012695 812.352417,573.017334 
	C812.732544,566.622742 813.331787,560.223511 814.098083,553.424866 
	C816.278809,559.572571 815.467957,565.948547 813.055908,572.614014 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M299.314301,670.813843 
	C300.008270,671.280579 300.449249,671.878601 300.917267,672.760376 
	C300.316650,672.344482 299.688965,671.644836 299.314301,670.813843 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M435.300201,797.511230 
	C434.968353,798.036682 434.431427,798.350952 433.894470,798.665222 
	C433.773102,798.395874 433.651733,798.126526 433.530365,797.857178 
	C434.051941,797.671387 434.573517,797.485718 435.300201,797.511230 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M295.048828,665.333069 
	C295.215881,664.963257 295.457764,664.941162 296.058197,664.852051 
	C296.714172,665.289734 297.011597,665.794495 297.361206,666.596008 
	C296.650177,666.488831 295.886932,666.084839 295.048828,665.333069 
z"/>
<path fill="#266E9A" opacity="1.000000" stroke="none" 
	d="
M303.148193,676.675232 
	C302.794861,676.794800 302.483765,676.598511 301.999390,676.179932 
	C301.854156,675.789856 301.882172,675.622009 301.960876,675.203979 
	C302.404480,675.422363 302.797455,675.890869 303.148193,676.675232 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M328.175842,713.077393 
	C328.386597,713.009338 328.695557,713.200195 329.094635,713.656372 
	C328.881165,713.726562 328.577637,713.531433 328.175842,713.077393 
z"/>
<path fill="#095C8E" opacity="1.000000" stroke="none" 
	d="
M297.896027,668.102295 
	C298.184418,668.084412 298.314453,668.211487 298.500732,668.605835 
	C298.283813,668.664490 298.010742,668.455811 297.896027,668.102295 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M805.118286,452.714417 
	C803.301697,448.631470 801.642578,444.200806 799.976562,439.378235 
	C804.778931,439.423157 807.807129,443.447662 806.760376,448.163727 
	C806.440796,449.603607 805.780151,450.967804 805.118286,452.714417 
z"/>
<path fill="#01639F" opacity="1.000000" stroke="none" 
	d="
M784.195740,401.819244 
	C783.039185,401.216766 782.146545,400.383667 781.126831,399.267761 
	C782.460449,399.444305 785.435547,397.891418 784.195740,401.819244 
z"/>
<path fill="#020303" opacity="1.000000" stroke="none" 
	d="
M424.532013,290.386047 
	C452.645630,276.958191 481.875641,268.943176 512.504944,266.037659 
	C559.373657,261.591675 604.590942,268.236633 647.646912,287.384186 
	C701.768066,311.452606 743.793396,349.266479 773.369568,400.650116 
	C797.768066,443.038483 809.722412,488.865173 808.729797,537.702271 
	C807.111145,617.348389 776.328003,684.040466 716.863831,736.963318 
	C675.164368,774.075439 626.140869,796.178955 570.550415,801.939514 
	C478.111084,811.518494 399.721069,781.806396 337.565399,712.936584 
	C288.727173,658.822876 266.371368,593.950989 270.085663,521.123413 
	C274.061798,443.162292 306.708130,378.704712 365.945160,327.829315 
	C383.587708,312.677063 403.027527,300.240692 424.532013,290.386047 
M545.885742,569.220581 
	C547.256897,568.124634 548.628052,567.028687 550.720764,565.992310 
	C552.433350,565.001709 554.226807,564.127258 555.844604,563.000427 
	C563.050964,557.980957 570.354919,553.084045 577.349060,547.780884 
	C589.725830,538.396484 601.903076,528.749023 614.818848,518.980286 
	C617.907593,516.511902 620.953125,513.987061 624.092224,511.584534 
	C642.120850,497.786072 660.171631,484.016602 678.875427,470.067413 
	C686.378906,464.058472 693.882385,458.049530 701.982971,451.910522 
	C703.383606,450.318512 704.784241,448.726532 706.830627,446.983704 
	C708.164062,445.304108 709.497437,443.624512 711.064331,441.252136 
	C712.646912,436.524536 714.229492,431.796967 715.935974,426.170319 
	C715.808472,423.738983 715.680969,421.307678 715.602600,418.238556 
	C711.269226,413.100098 707.505615,407.300110 702.477173,402.968567 
	C693.527405,395.259155 684.061401,388.074371 674.312134,381.399109 
	C669.416992,378.047394 665.789429,372.327393 658.265442,371.951569 
	C646.076721,367.296875 633.812195,362.829010 621.725464,357.923004 
	C613.415283,354.549896 604.882324,353.123413 595.255554,353.066010 
	C594.485352,353.050903 593.715149,353.035797 592.342102,352.628998 
	C590.882751,352.554718 589.423340,352.480438 587.306213,352.168213 
	C586.843994,352.178131 586.381775,352.188019 585.251038,351.790070 
	C563.954956,349.382721 542.701172,346.327454 521.175110,349.317932 
	C510.502045,350.800690 499.766815,351.836395 488.353760,353.053406 
	C487.890778,353.124542 487.427795,353.195709 486.080383,353.104645 
	C479.845123,354.012085 473.403900,354.220917 467.421112,355.984619 
	C457.544434,358.896301 447.928558,362.718140 438.272491,366.342163 
	C433.012970,368.316162 427.897034,370.672699 421.971954,372.799561 
	C417.349640,375.842163 412.463898,378.554321 408.154449,381.989471 
	C395.362122,392.186371 382.745148,402.606415 370.201660,413.108856 
	C368.440918,414.583099 367.474976,417.006592 365.529999,419.479919 
	C365.396759,421.306274 365.263550,423.132629 365.025177,425.829712 
	C365.060211,427.582275 365.095245,429.334869 365.052307,431.938507 
	C366.344147,436.019714 367.635986,440.100891 369.016266,444.867462 
	C371.674774,447.580139 374.333313,450.292816 377.183533,453.656128 
	C378.781097,455.048492 380.280304,456.577179 381.991241,457.812500 
	C402.331329,472.498383 422.773315,487.044128 443.048523,501.818787 
	C468.133087,520.098022 493.017975,538.651489 518.140015,556.878601 
	C525.079773,561.913635 532.556396,566.208679 540.573608,571.035522 
	C542.117798,570.428284 543.661987,569.820984 545.885742,569.220581 
M458.641876,609.845764 
	C458.079498,609.976562 457.517090,610.107361 456.954712,610.238098 
	C457.133606,610.563904 457.312500,610.889648 457.701385,612.109497 
	C457.822052,625.481689 457.942719,638.853882 457.284210,652.652283 
	C455.589813,651.203003 453.263000,650.083557 452.306427,648.249451 
	C447.156738,638.376221 442.258270,628.367859 437.484161,618.305908 
	C436.304962,615.820679 435.911926,612.962463 435.108521,610.101318 
	C431.279724,610.101318 426.742401,610.101318 422.862305,610.101318 
	C422.384064,618.935059 417.169495,625.492676 413.984070,632.753662 
	C410.756714,640.110168 407.015747,647.241394 403.531097,654.406799 
	C407.692169,654.406799 412.052216,654.406799 416.597382,654.406799 
	C413.893341,648.689819 417.203949,643.271179 422.987061,643.238342 
	C426.153564,643.220398 429.320282,643.226318 432.486877,643.235352 
	C439.880005,643.256470 443.176880,647.305603 441.639099,654.317688 
	C447.075470,654.317688 452.535339,654.317688 458.822510,654.729187 
	C461.875244,654.639954 464.927979,654.550720 468.441833,654.695007 
	C468.340607,654.007751 468.239410,653.320496 468.046600,651.747375 
	C468.010529,646.764160 467.974487,641.780884 468.491943,636.711548 
	C469.011658,636.517761 469.531403,636.323975 470.929779,636.382141 
	C474.270844,636.298767 477.611908,636.215393 481.700684,636.392212 
	C487.041321,636.325073 492.562042,637.072754 497.679810,632.832581 
	C504.022766,624.742004 502.567017,615.298706 494.136963,611.357483 
	C493.419983,611.202332 492.703003,611.047119 491.245972,610.431458 
	C489.810455,610.351807 488.374908,610.272156 486.200745,609.885803 
	C477.815704,609.964905 469.430664,610.044006 460.359467,609.944458 
	C459.894196,610.003052 459.428925,610.061707 458.641876,609.845764 
M665.862915,652.400452 
	C662.085876,647.216797 658.308838,642.033203 654.368530,636.625549 
	C655.568481,636.009094 656.108643,635.667358 656.693420,635.440979 
	C664.174438,632.544861 666.940186,628.244080 665.945618,621.018188 
	C665.032654,614.385193 660.732361,610.347168 653.196350,610.130676 
	C642.412964,609.821045 631.614075,610.051819 621.206177,610.051819 
	C621.206177,624.916443 621.206177,639.657715 621.206177,654.455078 
	C624.941162,654.455078 628.846191,654.455078 632.274414,654.455078 
	C632.274414,648.315002 632.274414,642.501709 632.274414,635.989380 
	C636.227783,636.403809 641.513428,635.374512 643.685181,637.510803 
	C648.396851,642.145508 651.516785,648.398315 656.050293,655.120544 
	C658.110779,655.026672 662.049988,654.847168 666.928040,654.545959 
	C680.575623,654.609924 694.223633,654.635010 707.870239,654.788147 
	C709.535461,654.806824 711.192932,655.512451 713.182922,655.976379 
	C713.182922,652.718933 713.182922,649.336426 713.182922,646.424194 
	C702.497314,646.424194 691.942627,646.424194 681.479492,646.424194 
	C681.479492,642.774536 681.479492,639.172607 681.479492,635.703125 
	C688.736633,635.703125 695.666504,635.703125 702.605713,635.703125 
	C702.605713,632.923645 702.605713,629.664551 702.605713,627.118225 
	C695.390198,627.118225 688.470215,627.118225 681.529602,627.118225 
	C681.529602,623.896973 681.529602,620.822754 681.529602,617.967285 
	C692.085510,617.967285 702.299744,617.967285 712.596924,617.967285 
	C712.596924,615.002319 712.596924,611.579468 712.596924,609.398193 
	C698.465393,610.417969 684.429749,609.434937 670.300415,609.975403 
	C670.676819,611.636475 671.256775,613.024719 671.264954,614.416260 
	C671.334106,626.246643 671.307922,638.077576 671.291016,649.908386 
	C671.285400,653.848755 669.120361,654.290344 665.862915,652.400452 
M695.497925,684.721924 
	C700.163391,684.696411 704.832825,684.540405 709.493042,684.684387 
	C713.646240,684.812561 715.176208,682.872986 715.016296,678.918396 
	C714.868286,675.258545 714.993042,671.588074 714.976746,667.922363 
	C714.945679,660.935608 714.522400,660.476746 707.670044,660.475342 
	C621.026062,660.457825 534.382019,660.450684 447.738037,660.440857 
	C421.911133,660.437927 396.084259,660.439331 370.257355,660.428833 
	C361.195984,660.425171 361.194458,660.411743 361.193817,669.699829 
	C361.193665,672.032532 361.197113,674.365295 361.215363,676.697937 
	C361.274536,684.253296 361.712097,684.737366 369.082306,684.737793 
	C477.554443,684.744202 586.026611,684.739319 695.497925,684.721924 
M364.178955,650.588196 
	C366.233887,651.213440 368.343719,651.700073 370.334015,652.488525 
	C378.005341,655.527466 385.858978,656.259949 393.860199,654.267578 
	C401.833618,652.282288 406.171448,647.163635 405.897522,640.465881 
	C405.623505,633.764404 400.849182,629.077026 392.745605,627.806396 
	C388.470123,627.136047 384.067444,627.100403 379.881561,626.106812 
	C377.685150,625.585388 375.832275,623.616760 373.824249,622.301697 
	C375.816559,620.590454 377.591187,617.822998 379.840607,617.359375 
	C387.629669,615.753906 394.849701,617.470520 400.935181,623.668884 
	C402.575500,620.374878 404.094147,617.325256 405.560333,614.380981 
	C402.206024,613.233154 399.347076,611.711121 396.345612,611.339294 
	C389.832489,610.532288 383.134125,609.348511 376.718506,610.095703 
	C368.540802,611.048218 364.389496,616.790466 364.623962,624.043152 
	C364.834747,630.562317 369.428436,634.809631 377.712585,635.954651 
	C382.165802,636.570251 386.700195,636.714355 391.093262,637.594055 
	C392.896118,637.954956 394.422913,639.694458 396.075287,640.806824 
	C394.741333,642.704834 393.825073,645.458801 391.999634,646.349487 
	C384.910004,649.808960 372.834259,646.549255 367.382294,639.776001 
	C366.038147,643.456848 364.819244,646.794739 364.178955,650.588196 
M576.499207,627.649109 
	C571.561584,627.649109 566.624023,627.649109 561.999756,627.649109 
	C561.999756,621.294739 561.999756,615.688965 561.999756,610.043396 
	C558.358398,610.043396 554.415649,610.043396 550.975830,610.043396 
	C550.975830,624.928040 550.975830,639.611450 550.975830,654.386536 
	C554.459045,654.386536 558.481323,654.386536 561.941528,654.386536 
	C561.941528,647.897278 561.941528,641.754883 561.941528,635.338989 
	C570.185669,635.338989 578.433655,635.338989 586.336487,635.338989 
	C586.336487,641.773987 586.336487,648.003662 586.336487,654.491211 
	C589.728638,654.491211 593.802002,654.491211 597.550781,654.491211 
	C597.550781,639.542297 597.550781,624.808289 597.550781,610.031250 
	C593.719238,610.031250 589.781677,610.031250 586.382690,610.031250 
	C586.382690,615.965637 586.382690,621.663757 586.382690,627.614807 
	C583.472778,627.614807 580.485718,627.614807 576.499207,627.649109 
M539.008728,635.601562 
	C545.894104,633.059753 548.886108,628.459595 548.136353,621.567993 
	C547.453491,615.290649 543.414551,610.581909 536.856384,610.293640 
	C525.793823,609.807312 514.694397,610.160278 504.161438,610.160278 
	C504.161438,625.069641 504.161438,639.727966 504.161438,654.456604 
	C507.840027,654.456604 511.886383,654.456604 515.335938,654.456604 
	C515.335938,648.376526 515.335938,642.598389 515.335938,635.811279 
	C522.989136,635.811279 530.606812,635.811279 539.008728,635.601562 
M614.314331,615.071411 
	C614.659546,613.410461 615.004822,611.749573 615.344421,610.115601 
	C611.143555,610.115601 607.278259,610.115601 603.757324,610.115601 
	C603.757324,625.074463 603.757324,639.660706 603.757324,654.423218 
	C607.372620,654.423218 611.422729,654.423218 614.292786,654.423218 
	C614.292786,641.366394 614.292786,628.656433 614.314331,615.071411 
z"/>
<path fill="#0780C9" opacity="1.000000" stroke="none" 
	d="
M694.998291,684.729492 
	C586.026611,684.739319 477.554443,684.744202 369.082306,684.737793 
	C361.712097,684.737366 361.274536,684.253296 361.215363,676.697937 
	C361.197113,674.365295 361.193665,672.032532 361.193817,669.699829 
	C361.194458,660.411743 361.195984,660.425171 370.257355,660.428833 
	C396.084259,660.439331 421.911133,660.437927 447.738037,660.440857 
	C534.382019,660.450684 621.026062,660.457825 707.670044,660.475342 
	C714.522400,660.476746 714.945679,660.935608 714.976746,667.922363 
	C714.993042,671.588074 714.868286,675.258545 715.016296,678.918396 
	C715.176208,682.872986 713.646240,684.812561 709.493042,684.684387 
	C704.832825,684.540405 700.163391,684.696411 694.998291,684.729492 
M420.141510,677.331238 
	C423.194550,671.163696 426.096741,678.728577 429.570923,677.591492 
	C428.020599,674.492676 426.766449,671.619690 425.140930,668.974976 
	C424.692932,668.246094 422.291962,667.754700 422.214111,667.903076 
	C420.607086,670.965759 419.206512,674.136841 417.765015,677.286377 
	C418.371185,677.440857 418.977356,677.595398 420.141510,677.331238 
M372.549805,674.265808 
	C372.275208,680.546387 376.994812,677.677979 379.501984,677.646057 
	C383.430450,677.596008 384.103943,674.413696 383.522095,671.195007 
	C382.841187,667.428467 379.024902,666.374390 372.530579,668.125244 
	C372.530579,669.813110 372.530579,671.604248 372.549805,674.265808 
M698.781799,669.099792 
	C699.801514,669.424866 700.821228,669.749939 701.840942,670.075012 
	C698.002502,666.788879 693.457520,666.899231 692.421753,670.104492 
	C691.798828,672.032166 692.833374,676.247681 694.056274,676.658752 
	C696.691528,677.544434 699.932373,676.664246 702.902466,676.312866 
	C702.976868,676.304138 702.783752,674.034851 702.701904,672.580078 
	C700.804504,672.754761 699.417175,672.882507 698.029846,673.010254 
	C698.007202,673.245789 697.984497,673.481323 697.961853,673.716797 
	C698.887329,674.053162 699.812866,674.389465 700.738342,674.725830 
	C700.557739,675.285461 700.377136,675.845032 700.196533,676.404663 
	C698.280823,675.653015 695.407227,675.425049 694.649963,674.040039 
	C693.102539,671.209717 695.156860,669.505371 698.781799,669.099792 
M558.587158,676.815308 
	C558.421875,673.557800 560.579529,673.414795 563.050720,673.046692 
	C564.292908,672.861694 565.332397,671.315918 566.463562,670.385498 
	C565.322571,669.391296 564.311279,667.828674 563.011169,667.532288 
	C560.905151,667.052063 558.609558,667.402893 556.183899,667.402893 
	C556.183899,670.351746 555.980103,672.517761 556.271729,674.614990 
	C556.413086,675.630981 557.483765,676.517639 558.587158,676.815308 
M506.004364,675.951172 
	C505.745422,678.656128 506.807220,678.413757 508.072968,676.756348 
	C510.115448,674.081848 511.949005,674.377197 514.039734,676.682617 
	C514.619080,677.321411 515.674866,677.528198 516.512024,677.933167 
	C516.809509,677.613892 517.107056,677.294678 517.404602,676.975403 
	C515.368958,673.772583 513.333252,670.569702 511.297607,667.366821 
	C509.611328,670.010559 507.925049,672.654297 506.004364,675.951172 
M656.107483,674.339722 
	C656.107483,672.063477 656.107483,669.787231 656.107483,667.510986 
	C655.518616,667.510071 654.929749,667.509155 654.340881,667.508240 
	C654.221741,669.349304 654.437134,671.302124 653.872192,672.994324 
	C653.458130,674.234497 652.020020,675.132874 651.038330,676.183594 
	C650.062439,675.130859 648.591309,674.222595 648.231750,672.990051 
	C647.744934,671.321228 648.110657,669.403625 648.110657,667.592773 
	C647.484558,667.625305 646.858398,667.657898 646.232300,667.690491 
	C645.634705,670.928406 644.452942,674.831604 647.622009,677.052551 
	C650.027954,678.738525 654.023438,679.365051 656.107483,674.339722 
M468.733856,671.680908 
	C469.725067,670.368591 470.716248,669.056213 471.749969,667.687622 
	C469.030487,667.687622 465.971771,667.687622 464.281464,667.687622 
	C465.163361,671.116943 466.008789,674.404541 466.854218,677.692078 
	C467.246033,677.724365 467.637848,677.756714 468.029663,677.789062 
	C468.237701,676.032837 468.445740,674.276550 468.733856,671.680908 
M601.607544,675.180481 
	C602.658752,678.331543 605.580627,678.881042 611.273010,676.677673 
	C608.706909,676.385620 606.662109,676.152893 604.417419,675.897461 
	C604.132568,672.953064 603.867798,670.215698 603.602966,667.478394 
	C603.043457,667.495117 602.483887,667.511902 601.924377,667.528625 
	C601.817017,669.790527 601.709656,672.052429 601.607544,675.180481 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M585.919556,352.197937 
	C586.381775,352.188019 586.843994,352.178131 587.750610,352.590973 
	C589.778381,353.016052 591.361633,353.018402 592.944946,353.020721 
	C593.715149,353.035797 594.485352,353.050903 595.737305,353.505554 
	C598.209045,354.595276 600.286072,355.992401 602.174866,355.772583 
	C613.196899,354.489990 621.338196,360.940247 630.068420,365.775513 
	C631.014282,366.299377 631.362427,367.902405 631.611511,369.012939 
	C630.595459,369.168762 629.892334,369.192871 629.325378,369.482910 
	C620.732849,373.878387 612.127686,378.251709 603.616089,382.799744 
	C602.494812,383.398895 601.872620,384.932129 600.790894,386.308655 
	C598.696716,387.089661 596.831055,387.594757 594.968384,387.715820 
	C593.319153,385.948730 591.825745,383.671204 589.985718,383.344177 
	C583.532227,382.197205 576.959839,381.717163 570.431580,380.993011 
	C563.794617,380.256775 557.147949,380.971375 550.425110,380.460938 
	C532.640808,379.110748 514.744629,379.814362 496.907959,382.519531 
	C488.836182,383.743713 480.286682,384.280243 471.869507,381.434052 
	C467.539978,379.970062 462.315399,380.961121 457.518524,381.241119 
	C456.613525,381.293915 455.818237,383.226257 454.610504,384.218628 
	C451.465637,382.385254 448.682617,380.624603 445.871216,378.566620 
	C445.224609,377.869324 444.606323,377.469330 443.967346,377.062103 
	C443.946625,377.054840 443.929932,377.014282 443.904480,376.691101 
	C443.218994,375.958557 442.558899,375.549133 441.657898,375.109894 
	C441.416931,375.080048 440.942841,374.974976 440.952576,374.601929 
	C441.321564,373.336731 441.420410,372.017426 442.078827,371.615967 
	C453.461945,364.675568 464.196899,356.301483 478.456818,355.795410 
	C481.319122,355.693848 484.130157,354.147217 486.964813,353.266876 
	C487.427795,353.195709 487.890778,353.124542 489.000366,353.326294 
	C502.655457,352.664764 515.651367,351.295898 528.675964,350.919678 
	C541.382202,350.552612 554.118958,351.147491 566.840027,351.424103 
	C573.202454,351.562408 579.559875,351.932617 585.919556,352.197937 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M665.989136,654.667725 
	C662.049988,654.847168 658.110779,655.026672 656.050293,655.120544 
	C651.516785,648.398315 648.396851,642.145508 643.685181,637.510803 
	C641.513428,635.374512 636.227783,636.403809 632.274414,635.989380 
	C632.274414,642.501709 632.274414,648.315002 632.274414,654.455078 
	C628.846191,654.455078 624.941162,654.455078 621.206177,654.455078 
	C621.206177,639.657715 621.206177,624.916443 621.206177,610.051819 
	C631.614075,610.051819 642.412964,609.821045 653.196350,610.130676 
	C660.732361,610.347168 665.032654,614.385193 665.945618,621.018188 
	C666.940186,628.244080 664.174438,632.544861 656.693420,635.440979 
	C656.108643,635.667358 655.568481,636.009094 654.368530,636.625549 
	C658.308838,642.033203 662.085876,647.216797 665.914673,653.009338 
	C665.973999,653.968079 665.981567,654.317871 665.989136,654.667725 
M655.187317,619.529419 
	C653.740601,618.808411 652.314941,617.519653 650.843506,617.465088 
	C644.584229,617.233032 638.311340,617.366455 631.927856,617.366455 
	C631.927856,621.291626 631.927856,624.879883 631.927856,628.906128 
	C637.804626,628.906128 643.421875,629.054565 649.027771,628.866516 
	C655.250977,628.657837 656.739563,626.562378 655.187317,619.529419 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M666.458618,654.606812 
	C665.981567,654.317871 665.973999,653.968079 665.981445,653.356323 
	C669.120361,654.290344 671.285400,653.848755 671.291016,649.908386 
	C671.307922,638.077576 671.334106,626.246643 671.264954,614.416260 
	C671.256775,613.024719 670.676819,611.636475 670.300415,609.975403 
	C684.429749,609.434937 698.465393,610.417969 712.596924,609.398193 
	C712.596924,611.579468 712.596924,615.002319 712.596924,617.967285 
	C702.299744,617.967285 692.085510,617.967285 681.529602,617.967285 
	C681.529602,620.822754 681.529602,623.896973 681.529602,627.118225 
	C688.470215,627.118225 695.390198,627.118225 702.605713,627.118225 
	C702.605713,629.664551 702.605713,632.923645 702.605713,635.703125 
	C695.666504,635.703125 688.736633,635.703125 681.479492,635.703125 
	C681.479492,639.172607 681.479492,642.774536 681.479492,646.424194 
	C691.942627,646.424194 702.497314,646.424194 713.182922,646.424194 
	C713.182922,649.336426 713.182922,652.718933 713.182922,655.976379 
	C711.192932,655.512451 709.535461,654.806824 707.870239,654.788147 
	C694.223633,654.635010 680.575623,654.609924 666.458618,654.606812 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M363.889648,650.360352 
	C364.819244,646.794739 366.038147,643.456848 367.382294,639.776001 
	C372.834259,646.549255 384.910004,649.808960 391.999634,646.349487 
	C393.825073,645.458801 394.741333,642.704834 396.075287,640.806824 
	C394.422913,639.694458 392.896118,637.954956 391.093262,637.594055 
	C386.700195,636.714355 382.165802,636.570251 377.712585,635.954651 
	C369.428436,634.809631 364.834747,630.562317 364.623962,624.043152 
	C364.389496,616.790466 368.540802,611.048218 376.718506,610.095703 
	C383.134125,609.348511 389.832489,610.532288 396.345612,611.339294 
	C399.347076,611.711121 402.206024,613.233154 405.560333,614.380981 
	C404.094147,617.325256 402.575500,620.374878 400.935181,623.668884 
	C394.849701,617.470520 387.629669,615.753906 379.840607,617.359375 
	C377.591187,617.822998 375.816559,620.590454 373.824249,622.301697 
	C375.832275,623.616760 377.685150,625.585388 379.881561,626.106812 
	C384.067444,627.100403 388.470123,627.136047 392.745605,627.806396 
	C400.849182,629.077026 405.623505,633.764404 405.897522,640.465881 
	C406.171448,647.163635 401.833618,652.282288 393.860199,654.267578 
	C385.858978,656.259949 378.005341,655.527466 370.334015,652.488525 
	C368.343719,651.700073 366.233887,651.213440 363.889648,650.360352 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M576.998901,627.631958 
	C580.485718,627.614807 583.472778,627.614807 586.382690,627.614807 
	C586.382690,621.663757 586.382690,615.965637 586.382690,610.031250 
	C589.781677,610.031250 593.719238,610.031250 597.550781,610.031250 
	C597.550781,624.808289 597.550781,639.542297 597.550781,654.491211 
	C593.802002,654.491211 589.728638,654.491211 586.336487,654.491211 
	C586.336487,648.003662 586.336487,641.773987 586.336487,635.338989 
	C578.433655,635.338989 570.185669,635.338989 561.941528,635.338989 
	C561.941528,641.754883 561.941528,647.897278 561.941528,654.386536 
	C558.481323,654.386536 554.459045,654.386536 550.975830,654.386536 
	C550.975830,639.611450 550.975830,624.928040 550.975830,610.043396 
	C554.415649,610.043396 558.358398,610.043396 561.999756,610.043396 
	C561.999756,615.688965 561.999756,621.294739 561.999756,627.649109 
	C566.624023,627.649109 571.561584,627.649109 576.998901,627.631958 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M539.786621,570.843323 
	C532.556396,566.208679 525.079773,561.913635 518.140015,556.878601 
	C493.017975,538.651489 468.133087,520.098022 443.048523,501.818787 
	C422.773315,487.044128 402.331329,472.498383 381.991241,457.812500 
	C380.280304,456.577179 378.781097,455.048492 377.351379,453.185730 
	C379.667633,453.353943 381.816010,453.992615 384.157166,454.790283 
	C384.843140,455.066101 385.336365,455.182892 386.077301,455.526001 
	C390.205414,457.964081 393.994598,460.359985 397.990295,462.338898 
	C402.531616,464.588013 407.257568,466.464386 411.880310,468.848877 
	C413.538483,470.810364 415.219604,472.425751 416.970947,474.374207 
	C427.734344,484.440247 438.711761,493.783203 452.104248,500.366211 
	C460.532288,506.646820 468.972778,512.390381 476.856140,518.817322 
	C480.549164,521.828064 484.353882,523.466248 488.772888,524.272339 
	C489.361328,526.214722 489.652832,528.310791 490.856750,529.367676 
	C493.315216,531.525940 496.264587,533.125000 499.108032,535.261230 
	C499.460663,536.371338 499.717041,537.174683 499.779419,538.231567 
	C497.015686,540.376892 498.886719,541.561096 500.347290,542.621704 
	C503.295929,544.763062 506.304077,546.826416 509.341553,548.840698 
	C515.506409,552.929016 521.707031,556.963257 527.914551,561.050171 
	C527.936096,561.080566 528.009216,561.094971 528.104309,561.302612 
	C528.386536,561.759155 528.620361,561.951355 529.129517,562.358154 
	C532.508545,564.803650 535.658875,566.977844 538.934143,569.367126 
	C539.301575,570.002563 539.544128,570.422974 539.786621,570.843323 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M457.995178,654.317688 
	C452.535339,654.317688 447.075470,654.317688 441.639099,654.317688 
	C443.176880,647.305603 439.880005,643.256470 432.486877,643.235352 
	C429.320282,643.226318 426.153564,643.220398 422.987061,643.238342 
	C417.203949,643.271179 413.893341,648.689819 416.597382,654.406799 
	C412.052216,654.406799 407.692169,654.406799 403.531097,654.406799 
	C407.015747,647.241394 410.756714,640.110168 413.984070,632.753662 
	C417.169495,625.492676 422.384064,618.935059 422.862305,610.101318 
	C426.742401,610.101318 431.279724,610.101318 435.108521,610.101318 
	C435.911926,612.962463 436.304962,615.820679 437.484161,618.305908 
	C442.258270,628.367859 447.156738,638.376221 452.306427,648.249451 
	C453.263000,650.083557 455.589813,651.203003 457.669983,652.700806 
	C458.035583,653.272095 458.015381,653.794861 457.995178,654.317688 
M433.454681,631.249878 
	C432.039612,628.251465 430.624542,625.253052 428.715332,621.207642 
	C426.028809,627.148132 423.916077,631.819763 421.637848,636.857361 
	C426.683746,636.857361 430.886230,636.857361 435.719208,636.857361 
	C434.983307,634.919556 434.421082,633.439026 433.454681,631.249878 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M538.616577,635.706421 
	C530.606812,635.811279 522.989136,635.811279 515.335938,635.811279 
	C515.335938,642.598389 515.335938,648.376526 515.335938,654.456604 
	C511.886383,654.456604 507.840027,654.456604 504.161438,654.456604 
	C504.161438,639.727966 504.161438,625.069641 504.161438,610.160278 
	C514.694397,610.160278 525.793823,609.807312 536.856384,610.293640 
	C543.414551,610.581909 547.453491,615.290649 548.136353,621.567993 
	C548.886108,628.459595 545.894104,633.059753 538.616577,635.706421 
M525.426392,617.176636 
	C521.987549,617.176636 518.548706,617.176636 514.923340,617.176636 
	C514.923340,621.271545 514.923340,624.838196 514.923340,628.698059 
	C521.287720,628.698059 527.388855,628.874390 533.472351,628.623352 
	C536.489868,628.498901 538.177368,626.469910 538.193909,623.295227 
	C538.211182,619.980408 536.699097,617.879211 533.342896,617.495178 
	C531.040894,617.231689 528.704163,617.272339 525.426392,617.176636 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M458.408844,654.523438 
	C458.015381,653.794861 458.035583,653.272095 458.059570,652.487671 
	C457.942719,638.853882 457.822052,625.481689 457.887543,611.546448 
	C458.370331,610.695679 458.666992,610.407959 458.963654,610.120300 
	C459.428925,610.061707 459.894196,610.003052 461.017670,610.297852 
	C470.097046,610.498291 478.518219,610.345337 486.939392,610.192444 
	C488.374908,610.272156 489.810455,610.351807 491.765808,610.907837 
	C493.005402,611.538696 493.725128,611.693237 494.444855,611.847778 
	C502.567017,615.298706 504.022766,624.742004 496.959656,632.960327 
	C494.343689,633.688416 492.484436,634.487793 490.543884,634.843750 
	C487.374786,635.425232 484.152710,635.717651 480.952972,636.131958 
	C477.611908,636.215393 474.270844,636.298767 470.337128,636.022339 
	C469.142456,636.040833 468.540436,636.419250 467.938416,636.797668 
	C467.974487,641.780884 468.010529,646.764160 468.048157,652.493286 
	C468.026733,653.646667 468.003723,654.054077 467.980713,654.461426 
	C464.927979,654.550720 461.875244,654.639954 458.408844,654.523438 
M467.886230,621.599854 
	C467.886230,623.879822 467.886230,626.159790 467.886230,628.897705 
	C474.039459,628.897705 479.652222,628.905212 485.264954,628.895020 
	C488.985718,628.888245 491.370148,627.234619 491.465240,623.322815 
	C491.560059,619.423889 489.248352,617.526733 485.561523,617.382202 
	C480.574829,617.186707 475.569427,617.017944 470.597137,617.292664 
	C469.632294,617.345947 468.782715,619.485718 467.886230,621.599854 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M658.982849,372.053528 
	C665.789429,372.327393 669.416992,378.047394 674.312134,381.399109 
	C684.061401,388.074371 693.527405,395.259155 702.477173,402.968567 
	C707.505615,407.300110 711.269226,413.100098 715.218262,418.588074 
	C714.010193,418.917725 713.096558,419.126221 712.376404,418.842896 
	C704.595215,415.780945 696.825500,412.686859 689.110718,409.462677 
	C688.208191,409.085449 687.654175,407.874420 687.250793,406.858032 
	C687.960815,406.151459 688.608765,405.684387 688.717285,405.114594 
	C689.699219,399.957275 684.767395,400.524506 682.343323,399.170074 
	C678.126038,396.813690 673.947632,395.164215 672.432312,389.698547 
	C671.817078,387.479584 668.705200,385.952820 666.729431,384.111084 
	C665.199219,385.860504 663.655029,387.598022 662.147827,389.367004 
	C661.723022,389.865601 661.406250,390.456299 660.735718,390.864258 
	C655.560913,385.401001 650.690369,380.078278 645.957825,374.388489 
	C648.771851,373.849213 651.465637,373.810333 654.117310,373.455902 
	C655.772095,373.234772 657.363159,372.537292 658.982849,372.053528 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M366.141113,418.990723 
	C367.474976,417.006592 368.440918,414.583099 370.201660,413.108856 
	C382.745148,402.606415 395.362122,392.186371 408.154449,381.989471 
	C412.463898,378.554321 417.349640,375.842163 422.668762,373.086792 
	C426.909668,373.257141 430.453766,373.140228 433.993866,373.388885 
	C431.218140,378.100159 428.718628,382.732880 422.894714,383.763092 
	C415.142303,379.268677 413.099304,380.232727 412.862305,388.283386 
	C411.819336,389.372803 410.922272,390.192261 409.679749,391.063141 
	C407.693024,392.397888 406.174042,393.895721 404.383118,394.916290 
	C401.403198,396.614380 398.250824,398.010529 395.167328,399.526154 
	C391.800446,401.181122 391.505920,403.350616 394.005707,406.371307 
	C390.974060,408.486023 388.002289,410.238403 384.652466,411.998718 
	C378.230011,414.334656 372.185577,416.662689 366.141113,418.990723 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M678.214905,470.237366 
	C660.171631,484.016602 642.120850,497.786072 624.092224,511.584534 
	C620.953125,513.987061 617.907593,516.511902 614.205383,518.922913 
	C613.320801,518.516846 613.049622,518.168213 612.983826,517.554199 
	C621.180969,510.358185 629.178040,503.433533 637.130249,496.457825 
	C637.306335,496.303345 636.971313,495.566193 637.045715,494.801392 
	C640.492615,491.530762 643.812378,488.606262 647.034058,485.577423 
	C651.774048,481.121002 656.444031,476.590118 661.478271,472.030304 
	C662.971680,470.806549 664.130554,469.643463 665.645508,468.387909 
	C669.938599,466.417511 673.875488,464.539642 678.118408,462.849701 
	C683.547607,465.588684 677.632263,467.818481 678.214905,470.237366 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M686.938538,407.049927 
	C687.654175,407.874420 688.208191,409.085449 689.110718,409.462677 
	C696.825500,412.686859 704.595215,415.780945 712.376404,418.842896 
	C713.096558,419.126221 714.010193,418.917725 715.193726,418.906982 
	C715.680969,421.307678 715.808472,423.738983 715.548218,426.679596 
	C712.494812,431.567810 709.829163,435.946716 706.779846,440.955994 
	C708.502319,441.376465 709.666626,441.660675 710.830872,441.944916 
	C709.497437,443.624512 708.164062,445.304108 706.112732,447.005737 
	C704.790161,445.720673 703.770630,444.436432 703.696960,443.100067 
	C703.622742,441.752869 704.487854,440.353943 704.962158,438.563477 
	C704.231445,430.682220 697.516968,428.271576 692.848022,423.709839 
	C691.473938,422.609558 690.241821,421.805389 689.005432,420.575958 
	C689.001099,418.254486 689.001099,416.358246 689.001099,413.118408 
	C686.885620,414.666473 685.939087,415.359131 684.733032,415.842590 
	C682.844910,413.796906 681.285278,411.892853 679.570068,410.141205 
	C677.465698,407.992157 675.231262,405.970551 673.434631,403.912354 
	C678.190308,404.970520 682.564453,406.010254 686.938538,407.049927 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M614.303589,615.508911 
	C614.292786,628.656433 614.292786,641.366394 614.292786,654.423218 
	C611.422729,654.423218 607.372620,654.423218 603.757324,654.423218 
	C603.757324,639.660706 603.757324,625.074463 603.757324,610.115601 
	C607.278259,610.115601 611.143555,610.115601 615.344421,610.115601 
	C615.004822,611.749573 614.659546,613.410461 614.303589,615.508911 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M612.778442,517.819519 
	C613.049622,518.168213 613.320801,518.516846 613.877869,519.038635 
	C601.903076,528.749023 589.725830,538.396484 577.349060,547.780884 
	C570.354919,553.084045 563.050964,557.980957 555.844604,563.000427 
	C554.226807,564.127258 552.433350,565.001709 550.351440,565.718628 
	C549.982117,565.444946 549.987061,564.956787 550.196167,564.848877 
	C550.667114,564.552979 550.863464,564.311646 551.338501,563.967041 
	C563.526611,555.172913 575.370667,546.428894 587.214661,537.684937 
	C587.032837,537.214844 586.851013,536.744812 586.669189,536.274719 
	C585.108643,536.179138 583.548096,536.083557 581.838745,535.668091 
	C581.898193,534.259766 581.731384,532.644226 582.383667,532.180115 
	C584.482178,530.687012 587.186768,529.965332 589.089111,528.297668 
	C591.384460,526.285522 593.098083,523.609863 595.432495,521.158325 
	C600.943665,517.325317 607.265015,519.666016 612.778442,517.819519 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M658.624146,372.002563 
	C657.363159,372.537292 655.772095,373.234772 654.117310,373.455902 
	C651.465637,373.810333 648.771851,373.849213 645.688354,374.561401 
	C644.186340,375.565308 643.091736,376.029236 642.067505,376.176819 
	C641.690918,374.539124 641.610962,372.466797 640.729004,372.035889 
	C637.978333,370.691803 634.924255,369.968750 631.987793,369.004883 
	C631.362427,367.902405 631.014282,366.299377 630.068420,365.775513 
	C621.338196,360.940247 613.196899,354.489990 602.174866,355.772583 
	C600.286072,355.992401 598.209045,354.595276 596.109985,353.604370 
	C604.882324,353.123413 613.415283,354.549896 621.725464,357.923004 
	C633.812195,362.829010 646.076721,367.296875 658.624146,372.002563 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M433.997833,373.023315 
	C430.453766,373.140228 426.909668,373.257141 423.040955,373.115387 
	C427.897034,370.672699 433.012970,368.316162 438.272491,366.342163 
	C447.928558,362.718140 457.544434,358.896301 467.421112,355.984619 
	C473.403900,354.220917 479.845123,354.012085 486.522583,353.185760 
	C484.130157,354.147217 481.319122,355.693848 478.456818,355.795410 
	C464.196899,356.301483 453.461945,364.675568 442.078827,371.615967 
	C441.420410,372.017426 441.321564,373.336731 440.593872,374.564880 
	C438.149567,374.275055 436.073700,373.649200 433.997833,373.023315 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M585.585327,351.993988 
	C579.559875,351.932617 573.202454,351.562408 566.840027,351.424103 
	C554.118958,351.147491 541.382202,350.552612 528.675964,350.919678 
	C515.651367,351.295898 502.655457,352.664764 489.353363,353.336761 
	C499.766815,351.836395 510.502045,350.800690 521.175110,349.317932 
	C542.701172,346.327454 563.954956,349.382721 585.585327,351.993988 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M365.835571,419.235321 
	C372.185577,416.662689 378.230011,414.334656 384.646362,412.401855 
	C383.834625,414.203186 382.883667,415.967499 381.408936,416.925476 
	C379.526886,418.147980 376.939667,418.353821 375.197418,419.698730 
	C374.021362,420.606506 373.518616,422.690735 373.306976,424.325409 
	C373.255402,424.723785 375.494904,425.534149 376.748138,425.955139 
	C378.132080,426.419983 379.593231,426.654877 381.018402,427.239868 
	C381.016052,427.489777 381.016907,427.989594 380.629456,428.000702 
	C379.367584,428.383392 377.825867,428.622253 377.721527,429.146973 
	C376.614838,434.710785 372.651611,433.498322 368.985168,433.021606 
	C368.999298,433.005798 368.962830,433.025146 368.968018,432.622253 
	C367.354401,429.978058 369.989410,425.485229 365.130341,424.958984 
	C365.263550,423.132629 365.396759,421.306274 365.835571,419.235321 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M383.964417,454.631256 
	C381.816010,453.992615 379.667633,453.353943 377.255524,452.860413 
	C374.333313,450.292816 371.674774,447.580139 369.238220,444.233948 
	C369.330200,441.066681 369.200165,438.532928 369.450317,435.997253 
	C371.377167,436.962677 373.036102,437.790436 374.447906,438.925598 
	C377.697418,441.538208 380.826752,444.300262 384.019165,447.237671 
	C384.033447,447.473633 384.077728,447.944366 384.060211,448.410034 
	C384.016602,450.794189 383.990509,452.712738 383.964417,454.631256 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M678.545166,470.152405 
	C677.632263,467.818481 683.547607,465.588684 678.249817,462.550751 
	C682.044617,460.001953 686.014099,457.940094 690.257141,455.806976 
	C691.021729,455.183380 691.512756,454.630981 692.357910,454.040833 
	C694.158630,453.346344 695.605225,452.689697 697.479980,452.021881 
	C699.067383,452.020691 700.226624,452.030670 701.385864,452.040619 
	C693.882385,458.049530 686.378906,464.058472 678.545166,470.152405 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M701.684448,451.975586 
	C700.226624,452.030670 699.067383,452.020691 697.254761,451.766235 
	C695.068909,451.679962 693.536499,451.838135 692.061829,451.657257 
	C695.764343,446.550934 698.618591,440.962372 704.941406,438.977692 
	C704.487854,440.353943 703.622742,441.752869 703.696960,443.100067 
	C703.770630,444.436432 704.790161,445.720673 705.789856,447.081177 
	C704.784241,448.726532 703.383606,450.318512 701.684448,451.975586 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M486.570068,610.039124 
	C478.518219,610.345337 470.097046,610.498291 461.360748,610.387146 
	C469.430664,610.044006 477.815704,609.964905 486.570068,610.039124 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M710.947632,441.598511 
	C709.666626,441.660675 708.502319,441.376465 706.779846,440.955994 
	C709.829163,435.946716 712.494812,431.567810 715.486206,427.129150 
	C714.229492,431.796967 712.646912,436.524536 710.947632,441.598511 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M369.070129,435.999146 
	C369.200165,438.532928 369.330200,441.066681 369.194031,443.891266 
	C367.635986,440.100891 366.344147,436.019714 365.399170,431.564758 
	C366.818329,431.802399 367.890564,432.413757 368.962830,433.025146 
	C368.962830,433.025146 368.999298,433.005798 368.965454,433.391907 
	C368.977753,434.518402 369.023956,435.258759 369.070129,435.999146 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M481.326843,636.262085 
	C484.152710,635.717651 487.374786,635.425232 490.543884,634.843750 
	C492.484436,634.487793 494.343689,633.688416 496.629181,633.047424 
	C492.562042,637.072754 487.041321,636.325073 481.326843,636.262085 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M368.968018,432.622253 
	C367.890564,432.413757 366.818329,431.802399 365.438171,431.139221 
	C365.095245,429.334869 365.060211,427.582275 365.077759,425.394348 
	C369.989410,425.485229 367.354401,429.978058 368.968018,432.622253 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M549.987061,564.956787 
	C549.987061,564.956787 549.982117,565.444946 549.990662,565.688843 
	C548.628052,567.028687 547.256897,568.124634 545.468811,569.136841 
	C545.051880,569.053101 544.897644,568.892517 544.980957,568.555786 
	C546.705139,567.131592 548.346069,566.044189 549.987061,564.956787 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M592.643555,352.824860 
	C591.361633,353.018402 589.778381,353.016052 588.079529,352.709930 
	C589.423340,352.480438 590.882751,352.554718 592.643555,352.824860 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M540.180115,570.939453 
	C539.544128,570.422974 539.301575,570.002563 539.375854,569.324768 
	C541.427673,569.009033 543.162659,568.950745 544.897644,568.892517 
	C544.897644,568.892517 545.051880,569.053101 545.129028,569.133423 
	C543.661987,569.820984 542.117798,570.428284 540.180115,570.939453 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M458.802765,609.983032 
	C458.666992,610.407959 458.370331,610.695679 457.782532,611.099426 
	C457.312500,610.889648 457.133606,610.563904 456.954712,610.238159 
	C457.517090,610.107361 458.079498,609.976562 458.802765,609.983032 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M468.215179,636.754639 
	C468.540436,636.419250 469.142456,636.040833 469.897797,635.896362 
	C469.531403,636.323975 469.011658,636.517761 468.215179,636.754639 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M494.290894,611.602661 
	C493.725128,611.693237 493.005402,611.538696 492.135864,611.138062 
	C492.703003,611.047119 493.419983,611.202332 494.290894,611.602661 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M468.211273,654.578247 
	C468.003723,654.054077 468.026733,653.646667 468.093964,652.936279 
	C468.239410,653.320496 468.340607,654.007751 468.211273,654.578247 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M419.862518,677.540527 
	C418.977356,677.595398 418.371185,677.440857 417.765015,677.286377 
	C419.206512,674.136841 420.607086,670.965759 422.214111,667.903076 
	C422.291962,667.754700 424.692932,668.246094 425.140930,668.974976 
	C426.766449,671.619690 428.020599,674.492676 429.570923,677.591492 
	C426.096741,678.728577 423.194550,671.163696 419.862518,677.540527 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M372.540192,673.830566 
	C372.530579,671.604248 372.530579,669.813110 372.530579,668.125244 
	C379.024902,666.374390 382.841187,667.428467 383.522095,671.195007 
	C384.103943,674.413696 383.430450,677.596008 379.501984,677.646057 
	C376.994812,677.677979 372.275208,680.546387 372.540192,673.830566 
M379.877838,675.667419 
	C379.981537,673.731689 380.567566,671.623352 379.965454,669.940125 
	C379.703125,669.206665 377.003296,669.345154 374.884949,669.002686 
	C375.570740,672.533936 372.718231,677.123779 379.877838,675.667419 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M698.437256,669.057678 
	C695.156860,669.505371 693.102539,671.209717 694.649963,674.040039 
	C695.407227,675.425049 698.280823,675.653015 700.196533,676.404663 
	C700.377136,675.845032 700.557739,675.285461 700.738342,674.725830 
	C699.812866,674.389465 698.887329,674.053162 697.961853,673.716797 
	C697.984497,673.481323 698.007202,673.245789 698.029846,673.010254 
	C699.417175,672.882507 700.804504,672.754761 702.701904,672.580078 
	C702.783752,674.034851 702.976868,676.304138 702.902466,676.312866 
	C699.932373,676.664246 696.691528,677.544434 694.056274,676.658752 
	C692.833374,676.247681 691.798828,672.032166 692.421753,670.104492 
	C693.457520,666.899231 698.002502,666.788879 701.840942,670.075012 
	C700.821228,669.749939 699.801514,669.424866 698.437256,669.057678 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M558.360474,677.139038 
	C557.483765,676.517639 556.413086,675.630981 556.271729,674.614990 
	C555.980103,672.517761 556.183899,670.351746 556.183899,667.402893 
	C558.609558,667.402893 560.905151,667.052063 563.011169,667.532288 
	C564.311279,667.828674 565.322571,669.391296 566.463562,670.385498 
	C565.332397,671.315918 564.292908,672.861694 563.050720,673.046692 
	C560.579529,673.414795 558.421875,673.557800 558.360474,677.139038 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M506.121582,675.624634 
	C507.925049,672.654297 509.611328,670.010559 511.297607,667.366821 
	C513.333252,670.569702 515.368958,673.772583 517.404602,676.975403 
	C517.107056,677.294678 516.809509,677.613892 516.512024,677.933167 
	C515.674866,677.528198 514.619080,677.321411 514.039734,676.682617 
	C511.949005,674.377197 510.115448,674.081848 508.072968,676.756348 
	C506.807220,678.413757 505.745422,678.656128 506.121582,675.624634 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M656.054199,674.749634 
	C654.023438,679.365051 650.027954,678.738525 647.622009,677.052551 
	C644.452942,674.831604 645.634705,670.928406 646.232300,667.690491 
	C646.858398,667.657898 647.484558,667.625305 648.110657,667.592773 
	C648.110657,669.403625 647.744934,671.321228 648.231750,672.990051 
	C648.591309,674.222595 650.062439,675.130859 651.038330,676.183594 
	C652.020020,675.132874 653.458130,674.234497 653.872192,672.994324 
	C654.437134,671.302124 654.221741,669.349304 654.340881,667.508240 
	C654.929749,667.509155 655.518616,667.510071 656.107483,667.510986 
	C656.107483,669.787231 656.107483,672.063477 656.054199,674.749634 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M468.693817,672.100586 
	C468.445740,674.276550 468.237701,676.032837 468.029663,677.789062 
	C467.637848,677.756714 467.246033,677.724365 466.854218,677.692078 
	C466.008789,674.404541 465.163361,671.116943 464.281464,667.687622 
	C465.971771,667.687622 469.030487,667.687622 471.749969,667.687622 
	C470.716248,669.056213 469.725067,670.368591 468.693817,672.100586 
z"/>
<path fill="#E4EDEF" opacity="1.000000" stroke="none" 
	d="
M601.604980,674.747437 
	C601.709656,672.052429 601.817017,669.790527 601.924377,667.528625 
	C602.483887,667.511902 603.043457,667.495117 603.602966,667.478394 
	C603.867798,670.215698 604.132568,672.953064 604.417419,675.897461 
	C606.662109,676.152893 608.706909,676.385620 611.273010,676.677673 
	C605.580627,678.881042 602.658752,678.331543 601.604980,674.747437 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M454.972321,384.291321 
	C455.818237,383.226257 456.613525,381.293915 457.518524,381.241119 
	C462.315399,380.961121 467.539978,379.970062 471.869507,381.434052 
	C480.286682,384.280243 488.836182,383.743713 496.907959,382.519531 
	C514.744629,379.814362 532.640808,379.110748 550.425110,380.460938 
	C557.147949,380.971375 563.794617,380.256775 570.431580,380.993011 
	C576.959839,381.717163 583.532227,382.197205 589.985718,383.344177 
	C591.825745,383.671204 593.319153,385.948730 594.711792,387.929016 
	C593.967834,390.030640 593.483459,391.535034 592.800842,393.378296 
	C591.571533,394.138519 590.563354,394.852875 589.505859,394.935486 
	C579.817810,395.692383 570.102600,396.148376 560.433228,397.083801 
	C553.588806,397.745941 546.802917,399.013428 539.615051,399.843262 
	C536.284424,399.410858 533.331726,399.118713 530.374451,398.885406 
	C513.914856,397.586823 497.454193,396.302582 480.991699,394.608521 
	C480.429688,393.169556 480.056915,391.422089 479.279480,391.218262 
	C474.552582,389.978882 469.733582,389.091034 464.574890,388.027557 
	C461.127106,386.743286 458.049713,385.517303 454.972321,384.291321 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M454.610504,384.218628 
	C458.049713,385.517303 461.127106,386.743286 464.572876,388.406250 
	C460.769653,393.251923 461.774017,398.154053 463.204895,403.335876 
	C464.512482,408.071106 465.181305,412.982697 465.788269,417.677399 
	C463.659027,410.926514 461.458344,404.392120 460.207184,397.680725 
	C459.023376,391.330536 459.461761,391.248810 452.582428,393.003906 
	C450.797760,393.043640 449.425171,393.090179 447.677185,393.094421 
	C446.195007,393.689545 445.088196,394.326904 443.983643,394.983215 
	C443.985931,395.002136 444.014709,395.027161 443.719482,395.023499 
	C442.938232,395.303558 442.452209,395.587341 441.542511,395.887085 
	C439.426605,395.917328 437.734406,395.931580 436.036682,395.544861 
	C434.665588,391.900360 433.373260,388.623199 431.907410,385.425629 
	C431.008484,383.464813 430.022461,381.669769 433.215881,381.022644 
	C436.839142,380.288452 440.466003,379.572235 444.393066,378.864136 
	C445.096436,378.874664 445.497986,378.869293 445.899567,378.863922 
	C448.682617,380.624603 451.465637,382.385254 454.610504,384.218628 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M631.611511,369.012939 
	C634.924255,369.968750 637.978333,370.691803 640.729004,372.035889 
	C641.610962,372.466797 641.690918,374.539124 641.776611,376.401794 
	C636.664673,379.691040 631.914001,382.438965 626.725586,385.130707 
	C617.864929,385.393951 609.442139,385.713348 601.019287,386.032745 
	C601.872620,384.932129 602.494812,383.398895 603.616089,382.799744 
	C612.127686,378.251709 620.732849,373.878387 629.325378,369.482910 
	C629.892334,369.192871 630.595459,369.168762 631.611511,369.012939 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M444.091309,378.848206 
	C440.466003,379.572235 436.839142,380.288452 433.215881,381.022644 
	C430.022461,381.669769 431.008484,383.464813 431.907410,385.425629 
	C433.373260,388.623199 434.665588,391.900360 435.745300,395.693085 
	C434.303284,396.825165 433.147095,397.408051 431.564819,397.991333 
	C423.764679,398.996765 416.390625,400.001770 409.061279,400.691101 
	C409.758606,399.324677 410.411194,398.273926 411.404236,397.146790 
	C415.215576,393.062805 418.686432,389.055206 422.358215,384.934509 
	C422.794220,384.613708 422.975464,384.366547 423.102844,384.079865 
	C428.718628,382.732880 431.218140,378.100159 433.993866,373.388885 
	C436.073700,373.649200 438.149567,374.275055 440.584137,374.937927 
	C440.942841,374.974976 441.416931,375.080048 441.732269,375.403931 
	C442.675049,376.156647 443.302490,376.585449 443.929932,377.014282 
	C443.929932,377.014282 443.946625,377.054840 443.975677,377.359070 
	C444.033600,378.058228 444.062439,378.453217 444.091309,378.848206 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M600.790894,386.308655 
	C609.442139,385.713348 617.864929,385.393951 626.626831,385.449036 
	C625.628723,388.193115 624.291687,390.562714 622.965515,392.947479 
	C622.976440,392.962646 622.975647,392.959900 622.749939,393.066284 
	C622.243958,393.389282 622.087280,393.673950 622.033203,394.350952 
	C622.013428,395.108429 622.014709,395.541565 621.769897,396.167603 
	C621.015503,397.233032 620.507141,398.105591 619.755371,398.747070 
	C619.627319,396.928223 619.742737,395.340424 619.978882,392.092041 
	C617.271912,393.489807 614.925293,393.937897 613.920288,395.344574 
	C607.429932,404.428650 601.232483,413.722015 594.731140,423.065796 
	C594.263062,423.385071 594.071472,423.637329 593.712280,423.952759 
	C593.476013,423.963776 593.004822,424.005341 592.710571,423.956940 
	C592.222778,423.862854 592.029297,423.817139 591.980408,423.516296 
	C592.368652,422.530914 592.612305,421.800690 593.026245,420.756805 
	C599.004639,412.235291 604.878174,404.072357 610.568481,395.783661 
	C611.780029,394.018799 612.324951,391.796234 613.177002,389.784546 
	C612.526917,389.406982 611.876831,389.029388 611.226807,388.651825 
	C609.972290,390.132874 608.855530,391.765198 607.432068,393.060608 
	C605.110596,395.173370 602.588989,397.066254 600.250854,398.714966 
	C601.311707,397.008057 602.273499,395.638062 604.097839,393.039429 
	C599.435913,393.039429 596.217529,393.039429 592.999146,393.039429 
	C593.483459,391.535034 593.967834,390.030640 594.708740,388.313049 
	C596.831055,387.594757 598.696716,387.089661 600.790894,386.308655 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M443.904480,376.691101 
	C443.302490,376.585449 442.675049,376.156647 441.973206,375.433777 
	C442.558899,375.549133 443.218994,375.958557 443.904480,376.691101 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M444.393066,378.864105 
	C444.062439,378.453217 444.033600,378.058228 443.996399,377.366333 
	C444.606323,377.469330 445.224609,377.869324 445.871216,378.566620 
	C445.497986,378.869293 445.096436,378.874664 444.393066,378.864105 
z"/>
<path fill="#020303" opacity="1.000000" stroke="none" 
	d="
M655.411987,619.876831 
	C656.739563,626.562378 655.250977,628.657837 649.027771,628.866516 
	C643.421875,629.054565 637.804626,628.906128 631.927856,628.906128 
	C631.927856,624.879883 631.927856,621.291626 631.927856,617.366455 
	C638.311340,617.366455 644.584229,617.233032 650.843506,617.465088 
	C652.314941,617.519653 653.740601,618.808411 655.411987,619.876831 
z"/>
<path fill="#456CDF" opacity="1.000000" stroke="none" 
	d="
M544.980957,568.555786 
	C543.162659,568.950745 541.427673,569.009033 539.250977,569.109680 
	C535.658875,566.977844 532.508545,564.803650 529.059692,562.130981 
	C528.588501,561.344238 528.337830,561.165100 528.009216,561.094971 
	C528.009216,561.094971 527.936096,561.080566 527.934265,560.690063 
	C526.246948,557.860718 524.561523,555.421814 522.861328,552.672729 
	C522.231995,551.936157 521.617493,551.509827 520.981445,551.062927 
	C520.959961,551.042419 520.916260,551.002136 520.895996,550.694336 
	C520.243591,549.605408 519.611450,548.824219 518.991577,547.624390 
	C519.023865,543.383179 519.043884,539.560669 519.404907,535.748047 
	C520.428040,535.764038 521.110168,535.770020 522.214661,535.895569 
	C527.434021,536.025146 532.231079,536.035217 537.467773,536.035950 
	C540.251587,536.053528 542.595764,536.080444 545.011719,536.449707 
	C546.563171,539.517456 547.826050,542.397888 549.616272,544.901245 
	C550.479797,546.108704 552.336060,546.606201 553.742859,547.425110 
	C554.049133,545.770508 554.737305,544.075928 554.569397,542.470947 
	C554.335938,540.239197 553.460876,538.074585 552.906250,535.939941 
	C552.952087,535.998596 552.882324,535.867493 553.216980,535.864258 
	C554.037048,535.221375 554.522400,534.581604 555.425537,533.932434 
	C557.237427,533.923157 558.631653,533.923401 560.184875,534.247131 
	C566.299988,538.389465 566.617371,540.642151 562.151855,546.453979 
	C561.594116,547.179871 561.435730,548.212524 560.925659,549.409912 
	C557.504089,554.483521 554.249207,559.250305 550.994324,564.017090 
	C550.863464,564.311646 550.667114,564.552979 550.196167,564.848877 
	C548.346069,566.044189 546.705139,567.131592 544.980957,568.555786 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M595.063538,521.221436 
	C593.098083,523.609863 591.384460,526.285522 589.089111,528.297668 
	C587.186768,529.965332 584.482178,530.687012 582.383667,532.180115 
	C581.731384,532.644226 581.898193,534.259766 581.553467,535.814575 
	C579.954224,537.153809 578.491516,538.026672 577.019165,538.489136 
	C577.231689,535.169006 575.379822,535.058350 573.162231,534.952026 
	C570.102295,534.805237 567.059875,534.294434 564.303223,533.682495 
	C567.300171,532.937988 570.003906,532.450623 572.707703,531.963257 
	C566.381775,531.143494 560.376953,531.101990 554.375549,531.224060 
	C553.907593,531.233582 553.462891,532.386719 552.538208,533.002380 
	C536.464111,532.071838 520.859192,531.147217 505.254211,530.222595 
	C505.145752,530.527283 505.037262,530.831970 504.928802,531.136658 
	C506.298157,531.779846 507.667511,532.423035 509.029419,533.429688 
	C509.001373,534.521057 508.980774,535.249084 508.551086,535.968262 
	C505.098572,535.624451 502.055145,535.289429 499.011749,534.954407 
	C496.264587,533.125000 493.315216,531.525940 490.856750,529.367676 
	C489.652832,528.310791 489.361328,526.214722 489.189209,524.275635 
	C490.817902,523.922302 491.914276,523.882202 493.368744,523.878906 
	C494.808289,523.601013 495.889740,523.286499 497.344604,523.008301 
	C501.632721,524.031433 505.498352,525.472229 509.473328,525.902527 
	C516.018921,526.611084 522.637207,526.631958 529.221130,527.007629 
	C533.823853,527.270264 538.456360,528.164490 543.012024,527.851624 
	C551.698669,527.255005 560.339905,525.997253 569.426453,525.034546 
	C571.558167,524.993958 573.263367,524.932983 575.316956,524.887329 
	C578.106018,522.889343 580.546692,520.876099 583.372986,518.947815 
	C587.526855,519.762390 591.295227,520.491943 595.063538,521.221436 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M493.010681,523.842163 
	C491.914276,523.882202 490.817902,523.922302 489.305176,523.958984 
	C484.353882,523.466248 480.549164,521.828064 476.856140,518.817322 
	C468.972778,512.390381 460.532288,506.646820 452.196747,500.009399 
	C453.772522,495.373138 456.221191,493.595490 461.004059,494.845001 
	C465.844513,496.109528 470.968658,496.288147 476.328491,496.966980 
	C478.122375,497.673615 479.556305,498.355652 480.991760,499.417938 
	C482.376923,506.093292 487.993958,508.874817 492.015137,513.367676 
	C493.668579,516.503113 495.274231,519.291443 496.531189,522.087646 
	C495.125244,522.677795 494.067963,523.260010 493.010681,523.842163 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M475.968536,496.942413 
	C470.968658,496.288147 465.844513,496.109528 461.004059,494.845001 
	C456.221191,493.595490 453.772522,495.373138 451.980957,499.752319 
	C438.711761,493.783203 427.734344,484.440247 417.314392,474.213928 
	C419.143646,473.744293 420.904510,473.298553 422.220459,473.872711 
	C428.066284,476.423065 433.732910,479.383667 439.574463,481.944641 
	C441.914062,482.970306 444.559784,483.276428 447.025574,484.041016 
	C449.839844,484.913635 452.566101,486.091614 455.406616,486.853638 
	C460.889374,488.324524 466.425598,489.596375 472.268005,491.103821 
	C473.720459,493.152100 474.844482,495.047241 475.968536,496.942413 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M471.939636,490.950745 
	C466.425598,489.596375 460.889374,488.324524 455.406616,486.853638 
	C452.566101,486.091614 449.839844,484.913635 447.025574,484.041016 
	C444.559784,483.276428 441.914062,482.970306 439.574463,481.944641 
	C433.732910,479.383667 428.066284,476.423065 422.220459,473.872711 
	C420.904510,473.298553 419.143646,473.744293 417.244141,473.880859 
	C415.219604,472.425751 413.538483,470.810364 412.291321,468.702698 
	C416.797089,468.516113 420.868927,468.821838 425.215057,469.374512 
	C427.997894,470.106567 430.506378,470.591705 433.301392,471.319580 
	C442.040344,473.654999 450.492767,475.747711 459.267303,478.024506 
	C461.375183,478.772125 463.160950,479.335693 464.987610,480.252197 
	C467.332245,484.053680 469.635925,487.502197 471.939636,490.950745 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M424.940735,469.127563 
	C420.868927,468.821838 416.797089,468.516113 412.314270,468.356628 
	C407.257568,466.464386 402.531616,464.588013 397.990295,462.338898 
	C393.994598,460.359985 390.205414,457.964081 386.172363,455.153687 
	C387.048004,454.210388 388.090302,453.901825 389.101318,453.512238 
	C390.417755,453.004944 391.709442,452.433258 393.451599,451.918335 
	C395.874176,451.882294 398.842072,452.626465 399.672882,451.615112 
	C403.738342,446.665894 406.272308,449.878296 409.266876,452.661469 
	C410.294983,453.617065 411.941895,453.870178 413.200287,454.621429 
	C417.383881,457.119080 421.587524,459.598114 425.596832,462.355713 
	C426.171600,462.750946 425.834137,465.675873 425.724976,465.687988 
	C421.957153,466.107269 425.772156,468.168427 424.940735,469.127563 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M522.876038,552.982910 
	C524.561523,555.421814 526.246948,557.860718 527.912720,560.659729 
	C521.707031,556.963257 515.506409,552.929016 509.341553,548.840698 
	C506.304077,546.826416 503.295929,544.763062 500.347290,542.621704 
	C498.886719,541.561096 497.015686,540.376892 500.149231,538.244507 
	C503.288940,539.641479 505.873047,541.266174 508.438721,542.919556 
	C512.604309,545.603882 516.757690,548.306946 520.916260,551.002136 
	C520.916260,551.002136 520.959961,551.042419 521.032227,551.357910 
	C521.695007,552.109863 522.285522,552.546387 522.876038,552.982910 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M520.895996,550.694336 
	C516.757690,548.306946 512.604309,545.603882 508.438721,542.919556 
	C505.873047,541.266174 503.288940,539.641479 500.343262,537.990967 
	C499.717041,537.174683 499.460663,536.371338 499.108032,535.261230 
	C502.055145,535.289429 505.098572,535.624451 508.715210,536.274536 
	C512.518738,540.407532 515.749023,544.225281 518.979309,548.043091 
	C519.611450,548.824219 520.243591,549.605408 520.895996,550.694336 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M393.011780,451.889313 
	C391.709442,452.433258 390.417755,453.004944 389.101318,453.512238 
	C388.090302,453.901825 387.048004,454.210388 385.924622,454.927368 
	C385.336365,455.182892 384.843140,455.066101 384.157196,454.790283 
	C383.990509,452.712738 384.016602,450.794189 384.429688,448.475952 
	C387.548370,449.347290 390.280090,450.618317 393.011780,451.889313 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M528.104309,561.302612 
	C528.337830,561.165100 528.588501,561.344238 528.831055,561.859619 
	C528.620361,561.951355 528.386536,561.759155 528.104309,561.302612 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M433.656769,631.604187 
	C434.421082,633.439026 434.983307,634.919556 435.719208,636.857361 
	C430.886230,636.857361 426.683746,636.857361 421.637848,636.857361 
	C423.916077,631.819763 426.028809,627.148132 428.715332,621.207642 
	C430.624542,625.253052 432.039612,628.251465 433.656769,631.604187 
z"/>
<path fill="#020303" opacity="1.000000" stroke="none" 
	d="
M525.904663,617.176880 
	C528.704163,617.272339 531.040894,617.231689 533.342896,617.495178 
	C536.699097,617.879211 538.211182,619.980408 538.193909,623.295227 
	C538.177368,626.469910 536.489868,628.498901 533.472351,628.623352 
	C527.388855,628.874390 521.287720,628.698059 514.923340,628.698059 
	C514.923340,624.838196 514.923340,621.271545 514.923340,617.176636 
	C518.548706,617.176636 521.987549,617.176636 525.904663,617.176880 
z"/>
<path fill="#020303" opacity="1.000000" stroke="none" 
	d="
M467.883087,621.131470 
	C468.782715,619.485718 469.632294,617.345947 470.597137,617.292664 
	C475.569427,617.017944 480.574829,617.186707 485.561523,617.382202 
	C489.248352,617.526733 491.560059,619.423889 491.465240,623.322815 
	C491.370148,627.234619 488.985718,628.888245 485.264954,628.895020 
	C479.652222,628.905212 474.039459,628.897705 467.886230,628.897705 
	C467.886230,626.159790 467.886230,623.879822 467.883087,621.131470 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M622.954590,392.932312 
	C624.291687,390.562714 625.628723,388.193115 627.064575,385.505188 
	C631.914001,382.438965 636.664673,379.691040 641.706238,376.718140 
	C643.091736,376.029236 644.186340,375.565308 645.550415,374.928467 
	C650.690369,380.078278 655.560913,385.401001 660.773560,391.207489 
	C662.801941,394.422211 664.488281,397.153198 665.740662,399.873779 
	C657.686462,398.880219 650.037659,398.071960 642.459473,396.832245 
	C639.195801,396.298340 637.736938,396.260498 638.398315,400.342438 
	C638.604980,401.617432 636.223755,404.555115 634.990295,404.576233 
	C631.868652,404.629761 628.726013,403.460144 625.591797,402.780457 
	C626.573120,401.070984 627.541626,399.353912 628.542358,397.655853 
	C628.932983,396.992889 629.395874,396.372559 630.404358,394.872284 
	C627.523560,394.122101 625.239075,393.527222 622.954590,392.932312 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M666.174683,399.884186 
	C664.488281,397.153198 662.801941,394.422211 661.077759,391.347992 
	C661.406250,390.456299 661.723022,389.865601 662.147827,389.367004 
	C663.655029,387.598022 665.199219,385.860504 666.729431,384.111084 
	C668.705200,385.952820 671.817078,387.479584 672.432312,389.698547 
	C673.947632,395.164215 678.126038,396.813690 682.343323,399.170074 
	C684.767395,400.524506 689.699219,399.957275 688.717285,405.114594 
	C688.608765,405.684387 687.960815,406.151459 687.250793,406.858063 
	C682.564453,406.010254 678.190308,404.970520 673.262085,403.690155 
	C672.182739,403.271942 671.657593,403.094421 671.057861,402.604614 
	C670.312866,401.888184 669.642334,401.483978 668.670227,401.016357 
	C667.637268,400.596710 666.905945,400.240448 666.174683,399.884186 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M381.020752,426.989990 
	C379.593231,426.654877 378.132080,426.419983 376.748138,425.955139 
	C375.494904,425.534149 373.255402,424.723785 373.306976,424.325409 
	C373.518616,422.690735 374.021362,420.606506 375.197418,419.698730 
	C376.939667,418.353821 379.526886,418.147980 381.408936,416.925476 
	C382.883667,415.967499 383.834625,414.203186 385.024384,412.393921 
	C388.002289,410.238403 390.974060,408.486023 394.415833,406.362885 
	C397.590698,405.664978 400.295654,405.337830 403.002289,405.395874 
	C402.653961,407.513885 402.303955,409.246704 401.616516,411.023926 
	C399.186066,413.041687 397.093048,415.015045 394.616455,416.991730 
	C389.490753,417.824860 385.473175,421.741241 384.650269,426.038147 
	C383.212189,426.379120 382.116455,426.684540 381.020752,426.989990 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M403.000610,405.010712 
	C400.295654,405.337830 397.590698,405.664978 394.475647,406.000549 
	C391.505920,403.350616 391.800446,401.181122 395.167328,399.526154 
	C398.250824,398.010529 401.403198,396.614380 404.383118,394.916290 
	C406.174042,393.895721 407.693024,392.397888 409.681946,391.460815 
	C410.374390,393.612427 410.719086,395.417816 411.063782,397.223175 
	C410.411194,398.273926 409.758606,399.324677 408.842834,400.932861 
	C406.719971,402.663727 404.860291,403.837219 403.000610,405.010712 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M411.404236,397.146790 
	C410.719086,395.417816 410.374390,393.612427 410.027466,391.409393 
	C410.922272,390.192261 411.819336,389.372803 413.171936,388.138641 
	C416.085663,385.604492 418.225250,382.469666 422.157288,385.047607 
	C418.686432,389.055206 415.215576,393.062805 411.404236,397.146790 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M422.358215,384.934509 
	C418.225250,382.469666 416.085663,385.604492 413.317810,387.868652 
	C413.099304,380.232727 415.142303,379.268677 422.894714,383.763092 
	C422.975464,384.366547 422.794220,384.613708 422.358215,384.934509 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M704.962158,438.563477 
	C698.618591,440.962372 695.764343,446.550934 692.059814,451.659088 
	C692.000000,452.000000 691.995972,452.004303 691.647949,452.072906 
	C690.861145,453.387085 690.422302,454.632629 689.983521,455.878204 
	C686.014099,457.940094 682.044617,460.001953 677.943848,462.362793 
	C673.875488,464.539642 669.938599,466.417511 665.180969,468.235016 
	C659.909485,468.461670 655.458740,468.748718 650.984863,468.658417 
	C649.933533,467.476135 649.021057,466.381653 647.856995,465.915894 
	C642.579590,463.804230 641.342468,460.734772 643.687500,455.512756 
	C644.628601,453.417145 645.242188,451.174500 646.405518,448.999298 
	C648.379150,449.361267 650.324402,449.255585 651.462341,450.162689 
	C657.305969,454.820923 663.381348,456.074799 670.501465,453.163269 
	C674.914307,451.358765 675.467834,450.046326 674.106323,445.394135 
	C673.013977,441.661743 673.190247,437.558105 672.803528,433.619202 
	C673.619385,433.367065 674.435303,433.114960 675.251160,432.862823 
	C677.164673,436.794037 679.129639,440.701477 680.961548,444.670380 
	C681.541321,445.926666 681.570923,447.468872 682.284607,448.609772 
	C682.471252,448.908112 684.858215,448.521851 685.224792,447.876434 
	C686.070862,446.386841 687.250000,443.860291 686.607788,442.967957 
	C684.088440,439.467438 685.660156,437.657990 688.471436,435.754669 
	C690.262573,434.542023 691.860046,433.043243 693.547791,431.669983 
	C688.948608,425.479645 688.948608,425.479645 692.989990,424.006012 
	C697.516968,428.271576 704.231445,430.682220 704.962158,438.563477 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M661.143799,472.090942 
	C656.444031,476.590118 651.774048,481.121002 647.034058,485.577423 
	C643.812378,488.606262 640.492615,491.530762 636.687134,494.835815 
	C633.772217,495.771698 631.386841,496.373688 628.992310,496.591156 
	C628.321777,491.385223 624.708923,491.606812 621.419373,492.193939 
	C613.578186,493.593506 605.798157,495.335602 598.042175,496.601440 
	C600.077698,493.843597 602.064514,491.423645 604.464172,488.999451 
	C612.729797,487.767548 620.425476,486.059296 628.603333,481.697205 
	C620.014526,479.775055 613.586853,482.772034 607.028076,484.605469 
	C607.031433,482.856415 607.040833,481.511078 607.438110,480.111572 
	C610.892151,479.064484 613.958313,478.071503 617.416626,477.064209 
	C620.210571,476.357422 622.612305,475.664978 625.330444,474.959717 
	C626.066101,474.909637 626.485352,474.872375 627.293457,474.850372 
	C634.777222,473.297211 641.872253,471.728821 648.952759,470.536469 
	C648.666809,471.482483 648.517822,472.429443 648.104675,472.563782 
	C644.253235,473.815826 640.300293,474.772766 636.496094,476.145752 
	C634.545654,476.849731 632.837524,478.224915 631.019897,479.296783 
	C636.546875,481.965820 640.628601,479.668640 644.999939,477.757050 
	C650.210693,475.478394 655.747986,473.946411 661.143799,472.090942 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M629.001526,496.975647 
	C631.386841,496.373688 633.772217,495.771698 636.516113,495.135254 
	C636.971313,495.566193 637.306335,496.303345 637.130249,496.457825 
	C629.178040,503.433533 621.180969,510.358185 612.983826,517.554199 
	C607.265015,519.666016 600.943665,517.325317 595.432495,521.158325 
	C591.295227,520.491943 587.526855,519.762390 583.423950,518.614075 
	C584.392944,516.129395 585.696472,514.063477 587.309998,511.839355 
	C591.980164,509.674438 596.219360,507.319336 600.732178,505.752472 
	C605.930115,503.947723 611.439148,501.518890 616.685425,501.794037 
	C622.106140,502.078278 625.812683,500.973328 629.001526,496.975647 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M661.478271,472.030273 
	C655.747986,473.946411 650.210693,475.478394 644.999939,477.757050 
	C640.628601,479.668640 636.546875,481.965820 631.019897,479.296783 
	C632.837524,478.224915 634.545654,476.849731 636.496094,476.145752 
	C640.300293,474.772766 644.253235,473.815826 648.104675,472.563782 
	C648.517822,472.429443 648.666809,471.482483 649.122681,470.276428 
	C649.873962,469.438782 650.440979,469.237274 651.007935,469.035767 
	C655.458740,468.748718 659.909485,468.461670 664.824829,468.327515 
	C664.130554,469.643463 662.971680,470.806549 661.478271,472.030273 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M692.848022,423.709839 
	C688.948608,425.479645 688.948608,425.479645 693.547791,431.669983 
	C691.860046,433.043243 690.262573,434.542023 688.471436,435.754669 
	C685.660156,437.657990 684.088440,439.467438 686.607788,442.967957 
	C687.250000,443.860291 686.070862,446.386841 685.224792,447.876434 
	C684.858215,448.521851 682.471252,448.908112 682.284607,448.609772 
	C681.570923,447.468872 681.541321,445.926666 680.961548,444.670380 
	C679.129639,440.701477 677.164673,436.794037 675.251160,432.862823 
	C674.435303,433.114960 673.619385,433.367065 672.803528,433.619202 
	C673.190247,437.558105 673.013977,441.661743 674.106323,445.394135 
	C675.467834,450.046326 674.914307,451.358765 670.501465,453.163269 
	C663.381348,456.074799 657.305969,454.820923 651.462341,450.162689 
	C650.324402,449.255585 648.379150,449.361267 646.364868,448.646545 
	C643.649292,444.661896 644.262146,442.390137 648.299194,440.091888 
	C651.648132,438.185455 654.116089,434.731537 656.972900,431.533203 
	C656.963684,429.091370 656.955872,427.079407 656.969482,424.647125 
	C655.971802,420.099213 655.578979,415.667114 653.761047,411.927887 
	C651.864563,408.027191 647.962463,405.153015 643.379456,406.272308 
	C641.319519,406.775391 639.917725,409.973053 638.217285,411.948059 
	C640.022827,412.388062 641.975525,413.492889 643.603882,413.132416 
	C647.213684,412.333282 647.584839,415.225677 648.996704,417.364258 
	C648.638062,418.807312 648.289551,419.887177 647.593384,421.020630 
	C646.494202,422.376678 645.742554,423.679077 644.951416,425.312195 
	C644.261902,426.754852 643.611816,427.866852 642.623718,429.045654 
	C641.862061,430.082092 641.438416,431.051788 640.991455,432.254639 
	C640.968140,432.487823 640.897095,432.951080 640.528320,432.976257 
	C635.902832,436.772278 634.550415,441.593964 635.047241,447.400360 
	C634.993347,448.122925 634.954224,448.535187 634.602051,449.015076 
	C633.881958,449.754761 633.474915,450.426849 633.052856,451.319244 
	C633.037720,451.539520 632.982178,451.977539 632.660889,452.035065 
	C631.918945,452.765411 631.498352,453.438202 631.065613,454.329803 
	C631.053528,454.548615 631.003784,454.984131 630.659790,455.025940 
	C627.234619,457.743988 622.135132,459.134399 623.579834,464.979126 
	C621.435913,464.986176 619.720947,464.981537 618.166016,464.618652 
	C619.880554,457.391449 615.259094,448.852264 607.009521,443.683960 
	C608.647583,439.141235 610.309082,434.533356 611.989868,429.568726 
	C612.394897,427.833374 612.780762,426.454803 613.543823,425.037964 
	C614.920105,421.619720 615.919006,418.239655 616.917969,414.478333 
	C616.999084,413.084198 617.080139,412.071320 617.432190,411.002808 
	C617.813232,410.292328 617.923340,409.637482 618.045410,408.613525 
	C618.313782,406.806793 618.570190,405.369110 618.906982,403.949829 
	C618.987366,403.968231 619.126892,404.056091 619.462524,403.975067 
	C620.537476,401.254272 621.276733,398.614502 622.015991,395.974731 
	C622.014709,395.541565 622.013428,395.108429 622.240723,394.225220 
	C622.727844,393.559174 622.896606,393.287445 622.975647,392.959900 
	C622.975647,392.959900 622.976440,392.962646 622.965515,392.947479 
	C625.239075,393.527222 627.523560,394.122101 630.404358,394.872284 
	C629.395874,396.372559 628.932983,396.992889 628.542358,397.655853 
	C627.541626,399.353912 626.573120,401.070984 625.591797,402.780457 
	C628.726013,403.460144 631.868652,404.629761 634.990295,404.576233 
	C636.223755,404.555115 638.604980,401.617432 638.398315,400.342438 
	C637.736938,396.260498 639.195801,396.298340 642.459473,396.832245 
	C650.037659,398.071960 657.686462,398.880219 665.740601,399.873779 
	C666.905945,400.240448 667.637268,400.596710 668.732849,401.331970 
	C669.775574,402.112946 670.453979,402.514893 671.132446,402.916870 
	C671.657593,403.094421 672.182739,403.271942 672.880493,403.671692 
	C675.231262,405.970551 677.465698,407.992157 679.570068,410.141205 
	C681.285278,411.892853 682.844910,413.796906 684.885742,416.173279 
	C686.942261,421.107330 683.847961,421.177612 681.123291,420.925720 
	C677.366455,420.578491 674.480042,421.916656 672.810486,425.049164 
	C672.113708,426.356598 672.788513,428.394989 672.839722,430.101013 
	C674.183167,429.893188 675.560669,429.806519 676.859802,429.441071 
	C677.993896,429.122040 679.039673,428.488922 680.427124,427.856628 
	C680.807556,429.644989 680.791626,430.918610 681.365723,431.813690 
	C681.776184,432.453552 683.019043,432.935608 683.814941,432.842377 
	C684.556763,432.755524 685.880066,431.510559 685.784119,431.296539 
	C683.794312,426.855133 688.102661,424.459045 689.009766,421.001221 
	C690.241821,421.805389 691.473938,422.609558 692.848022,423.709839 
M621.771851,436.865112 
	C624.581787,437.715485 627.391663,438.565887 631.410522,439.782166 
	C628.929932,431.916748 628.595459,424.911865 621.896606,421.186279 
	C615.063171,426.507233 614.888977,431.094940 621.771851,436.865112 
M663.656311,418.938446 
	C667.392456,421.557220 669.108276,417.172791 669.363586,415.969116 
	C669.904968,413.417358 669.017578,410.374207 668.004456,407.814087 
	C667.753174,407.179108 664.289856,406.954010 663.211060,407.758362 
	C662.203125,408.509857 662.086304,410.890503 662.149719,412.532013 
	C662.224365,414.465302 662.914795,416.374817 663.656311,418.938446 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M689.005432,420.575958 
	C688.102661,424.459045 683.794312,426.855133 685.784119,431.296539 
	C685.880066,431.510559 684.556763,432.755524 683.814941,432.842377 
	C683.019043,432.935608 681.776184,432.453552 681.365723,431.813690 
	C680.791626,430.918610 680.807556,429.644989 680.427124,427.856628 
	C679.039673,428.488922 677.993896,429.122040 676.859802,429.441071 
	C675.560669,429.806519 674.183167,429.893188 672.839722,430.101013 
	C672.788513,428.394989 672.113708,426.356598 672.810486,425.049164 
	C674.480042,421.916656 677.366455,420.578491 681.123291,420.925720 
	C683.847961,421.177612 686.942261,421.107330 685.145264,416.382446 
	C685.939087,415.359131 686.885620,414.666473 689.001099,413.118408 
	C689.001099,416.358246 689.001099,418.254486 689.005432,420.575958 
z"/>
<path fill="#4976C3" opacity="1.000000" stroke="none" 
	d="
M577.028748,538.899536 
	C578.491516,538.026672 579.954224,537.153809 581.702271,536.134521 
	C583.548096,536.083557 585.108643,536.179138 586.669189,536.274719 
	C586.851013,536.744812 587.032837,537.214844 587.214661,537.684937 
	C575.370667,546.428894 563.526611,555.172913 551.338501,563.967041 
	C554.249207,559.250305 557.504089,554.483521 561.278198,549.365601 
	C563.987305,547.382568 566.107666,545.644958 568.384949,544.145630 
	C571.198120,542.293457 574.141602,540.639282 577.028748,538.899536 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M381.018402,427.239868 
	C382.116455,426.684540 383.212189,426.379120 384.961761,426.196350 
	C388.276642,427.544067 390.835236,429.391968 393.622345,429.850372 
	C397.962494,430.564209 399.668762,427.794342 397.912109,423.795013 
	C396.919708,421.535645 395.969330,419.257874 395.000000,416.988373 
	C397.093048,415.015045 399.186066,413.041687 401.619568,411.447266 
	C401.704742,414.388367 401.449432,416.950592 401.124420,419.837219 
	C401.452423,422.568848 401.818359,424.983582 402.378601,427.352295 
	C402.398438,427.436279 405.225098,427.158844 405.253754,426.890656 
	C405.491974,424.661560 405.395386,422.394684 405.353973,420.140076 
	C405.348450,419.838928 405.085846,419.542542 405.113098,418.981873 
	C405.585480,418.638123 405.985504,418.388489 406.174164,418.499176 
	C411.175232,421.433502 418.987823,419.147949 421.372131,413.731598 
	C421.886902,412.562195 421.444397,410.359009 420.591522,409.355774 
	C419.537201,408.115631 417.453186,407.812988 416.086548,406.753723 
	C415.223907,406.085144 414.452911,404.779968 414.436920,403.748016 
	C414.428497,403.201935 416.358978,401.958588 417.005646,402.186920 
	C419.589569,403.099243 422.716766,403.875092 424.332611,405.800873 
	C426.800903,408.742676 428.086700,412.652924 430.094696,416.020752 
	C430.620972,416.903381 431.959167,417.301910 432.846802,418.191864 
	C432.511932,418.956970 432.254700,419.452942 431.694672,420.127716 
	C430.073792,422.926636 428.949005,425.669067 427.388397,428.135742 
	C425.464752,431.176239 426.087189,433.748352 428.917206,435.244843 
	C433.067078,437.439270 435.343445,444.093201 441.787018,440.964386 
	C442.396149,440.668579 442.669800,439.681793 443.478149,438.958557 
	C447.291138,437.978088 450.787598,437.229431 454.125336,436.036713 
	C455.289703,435.620636 456.062500,434.108826 457.399414,433.073212 
	C460.100433,431.680725 462.416077,430.312469 464.731720,428.944214 
	C463.489563,427.611237 462.247375,426.278290 460.960815,424.598450 
	C455.839417,418.879822 450.836334,413.435211 445.644745,408.176636 
	C443.872803,406.381897 442.578613,407.131897 440.926331,409.197174 
	C438.786316,411.872009 435.686951,413.779297 432.994507,415.589172 
	C433.022491,408.385864 436.790314,405.193054 445.328979,404.994202 
	C446.101562,405.014374 446.543030,405.024902 447.054626,405.372681 
	C448.825531,406.185577 451.247375,407.568665 452.058289,406.924286 
	C453.276093,405.956635 453.800873,403.576050 453.834320,401.781525 
	C453.888733,398.864166 453.307495,395.934906 452.994537,393.010681 
	C459.461761,391.248810 459.023376,391.330536 460.207184,397.680725 
	C461.458344,404.392120 463.659027,410.926514 465.969116,417.915588 
	C466.635040,418.862976 466.776764,419.432922 466.718658,420.182007 
	C466.954651,420.944946 467.390503,421.528839 467.864655,422.472168 
	C467.941711,423.553497 467.980469,424.275391 467.776855,425.205139 
	C469.351532,428.941803 471.168610,432.470551 472.992249,436.000366 
	C472.998779,436.001404 472.998352,436.015167 472.985718,436.350525 
	C472.984100,437.133881 472.995117,437.581940 473.003967,438.451355 
	C471.597443,443.507751 470.743317,448.414429 468.628204,452.698578 
	C466.657928,456.689362 464.088379,460.246460 464.620514,464.999481 
	C463.138458,465.029388 462.060669,465.053284 460.541016,465.057373 
	C458.030914,464.986938 455.962677,464.936340 453.921875,464.513000 
	C454.520874,461.020721 455.215363,457.975586 451.140320,456.377289 
	C450.150543,455.989075 449.591156,454.379333 448.943298,453.267487 
	C447.129333,450.154541 445.372589,447.008240 443.441193,443.607330 
	C437.834412,449.795502 438.029144,451.786224 442.777466,456.405090 
	C445.271759,458.831421 447.041687,462.002411 448.750702,464.876282 
	C446.069489,463.543396 443.643829,462.348480 441.515594,460.756073 
	C438.635010,458.600830 436.078125,456.008636 433.166412,453.902466 
	C432.423920,453.365387 430.866913,453.954315 429.684143,454.025879 
	C430.003876,455.283264 429.946747,456.892761 430.722992,457.723572 
	C432.259644,459.368164 434.256775,460.582550 435.607086,461.623077 
	C434.630737,465.183746 433.822815,468.130280 433.014893,471.076843 
	C430.506378,470.591705 427.997894,470.106567 425.215057,469.374512 
	C425.772156,468.168427 421.957153,466.107269 425.724976,465.687988 
	C425.834137,465.675873 426.171600,462.750946 425.596832,462.355713 
	C421.587524,459.598114 417.383881,457.119080 413.200287,454.621429 
	C411.941895,453.870178 410.294983,453.617065 409.266876,452.661469 
	C406.272308,449.878296 403.738342,446.665894 399.672882,451.615112 
	C398.842072,452.626465 395.874176,451.882294 393.451599,451.918335 
	C390.280090,450.618317 387.548370,449.347290 384.447205,448.010315 
	C384.077728,447.944366 384.033447,447.473633 384.081665,446.902069 
	C385.659302,445.878723 387.188751,445.426941 388.950317,444.906586 
	C388.333557,443.198181 387.716797,441.489868 386.654572,438.547577 
	C389.599457,439.452576 391.346985,440.241760 393.164703,440.468567 
	C394.623840,440.650665 396.168060,440.151062 397.674530,439.953979 
	C396.934143,438.702698 396.412231,437.232269 395.415131,436.238586 
	C391.375580,432.212891 384.302979,433.409760 381.016907,427.989594 
	C381.016907,427.989594 381.016052,427.489777 381.018402,427.239868 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M380.629456,428.000702 
	C384.302979,433.409760 391.375580,432.212891 395.415131,436.238586 
	C396.412231,437.232269 396.934143,438.702698 397.674530,439.953979 
	C396.168060,440.151062 394.623840,440.650665 393.164703,440.468567 
	C391.346985,440.241760 389.599457,439.452576 386.654572,438.547577 
	C387.716797,441.489868 388.333557,443.198181 388.950317,444.906586 
	C387.188751,445.426941 385.659302,445.878723 384.067383,446.666077 
	C380.826752,444.300262 377.697418,441.538208 374.447906,438.925598 
	C373.036102,437.790436 371.377167,436.962677 369.450317,435.997253 
	C369.023956,435.258759 368.977753,434.518402 368.951294,433.407715 
	C372.651611,433.498322 376.614838,434.710785 377.721527,429.146973 
	C377.825867,428.622253 379.367584,428.383392 380.629456,428.000702 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M692.002014,451.998169 
	C693.536499,451.838135 695.068909,451.679962 696.826538,451.777405 
	C695.605225,452.689697 694.158630,453.346344 692.354919,453.694214 
	C691.997192,452.925049 691.996582,452.464691 691.995972,452.004303 
	C691.995972,452.004303 692.000000,452.000000 692.002014,451.998169 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M691.647949,452.072906 
	C691.996582,452.464691 691.997192,452.925049 692.000732,453.732025 
	C691.512756,454.630981 691.021729,455.183380 690.257141,455.806976 
	C690.422302,454.632629 690.861145,453.387085 691.647949,452.072906 
z"/>
<path fill="#0780C9" opacity="1.000000" stroke="none" 
	d="
M379.490601,675.870483 
	C372.718231,677.123779 375.570740,672.533936 374.884949,669.002686 
	C377.003296,669.345154 379.703125,669.206665 379.965454,669.940125 
	C380.567566,671.623352 379.981537,673.731689 379.490601,675.870483 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M480.993896,395.013428 
	C497.454193,396.302582 513.914856,397.586823 530.374451,398.885406 
	C533.331726,399.118713 536.284424,399.410858 539.845398,400.105347 
	C551.978088,405.371979 563.504517,410.209106 574.958496,415.384155 
	C571.042114,418.488159 566.101868,420.449585 566.262695,426.032196 
	C568.566833,426.712463 570.127441,427.173218 571.688049,427.633972 
	C571.028137,429.027374 570.293457,430.391022 569.725098,431.820801 
	C568.775757,434.209167 567.932739,436.639862 567.023682,439.033386 
	C567.002441,439.014069 567.043823,439.044067 566.666626,439.029785 
	C563.771362,440.431427 561.230347,441.808441 558.741455,443.273804 
	C556.029297,444.870667 552.198730,456.132172 553.420959,459.051697 
	C553.617004,459.520081 554.479065,459.709656 554.867493,460.325195 
	C551.523804,463.892883 548.345886,467.163513 544.712036,470.579651 
	C539.178772,470.657135 534.101440,470.589111 529.027649,470.130249 
	C528.949524,468.416351 529.059082,467.047729 528.756470,465.777344 
	C525.814331,453.427246 517.979370,443.833893 510.490417,434.128448 
	C506.861877,429.425934 502.340698,425.421173 498.504120,420.864349 
	C497.447205,419.609039 497.299347,417.525940 496.915436,415.782776 
	C496.468842,413.754974 496.961304,410.826691 495.754669,409.784363 
	C491.363892,405.991516 486.433716,402.823090 480.993896,398.905762 
	C480.993896,398.317139 480.993896,396.665283 480.993896,395.013428 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M480.991699,394.608521 
	C480.993896,396.665283 480.993896,398.317139 480.993896,398.905762 
	C486.433716,402.823090 491.363892,405.991516 495.754669,409.784363 
	C496.961304,410.826691 496.468842,413.754974 496.915436,415.782776 
	C497.299347,417.525940 497.447205,419.609039 498.504120,420.864349 
	C502.340698,425.421173 506.861877,429.425934 510.490417,434.128448 
	C517.979370,443.833893 525.814331,453.427246 528.756470,465.777344 
	C529.059082,467.047729 528.949524,468.416351 528.769592,470.257385 
	C528.327942,470.840393 528.147888,470.905396 527.504395,470.939087 
	C521.460938,471.167236 515.880981,471.426666 510.179932,471.335022 
	C508.371582,466.324768 506.684296,461.665619 505.349060,457.040985 
	C507.488373,459.831879 509.181549,462.656189 511.102081,465.316376 
	C512.082153,466.673920 513.338501,468.521332 514.704468,468.750763 
	C516.794189,469.101807 519.122803,468.363403 521.238403,467.720398 
	C521.453186,467.655151 521.431396,464.536255 520.626038,463.752991 
	C514.641418,457.932617 508.556244,452.198059 502.214172,446.773865 
	C500.343018,445.173492 497.991882,445.494720 497.691345,448.842834 
	C496.278503,447.520508 495.145172,446.348328 494.084351,445.113831 
	C489.739777,440.057770 485.417389,434.982697 481.011475,429.592102 
	C480.003571,429.037262 479.070984,428.804962 478.138428,428.572693 
	C478.060364,429.387024 477.982239,430.201355 477.880981,431.301514 
	C477.568695,432.056976 477.279541,432.526642 476.695099,433.137024 
	C475.265961,434.190216 474.132141,435.102692 472.998352,436.015137 
	C472.998352,436.015167 472.998779,436.001404 473.007782,435.596558 
	C471.350922,431.793579 469.685089,428.395416 468.019226,424.997253 
	C467.980469,424.275391 467.941711,423.553497 467.898499,422.158203 
	C467.568848,420.990814 467.243683,420.496857 466.918518,420.002899 
	C466.776764,419.432922 466.635040,418.862976 466.312439,418.054810 
	C465.181305,412.982697 464.512482,408.071106 463.204895,403.335876 
	C461.774017,398.154053 460.769653,393.251923 464.943298,388.464478 
	C469.733582,389.091034 474.552582,389.978882 479.279480,391.218262 
	C480.056915,391.422089 480.429688,393.169556 480.991699,394.608521 
z"/>
<path fill="#456CDF" opacity="1.000000" stroke="none" 
	d="
M575.031006,415.046234 
	C563.504517,410.209106 551.978088,405.371979 540.221252,400.272797 
	C546.802917,399.013428 553.588806,397.745941 560.433228,397.083801 
	C570.102600,396.148376 579.817810,395.692383 589.505859,394.935486 
	C590.563354,394.852875 591.571533,394.138519 592.800842,393.378296 
	C596.217529,393.039429 599.435913,393.039429 604.097839,393.039429 
	C602.273499,395.638062 601.311707,397.008057 600.216370,398.732483 
	C600.082825,399.086945 600.060120,399.158722 599.713806,399.149170 
	C597.241943,401.420807 595.116394,403.701996 592.767090,406.057831 
	C592.543396,406.132477 592.102478,406.299957 591.752014,406.242065 
	C587.666748,409.529572 583.931885,412.874969 579.816223,416.174530 
	C577.967285,415.767853 576.499146,415.407043 575.031006,415.046234 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M446.984528,405.035431 
	C446.543030,405.024902 446.101562,405.014374 445.240417,404.622681 
	C442.307953,404.215515 439.638885,403.645599 437.324585,404.310547 
	C434.818817,405.030518 433.609039,404.773071 433.028229,402.252747 
	C432.699921,400.828125 432.337769,399.411285 431.990906,397.990967 
	C433.147095,397.408051 434.303284,396.825165 435.750824,396.094055 
	C437.734406,395.931580 439.426605,395.917328 441.704163,396.129395 
	C442.864532,395.912872 443.439636,395.470032 444.014709,395.027161 
	C444.014709,395.027161 443.985931,395.002136 444.384460,395.006165 
	C445.851410,395.020172 446.919800,395.030151 448.243835,395.302673 
	C452.651764,398.665833 452.020142,402.614227 446.984528,405.035431 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M447.054626,405.372681 
	C452.020142,402.614227 452.651764,398.665833 448.254669,394.984070 
	C448.024139,393.980835 448.038391,393.558746 448.052612,393.136688 
	C449.425171,393.090179 450.797760,393.043640 452.582428,393.003906 
	C453.307495,395.934906 453.888733,398.864166 453.834320,401.781525 
	C453.800873,403.576050 453.276093,405.956635 452.058289,406.924286 
	C451.247375,407.568665 448.825531,406.185577 447.054626,405.372681 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M447.677216,393.094421 
	C448.038391,393.558746 448.024139,393.980835 447.999054,394.721497 
	C446.919800,395.030151 445.851410,395.020172 444.382202,394.987244 
	C445.088196,394.326904 446.195007,393.689545 447.677216,393.094421 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M443.719482,395.023499 
	C443.439636,395.470032 442.864532,395.912872 442.127808,396.113434 
	C442.452209,395.587341 442.938232,395.303558 443.719482,395.023499 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M431.564819,397.991333 
	C432.337769,399.411285 432.699921,400.828125 433.028229,402.252747 
	C433.609039,404.773071 434.818817,405.030518 437.324585,404.310547 
	C439.638885,403.645599 442.307953,404.215515 444.909363,404.613037 
	C436.790314,405.193054 433.022491,408.385864 432.987671,415.907227 
	C432.966156,417.076355 432.945343,417.499573 432.924500,417.922760 
	C431.959167,417.301910 430.620972,416.903381 430.094696,416.020752 
	C428.086700,412.652924 426.800903,408.742676 424.332611,405.800873 
	C422.716766,403.875092 419.589569,403.099243 417.005646,402.186920 
	C416.358978,401.958588 414.428497,403.201935 414.436920,403.748016 
	C414.452911,404.779968 415.223907,406.085144 416.086548,406.753723 
	C417.453186,407.812988 419.537201,408.115631 420.591522,409.355774 
	C421.444397,410.359009 421.886902,412.562195 421.372131,413.731598 
	C418.987823,419.147949 411.175232,421.433502 406.174164,418.499176 
	C405.985504,418.388489 405.585480,418.638123 404.735535,418.952209 
	C403.189484,419.293976 402.191772,419.403381 401.194092,419.512787 
	C401.449432,416.950592 401.704742,414.388367 401.957001,411.402863 
	C402.303955,409.246704 402.653961,407.513885 403.002319,405.395874 
	C404.860291,403.837219 406.719971,402.663727 408.798096,401.248535 
	C416.390625,400.001770 423.764679,398.996765 431.564819,397.991333 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M591.835815,423.771423 
	C592.029297,423.817139 592.222778,423.862854 592.882385,424.195312 
	C593.890991,424.679504 594.433411,424.876984 594.982056,425.482025 
	C595.305908,428.846802 595.623657,431.804016 595.660767,434.964722 
	C589.497620,442.046509 583.195190,448.619446 577.890198,455.917542 
	C574.712402,460.289337 573.094299,465.794891 570.379578,470.887604 
	C564.325195,470.990082 558.666382,470.995850 553.306885,470.716309 
	C556.415222,470.294159 559.240112,469.907288 562.029114,470.084351 
	C565.429871,470.300171 567.484436,469.349762 568.859436,465.933899 
	C570.446167,461.992157 572.413940,458.039856 575.008545,454.709320 
	C578.253052,450.544586 576.738525,446.598541 576.612976,442.080353 
	C573.539795,444.000671 571.221497,445.449280 568.944824,446.556793 
	C576.602966,438.734253 584.219421,431.252838 591.835815,423.771423 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M594.938965,422.946716 
	C601.232483,413.722015 607.429932,404.428650 613.920288,395.344574 
	C614.925293,393.937897 617.271912,393.489807 619.978882,392.092041 
	C619.742737,395.340424 619.627319,396.928223 619.710083,399.118011 
	C619.647888,401.165375 619.387390,402.610748 619.126892,404.056091 
	C619.126892,404.056091 618.987366,403.968231 618.574341,404.045410 
	C615.450867,412.755920 612.740417,421.389221 609.823242,430.067810 
	C609.616516,430.113098 609.195557,430.157135 608.860718,429.984406 
	C604.267212,427.290192 600.413330,423.826782 594.998779,423.998779 
	C595.000000,424.000000 595.002441,424.002167 594.993286,423.737915 
	C594.969177,423.298035 594.954102,423.122406 594.938965,422.946716 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M591.980408,423.516296 
	C584.219421,431.252838 576.602966,438.734253 568.966797,446.563477 
	C568.947144,446.911255 568.920715,446.907288 568.583862,446.956024 
	C565.859863,449.348724 563.472778,451.692688 560.879639,454.253906 
	C560.069763,455.225952 559.465942,455.980804 558.639526,456.777924 
	C558.416992,456.820190 557.987610,456.966614 557.643127,456.914062 
	C557.240601,455.485962 556.607300,453.731750 557.221436,452.798706 
	C560.316833,448.096161 563.737366,443.607666 567.043823,439.044067 
	C567.043823,439.044067 567.002441,439.014069 567.358215,438.982574 
	C572.808838,433.295624 577.903748,427.640167 583.269531,421.874786 
	C584.048523,421.188141 584.556641,420.611450 585.465210,420.027496 
	C588.195801,420.370300 590.525879,420.720398 592.856018,421.070496 
	C592.612305,421.800690 592.368652,422.530914 591.980408,423.516296 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M593.026245,420.756836 
	C590.525879,420.720398 588.195801,420.370300 585.406311,419.709656 
	C584.334900,418.908295 583.722839,418.417511 583.081848,417.692383 
	C583.052917,417.458008 582.981934,416.991028 583.035400,416.649292 
	C586.093445,412.971680 589.097961,409.635834 592.102478,406.299957 
	C592.102478,406.299957 592.543396,406.132477 593.113037,406.035248 
	C595.808472,403.678253 597.934265,401.418488 600.060120,399.158691 
	C600.060120,399.158722 600.082825,399.086945 600.117310,399.069397 
	C602.588989,397.066254 605.110596,395.173370 607.432068,393.060608 
	C608.855530,391.765198 609.972290,390.132874 611.226807,388.651825 
	C611.876831,389.029388 612.526917,389.406982 613.177002,389.784546 
	C612.324951,391.796234 611.780029,394.018799 610.568481,395.783661 
	C604.878174,404.072357 599.004639,412.235291 593.026245,420.756836 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M619.462524,403.975067 
	C619.387390,402.610748 619.647888,401.165375 619.953552,399.349091 
	C620.507141,398.105591 621.015503,397.233032 621.769897,396.167603 
	C621.276733,398.614502 620.537476,401.254272 619.462524,403.975067 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M594.975891,425.074432 
	C594.433411,424.876984 593.890991,424.679504 593.176636,424.243713 
	C593.004822,424.005341 593.476013,423.963776 593.975586,423.974915 
	C594.650940,423.991394 594.826721,423.996765 595.002441,424.002167 
	C595.002441,424.002167 595.000000,424.000000 594.997437,424.268066 
	C594.988525,424.715576 594.982178,424.895020 594.975891,425.074432 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M622.749939,393.066284 
	C622.896606,393.287445 622.727844,393.559174 622.261841,393.900879 
	C622.087280,393.673950 622.243958,393.389282 622.749939,393.066284 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M594.993286,423.737915 
	C594.826721,423.996765 594.650940,423.991394 594.211914,423.963867 
	C594.071472,423.637329 594.263062,423.385071 594.731140,423.065826 
	C594.954102,423.122406 594.969177,423.298035 594.993286,423.737915 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M509.036865,533.066223 
	C507.667511,532.423035 506.298157,531.779846 504.928802,531.136658 
	C505.037262,530.831970 505.145752,530.527283 505.254211,530.222595 
	C520.859192,531.147217 536.464111,532.071838 552.534668,532.998413 
	C553.000305,533.000305 553.007324,533.007324 553.001099,533.366150 
	C552.957397,534.439148 552.919861,535.153320 552.882324,535.867493 
	C552.882324,535.867493 552.952087,535.998596 552.474487,535.942139 
	C549.644592,535.959595 547.292297,536.033447 544.939941,536.107300 
	C542.595764,536.080444 540.251587,536.053528 537.167358,535.743652 
	C531.549011,535.565857 526.670654,535.670959 521.792297,535.776001 
	C521.110168,535.770020 520.428040,535.764038 519.193115,535.461182 
	C515.439148,534.464905 512.238037,533.765564 509.036865,533.066223 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M577.019165,538.489197 
	C574.141602,540.639282 571.198120,542.293457 568.384949,544.145630 
	C566.107666,545.644958 563.987305,547.382568 561.444824,549.058838 
	C561.435730,548.212524 561.594116,547.179871 562.151855,546.453979 
	C566.617371,540.642151 566.299988,538.389465 560.582520,534.241089 
	C561.884155,533.920959 562.947021,533.930298 564.009949,533.939575 
	C567.059875,534.294434 570.102295,534.805237 573.162231,534.952026 
	C575.379822,535.058350 577.231689,535.169006 577.019165,538.489197 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M509.029419,533.429688 
	C512.238037,533.765564 515.439148,534.464905 518.852173,535.451172 
	C519.043884,539.560669 519.023865,543.383179 518.991577,547.624390 
	C515.749023,544.225281 512.518738,540.407532 509.124298,536.283386 
	C508.980774,535.249084 509.001373,534.521057 509.029419,533.429688 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M545.011719,536.449646 
	C547.292297,536.033447 549.644592,535.959595 552.428650,535.883484 
	C553.460876,538.074585 554.335938,540.239197 554.569397,542.470947 
	C554.737305,544.075928 554.049133,545.770508 553.742859,547.425110 
	C552.336060,546.606201 550.479797,546.108704 549.616272,544.901245 
	C547.826050,542.397888 546.563171,539.517456 545.011719,536.449646 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M564.303223,533.682495 
	C562.947021,533.930298 561.884155,533.920959 560.423584,533.917603 
	C558.631653,533.923401 557.237427,533.923157 555.244751,533.708618 
	C554.099976,533.331909 553.553650,533.169617 553.007324,533.007324 
	C553.007324,533.007324 553.000305,533.000305 553.003784,533.004272 
	C553.462891,532.386719 553.907593,531.233582 554.375549,531.224060 
	C560.376953,531.101990 566.381775,531.143494 572.707703,531.963257 
	C570.003906,532.450623 567.300171,532.937988 564.303223,533.682495 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M522.214661,535.895630 
	C526.670654,535.670959 531.549011,535.565857 536.727722,535.753052 
	C532.231079,536.035217 527.434021,536.025146 522.214661,535.895630 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M553.001099,533.366150 
	C553.553650,533.169617 554.099976,533.331909 554.827026,533.718018 
	C554.522400,534.581604 554.037048,535.221375 553.216980,535.864258 
	C552.919861,535.153320 552.957397,534.439148 553.001099,533.366150 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M522.861328,552.672729 
	C522.285522,552.546387 521.695007,552.109863 521.053711,551.378418 
	C521.617493,551.509827 522.231995,551.936157 522.861328,552.672729 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M496.879883,522.079712 
	C495.274231,519.291443 493.668579,516.503113 492.429718,513.361206 
	C497.115845,512.610474 501.514069,512.583740 505.726868,511.686584 
	C508.555969,511.084076 510.653839,508.922821 509.644226,505.433502 
	C508.824554,502.600708 507.882965,499.803253 507.199310,496.758362 
	C508.939484,497.181702 510.475861,497.836823 512.027161,498.498322 
	C515.598938,493.274231 520.873047,492.588776 524.670593,496.903381 
	C522.070374,497.309143 518.145813,496.031097 519.289062,500.080414 
	C519.930298,502.351807 523.135193,505.515991 525.090698,505.450745 
	C528.745239,505.328857 528.974304,506.800323 529.000122,509.379303 
	C529.015015,510.867950 528.891785,512.372742 529.066284,513.842285 
	C529.591858,518.270325 533.092041,516.876343 535.637878,516.917908 
	C538.523438,516.965027 539.447144,515.295593 538.362427,512.628967 
	C536.249146,507.434021 534.099976,502.253693 532.368408,497.101135 
	C540.428101,499.550903 547.592590,498.958252 554.989563,495.307373 
	C558.690186,493.480835 563.549744,493.600922 567.888245,493.582275 
	C572.118591,493.564117 576.372375,494.373138 580.575378,495.065155 
	C582.133423,495.321655 583.576233,496.277710 584.627441,496.936096 
	C579.390869,496.496124 575.997131,496.994293 575.631958,503.385101 
	C575.372375,507.927734 572.931152,512.340820 571.499451,516.823120 
	C570.630981,519.542114 569.830811,522.283020 569.000000,525.014038 
	C560.339905,525.997253 551.698669,527.255005 543.012024,527.851624 
	C538.456360,528.164490 533.823853,527.270264 529.221130,527.007629 
	C522.637207,526.631958 516.018921,526.611084 509.473328,525.902527 
	C505.498352,525.472229 501.632721,524.031433 497.325256,522.785034 
	C496.932556,522.525452 496.879883,522.079712 496.879883,522.079712 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M569.426453,525.034546 
	C569.830811,522.283020 570.630981,519.542114 571.499451,516.823120 
	C572.931152,512.340820 575.372375,507.927734 575.631958,503.385101 
	C575.997131,496.994293 579.390869,496.496124 584.991455,496.955597 
	C586.531433,496.961945 587.263245,496.970154 588.249390,497.249054 
	C590.989685,497.049683 593.744019,500.987000 596.349121,497.048645 
	C597.116821,497.003235 597.555115,496.971313 597.993408,496.939392 
	C605.798157,495.335602 613.578186,493.593506 621.419373,492.193939 
	C624.708923,491.606812 628.321777,491.385223 628.992310,496.591187 
	C625.812683,500.973328 622.106140,502.078278 616.685425,501.794037 
	C611.439148,501.518890 605.930115,503.947723 600.732178,505.752472 
	C596.219360,507.319336 591.980164,509.674438 587.244263,511.487274 
	C584.465271,508.234833 581.785828,507.993378 580.052063,511.558899 
	C577.982727,515.814270 576.631104,520.418701 574.968628,524.871948 
	C573.263367,524.932983 571.558167,524.993958 569.426453,525.034546 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M575.316895,524.887329 
	C576.631104,520.418701 577.982727,515.814270 580.052063,511.558899 
	C581.785828,507.993378 584.465271,508.234833 586.934204,511.645447 
	C585.696472,514.063477 584.392944,516.129395 583.038452,518.529053 
	C580.546692,520.876099 578.106018,522.889343 575.316895,524.887329 
z"/>
<path fill="#696C8C" opacity="1.000000" stroke="none" 
	d="
M496.531189,522.087646 
	C496.879883,522.079712 496.932556,522.525452 496.951904,522.748718 
	C495.889740,523.286499 494.808289,523.601013 493.368744,523.878906 
	C494.067963,523.260010 495.125244,522.677795 496.531189,522.087646 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M598.042175,496.601471 
	C597.555115,496.971313 597.116821,497.003235 595.955444,497.016846 
	C592.819885,496.991821 590.407532,496.985107 587.995117,496.978394 
	C587.263245,496.970154 586.531433,496.961945 585.435547,496.934235 
	C583.576233,496.277710 582.133423,495.321655 580.575378,495.065155 
	C576.372375,494.373138 572.118591,493.564117 567.888245,493.582275 
	C563.549744,493.600922 558.690186,493.480835 554.989563,495.307373 
	C547.592590,498.958252 540.428101,499.550903 531.938477,497.084351 
	C529.105286,496.976410 527.104736,496.919098 525.104248,496.861786 
	C520.873047,492.588776 515.598938,493.274231 512.027161,498.498322 
	C510.475861,497.836823 508.939484,497.181702 506.742645,496.756805 
	C497.718170,497.670593 489.354218,498.354156 480.990234,499.037720 
	C479.556305,498.355652 478.122375,497.673615 476.328491,496.966980 
	C474.844482,495.047241 473.720459,493.152100 472.268005,491.103821 
	C469.635925,487.502197 467.332245,484.053680 465.444641,480.271393 
	C468.577179,479.936310 471.293610,479.934967 474.301758,480.225403 
	C478.390137,480.681213 482.186859,480.845276 486.033203,481.367981 
	C488.139862,486.113800 492.146118,485.886200 496.417206,485.941833 
	C498.511475,485.653625 500.225739,485.354828 502.041718,485.412842 
	C503.775726,486.149536 505.408020,486.529388 507.410339,486.925354 
	C508.520996,486.947815 509.261658,486.954224 510.200623,487.212158 
	C511.282196,486.990204 512.165466,486.516724 513.416626,486.037537 
	C514.520020,486.028595 515.255554,486.025330 516.295349,486.303070 
	C522.021240,487.106384 527.476868,487.405701 532.852783,488.227142 
	C536.629211,488.804199 540.247009,490.247070 543.391724,486.596832 
	C543.895691,486.011871 545.560791,486.332306 546.680725,486.391663 
	C549.483215,486.540161 552.297729,487.081238 555.077515,486.926636 
	C564.486511,486.403442 573.919189,485.954071 583.266724,484.838348 
	C587.409973,484.343811 592.474609,484.970184 595.494019,480.063782 
	C599.651672,480.085236 603.350952,480.125458 607.050232,480.165710 
	C607.040833,481.511078 607.031433,482.856415 606.672607,484.653381 
	C605.565918,486.404541 604.808594,487.704102 604.051270,489.003662 
	C602.064514,491.423645 600.077698,493.843597 598.042175,496.601471 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M480.991760,499.417938 
	C489.354218,498.354156 497.718170,497.670593 506.538818,496.988586 
	C507.882965,499.803253 508.824554,502.600708 509.644226,505.433502 
	C510.653839,508.922821 508.555969,511.084076 505.726868,511.686584 
	C501.514069,512.583740 497.115845,512.610474 492.381958,513.014038 
	C487.993958,508.874817 482.376923,506.093292 480.991760,499.417938 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M474.010071,479.933624 
	C471.293610,479.934967 468.577179,479.936310 465.403717,479.918457 
	C463.160950,479.335693 461.375183,478.772125 459.374023,477.671051 
	C459.766724,473.114746 460.374817,469.095947 460.982910,465.077179 
	C462.060669,465.053284 463.138458,465.029388 464.990417,465.052979 
	C480.147095,470.426544 495.144531,471.594025 510.300995,471.686096 
	C515.880981,471.426666 521.460938,471.167236 527.543640,471.288025 
	C527.740356,473.231812 527.564880,474.835815 527.095581,476.348633 
	C526.503784,478.256226 525.672119,480.089386 524.549683,481.951324 
	C521.785706,481.644958 519.415894,481.341919 516.614868,481.022278 
	C509.832214,480.336395 503.494324,479.424072 497.125366,479.068573 
	C491.172607,478.736298 485.185577,478.925446 479.216431,479.036499 
	C477.476013,479.068878 475.745270,479.620209 474.010071,479.933624 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M460.541016,465.057373 
	C460.374817,469.095947 459.766724,473.114746 459.051910,477.486969 
	C450.492767,475.747711 442.040344,473.654999 433.301392,471.319580 
	C433.822815,468.130280 434.630737,465.183746 435.607086,461.623077 
	C434.256775,460.582550 432.259644,459.368164 430.722992,457.723572 
	C429.946747,456.892761 430.003876,455.283264 429.684143,454.025879 
	C430.866913,453.954315 432.423920,453.365387 433.166412,453.902466 
	C436.078125,456.008636 438.635010,458.600830 441.515594,460.756073 
	C443.643829,462.348480 446.069489,463.543396 449.143066,464.922363 
	C451.247162,464.914795 452.570831,464.900269 453.894470,464.885742 
	C455.962677,464.936340 458.030914,464.986938 460.541016,465.057373 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M671.057861,402.604614 
	C670.453979,402.514893 669.775574,402.112946 669.034485,401.395386 
	C669.642334,401.483978 670.312866,401.888184 671.057861,402.604614 
z"/>
<path fill="#23253A" opacity="1.000000" stroke="none" 
	d="
M394.616455,416.991730 
	C395.969330,419.257874 396.919708,421.535645 397.912109,423.795013 
	C399.668762,427.794342 397.962494,430.564209 393.622345,429.850372 
	C390.835236,429.391968 388.276642,427.544067 385.304138,426.160797 
	C385.473175,421.741241 389.490753,417.824860 394.616455,416.991730 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M624.008667,464.967377 
	C622.135132,459.134399 627.234619,457.743988 630.625610,455.388000 
	C629.939209,457.886169 628.943115,460.064056 627.946960,462.241943 
	C628.507141,462.590820 629.067322,462.939728 629.627502,463.288635 
	C633.085327,460.333832 636.833496,457.648499 639.907166,454.337097 
	C642.275391,451.785706 645.701111,448.936951 642.202454,444.610352 
	C641.742432,444.041473 642.114258,441.935089 642.764160,441.472198 
	C647.404358,438.167023 652.216736,435.103516 656.974365,431.963135 
	C654.116089,434.731537 651.648132,438.185455 648.299194,440.091888 
	C644.262146,442.390137 643.649292,444.661896 645.964233,448.646027 
	C645.242188,451.174500 644.628601,453.417145 643.687500,455.512756 
	C641.342468,460.734772 642.579590,463.804230 647.856995,465.915894 
	C649.021057,466.381653 649.933533,467.476135 650.984863,468.658447 
	C650.440979,469.237274 649.873962,469.438782 649.137085,469.900391 
	C641.872253,471.728821 634.777222,473.297211 627.317627,474.471588 
	C625.971619,471.040833 624.990173,468.004089 624.008667,464.967377 
z"/>
<path fill="#1E1E26" opacity="1.000000" stroke="none" 
	d="
M623.579834,464.979126 
	C624.990173,468.004089 625.971619,471.040833 626.928833,474.456360 
	C626.485352,474.872375 626.066101,474.909637 625.083862,474.701965 
	C622.022095,475.330841 619.523315,476.204681 617.024536,477.078522 
	C613.958313,478.071503 610.892151,479.064484 607.438110,480.111572 
	C603.350952,480.125458 599.651672,480.085236 595.035889,480.023315 
	C590.086609,479.665924 586.053833,479.330139 581.977539,478.632690 
	C580.629089,475.836090 579.324097,473.401154 578.430298,470.956604 
	C586.278137,469.508087 593.714722,468.069214 601.533569,466.698608 
	C604.236145,466.448151 606.556641,466.129425 609.299988,465.864258 
	C612.102478,465.561279 614.481995,465.204681 617.146606,464.889343 
	C617.623108,464.946014 617.814514,464.961456 618.005920,464.976868 
	C619.720947,464.981537 621.435913,464.986176 623.579834,464.979126 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M604.464111,488.999420 
	C604.808594,487.704102 605.565918,486.404541 606.678711,485.057068 
	C613.586853,482.772034 620.014526,479.775055 628.603333,481.697205 
	C620.425476,486.059296 612.729797,487.767548 604.464111,488.999420 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M617.416626,477.064209 
	C619.523315,476.204681 622.022095,475.330841 624.767456,474.714783 
	C622.612305,475.664978 620.210571,476.357422 617.416626,477.064209 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M656.972900,431.533264 
	C652.216736,435.103516 647.404358,438.167023 642.764160,441.472198 
	C642.114258,441.935089 641.742432,444.041473 642.202454,444.610352 
	C645.701111,448.936951 642.275391,451.785706 639.907166,454.337097 
	C636.833496,457.648499 633.085327,460.333832 629.627502,463.288635 
	C629.067322,462.939728 628.507141,462.590820 627.946960,462.241943 
	C628.943115,460.064056 629.939209,457.886169 630.969604,455.346222 
	C631.003784,454.984131 631.053528,454.548615 631.374023,454.250641 
	C632.123779,453.294281 632.552979,452.635925 632.982178,451.977539 
	C632.982178,451.977539 633.037720,451.539520 633.361755,451.240173 
	C634.095520,450.276367 634.505310,449.611908 634.915100,448.947449 
	C634.954224,448.535187 634.993347,448.122925 635.410400,447.284424 
	C637.491272,442.222504 639.194214,437.586792 640.897095,432.951080 
	C640.897095,432.951080 640.968140,432.487823 641.330261,432.188171 
	C642.115479,430.918610 642.538574,429.948700 642.961670,428.978821 
	C643.611816,427.866852 644.261902,426.754852 645.287354,425.264252 
	C646.422119,423.579437 647.181580,422.273254 647.941040,420.967041 
	C648.289551,419.887177 648.638062,418.807312 649.368530,417.377014 
	C651.264099,418.318481 652.915039,419.484711 654.248718,420.941345 
	C655.344055,422.137695 656.062561,423.679047 656.948120,425.067444 
	C656.955872,427.079407 656.963684,429.091370 656.972900,431.533264 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M621.442261,436.661072 
	C614.888977,431.094940 615.063171,426.507233 621.896606,421.186279 
	C628.595459,424.911865 628.929932,431.916748 631.410522,439.782166 
	C627.391663,438.565887 624.581787,437.715485 621.442261,436.661072 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M618.166016,464.618622 
	C617.814514,464.961456 617.623108,464.946014 617.182251,464.480652 
	C617.032532,455.412140 613.398621,452.438995 605.695557,454.814789 
	C604.910645,452.351685 604.402222,450.136810 603.969604,447.553528 
	C605.762085,441.509125 607.478821,435.833130 609.195557,430.157135 
	C609.195557,430.157135 609.616516,430.113098 609.953857,430.244751 
	C610.851013,430.226074 611.410889,430.075775 611.970703,429.925476 
	C610.309082,434.533356 608.647583,439.141235 607.009521,443.683960 
	C615.259094,448.852264 619.880554,457.391449 618.166016,464.618622 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M656.969482,424.647156 
	C656.062561,423.679047 655.344055,422.137695 654.248718,420.941345 
	C652.915039,419.484711 651.264099,418.318481 649.378662,417.013855 
	C647.584839,415.225677 647.213684,412.333282 643.603882,413.132416 
	C641.975525,413.492889 640.022827,412.388062 638.217285,411.948059 
	C639.917725,409.973053 641.319519,406.775391 643.379456,406.272308 
	C647.962463,405.153015 651.864563,408.027191 653.761047,411.927887 
	C655.578979,415.667114 655.971802,420.099213 656.969482,424.647156 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M663.497314,418.616547 
	C662.914795,416.374817 662.224365,414.465302 662.149719,412.532013 
	C662.086304,410.890503 662.203125,408.509857 663.211060,407.758362 
	C664.289856,406.954010 667.753174,407.179108 668.004456,407.814087 
	C669.017578,410.374207 669.904968,413.417358 669.363586,415.969116 
	C669.108276,417.172791 667.392456,421.557220 663.497314,418.616547 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M611.989868,429.568726 
	C611.410889,430.075775 610.851013,430.226074 610.160583,430.199463 
	C612.740417,421.389221 615.450867,412.755920 618.494019,404.027039 
	C618.570190,405.369110 618.313782,406.806793 617.790405,408.782898 
	C617.402710,409.900360 617.281982,410.479431 617.161255,411.058472 
	C617.080139,412.071320 616.999084,413.084198 616.649780,414.717346 
	C615.309875,418.583832 614.238220,421.830017 613.166504,425.076202 
	C612.780762,426.454803 612.394897,427.833374 611.989868,429.568726 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M640.528320,432.976257 
	C639.194214,437.586792 637.491272,442.222504 635.425232,446.974121 
	C634.550415,441.593964 635.902832,436.772278 640.528320,432.976257 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M613.543823,425.037964 
	C614.238220,421.830017 615.309875,418.583832 616.649780,415.098633 
	C615.919006,418.239655 614.920105,421.619720 613.543823,425.037964 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M647.593445,421.020660 
	C647.181580,422.273254 646.422119,423.579437 645.326782,424.933533 
	C645.742554,423.679077 646.494202,422.376678 647.593445,421.020660 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M642.623657,429.045624 
	C642.538574,429.948700 642.115479,430.918610 641.353516,431.954987 
	C641.438416,431.051788 641.862061,430.082092 642.623657,429.045624 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M632.660889,452.035095 
	C632.552979,452.635925 632.123779,453.294281 631.386108,454.031830 
	C631.498352,453.438202 631.918945,452.765411 632.660889,452.035095 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M617.432190,411.002808 
	C617.281982,410.479431 617.402710,409.900360 617.778442,409.151978 
	C617.923340,409.637482 617.813232,410.292328 617.432190,411.002808 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M634.602051,449.015045 
	C634.505310,449.611908 634.095520,450.276367 633.376831,451.019897 
	C633.474915,450.426849 633.881958,449.754761 634.602051,449.015045 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M510.179932,471.335022 
	C495.144531,471.594025 480.147095,470.426544 465.394714,465.046936 
	C464.088379,460.246460 466.657928,456.689362 468.628204,452.698578 
	C470.743317,448.414429 471.597443,443.507751 473.387634,438.437378 
	C477.187408,437.838348 477.024841,435.376129 476.990417,432.996277 
	C477.279541,432.526642 477.568695,432.056976 478.196381,431.174622 
	C479.385559,430.479462 480.236206,430.197052 481.086823,429.914612 
	C485.417389,434.982697 489.739777,440.057770 494.084351,445.113831 
	C495.145172,446.348328 496.278503,447.520508 497.904663,448.889404 
	C498.430786,449.057495 498.866455,449.085266 498.899994,449.442108 
	C500.953491,452.200165 502.973450,454.601410 504.996704,457.001343 
	C505.000000,457.000061 504.997009,457.006470 504.997009,457.006470 
	C506.684296,461.665619 508.371582,466.324768 510.179932,471.335022 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M432.846802,418.191864 
	C432.945343,417.499573 432.966156,417.076355 432.993774,416.335083 
	C435.686951,413.779297 438.786316,411.872009 440.926331,409.197174 
	C442.578613,407.131897 443.872803,406.381897 445.644745,408.176636 
	C450.836334,413.435211 455.839417,418.879822 460.575684,424.616455 
	C454.869568,426.372681 453.771393,429.139893 457.014099,433.097412 
	C456.062500,434.108826 455.289703,435.620636 454.125336,436.036713 
	C450.787598,437.229431 447.291138,437.978088 443.447540,438.563293 
	C440.708374,433.014221 438.528290,427.727112 435.942383,422.646545 
	C435.308319,421.400757 433.348022,420.830017 431.997467,419.948914 
	C432.254700,419.452942 432.511932,418.956970 432.846802,418.191864 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M431.694672,420.127716 
	C433.348022,420.830017 435.308319,421.400757 435.942383,422.646545 
	C438.528290,427.727112 440.708374,433.014221 443.068604,438.620514 
	C442.669800,439.681793 442.396149,440.668579 441.787018,440.964386 
	C435.343445,444.093201 433.067078,437.439270 428.917206,435.244843 
	C426.087189,433.748352 425.464752,431.176239 427.388397,428.135742 
	C428.949005,425.669067 430.073792,422.926636 431.694672,420.127716 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M453.921875,464.513000 
	C452.570831,464.900269 451.247162,464.914795 449.531128,464.883240 
	C447.041687,462.002411 445.271759,458.831421 442.777466,456.405090 
	C438.029144,451.786224 437.834412,449.795502 443.441193,443.607330 
	C445.372589,447.008240 447.129333,450.154541 448.943298,453.267487 
	C449.591156,454.379333 450.150543,455.989075 451.140320,456.377289 
	C455.215363,457.975586 454.520874,461.020721 453.921875,464.513000 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M457.399414,433.073212 
	C453.771393,429.139893 454.869568,426.372681 460.620117,424.963348 
	C462.247375,426.278290 463.489563,427.611237 464.731720,428.944214 
	C462.416077,430.312469 460.100433,431.680725 457.399414,433.073212 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M401.124390,419.837219 
	C402.191772,419.403381 403.189484,419.293976 404.564728,419.214233 
	C405.085846,419.542542 405.348450,419.838928 405.353973,420.140076 
	C405.395386,422.394684 405.491974,424.661560 405.253754,426.890656 
	C405.225098,427.158844 402.398438,427.436279 402.378601,427.352295 
	C401.818359,424.983582 401.452423,422.568848 401.124390,419.837219 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M467.776855,425.205139 
	C469.685089,428.395416 471.350922,431.793579 473.001221,435.595520 
	C471.168610,432.470551 469.351532,428.941803 467.776855,425.205139 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M476.695068,433.137024 
	C477.024841,435.376129 477.187408,437.838348 473.389771,438.015991 
	C472.995117,437.581940 472.984100,437.133881 472.985718,436.350494 
	C474.132141,435.102692 475.265961,434.190216 476.695068,433.137024 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M466.718689,420.182007 
	C467.243683,420.496857 467.568848,420.990814 467.860168,421.798706 
	C467.390503,421.528839 466.954651,420.944946 466.718689,420.182007 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M578.019104,470.966217 
	C579.324097,473.401154 580.629089,475.836090 581.541626,478.642517 
	C575.900818,479.383667 570.657471,479.869293 565.402954,480.100342 
	C551.915405,480.693298 538.618713,485.498718 524.943970,481.954651 
	C525.672119,480.089386 526.503784,478.256226 527.095581,476.348633 
	C527.564880,474.835815 527.740356,473.231812 528.007080,471.319336 
	C528.147888,470.905396 528.327942,470.840393 528.766052,470.648254 
	C534.101440,470.589111 539.178772,470.657135 545.123657,470.722595 
	C548.330017,470.813904 550.668762,470.907745 553.007568,471.001617 
	C558.666382,470.995850 564.325195,470.990082 570.837646,470.953186 
	C573.800537,470.936768 575.909790,470.951508 578.019104,470.966217 
z"/>
<path fill="#456CDF" opacity="1.000000" stroke="none" 
	d="
M553.306885,470.716309 
	C550.668762,470.907745 548.330017,470.813904 545.579590,470.577087 
	C548.345886,467.163513 551.523804,463.892883 555.171936,460.273560 
	C556.429016,459.289307 557.215820,458.653748 558.232483,457.871399 
	C558.595581,457.394989 558.728821,457.065338 558.862122,456.735657 
	C559.465942,455.980804 560.069763,455.225952 561.236328,454.244080 
	C564.172913,451.647156 566.546814,449.277222 568.920715,446.907288 
	C568.920715,446.907288 568.947144,446.911255 568.925171,446.904602 
	C571.221497,445.449280 573.539795,444.000671 576.612976,442.080353 
	C576.738525,446.598541 578.253052,450.544586 575.008545,454.709320 
	C572.413940,458.039856 570.446167,461.992157 568.859436,465.933899 
	C567.484436,469.349762 565.429871,470.300171 562.029114,470.084351 
	C559.240112,469.907288 556.415222,470.294159 553.306885,470.716309 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M582.981934,416.991028 
	C582.981934,416.991028 583.052917,417.458008 583.045410,418.099060 
	C583.024841,419.821686 583.011719,420.903198 582.998596,421.984741 
	C577.903748,427.640167 572.808838,433.295624 567.379517,439.001892 
	C567.932739,436.639862 568.775757,434.209167 569.725098,431.820801 
	C570.293457,430.391022 571.028137,429.027374 571.688049,427.633972 
	C570.127441,427.173218 568.566833,426.712463 566.262695,426.032196 
	C566.101868,420.449585 571.042114,418.488159 574.958496,415.384155 
	C576.499146,415.407043 577.967285,415.767853 580.025024,416.376892 
	C581.403687,416.747101 582.192810,416.869049 582.981934,416.991028 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M558.002625,458.018127 
	C557.215820,458.653748 556.429016,459.289307 555.337708,459.976501 
	C554.479065,459.709656 553.617004,459.520081 553.420959,459.051697 
	C552.198730,456.132172 556.029297,444.870667 558.741455,443.273804 
	C561.230347,441.808441 563.771362,440.431427 566.666626,439.029785 
	C563.737366,443.607666 560.316833,448.096161 557.221436,452.798706 
	C556.607300,453.731750 557.240601,455.485962 557.646606,457.177063 
	C557.997314,457.667786 558.000000,457.842957 558.002625,458.018127 
z"/>
<path fill="#2B52C8" opacity="1.000000" stroke="none" 
	d="
M498.866455,449.085266 
	C498.866455,449.085266 498.430786,449.057495 498.217499,449.010925 
	C497.991882,445.494720 500.343018,445.173492 502.214172,446.773865 
	C508.556244,452.198059 514.641418,457.932617 520.626038,463.752991 
	C521.431396,464.536255 521.453186,467.655151 521.238403,467.720398 
	C519.122803,468.363403 516.794189,469.101807 514.704468,468.750763 
	C513.338501,468.521332 512.082153,466.673920 511.102081,465.316376 
	C509.181549,462.656189 507.488373,459.831879 505.349060,457.040985 
	C504.997009,457.006470 505.000000,457.000061 505.000061,456.634979 
	C502.955566,453.875031 500.911011,451.480164 498.866455,449.085266 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M481.011475,429.592102 
	C480.236206,430.197052 479.385559,430.479462 478.219543,430.888794 
	C477.982239,430.201355 478.060364,429.387024 478.138428,428.572693 
	C479.070984,428.804962 480.003571,429.037262 481.011475,429.592102 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M583.035400,416.649292 
	C582.192810,416.869049 581.403687,416.747101 580.405762,416.422729 
	C583.931885,412.874969 587.666748,409.529572 591.752014,406.242065 
	C589.097961,409.635834 586.093445,412.971680 583.035400,416.649292 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M599.713806,399.149170 
	C597.934265,401.418488 595.808472,403.678253 593.336731,405.960602 
	C595.116394,403.701996 597.241943,401.420807 599.713806,399.149170 
z"/>
<path fill="#3D4662" opacity="1.000000" stroke="none" 
	d="
M578.430298,470.956604 
	C575.909790,470.951508 573.800537,470.936768 571.233154,470.856476 
	C573.094299,465.794891 574.712402,460.289337 577.890198,455.917542 
	C583.195190,448.619446 589.497620,442.046509 595.968872,435.184601 
	C597.461243,436.879639 598.320740,438.584076 599.278931,440.231079 
	C600.782166,442.815033 602.352112,445.360291 603.893799,447.921906 
	C604.402222,450.136810 604.910645,452.351685 605.698059,455.145142 
	C605.969971,456.165863 605.962891,456.608002 605.656189,457.205750 
	C603.954834,460.451019 602.553101,463.540680 601.151367,466.630341 
	C593.714722,468.069214 586.278137,469.508087 578.430298,470.956604 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M603.969604,447.553528 
	C602.352112,445.360291 600.782166,442.815033 599.278931,440.231079 
	C598.320740,438.584076 597.461243,436.879639 596.249451,434.981079 
	C595.623657,431.804016 595.305908,428.846802 594.982056,425.482025 
	C594.982178,424.895020 594.988525,424.715576 594.996216,424.266846 
	C600.413330,423.826782 604.267212,427.290192 608.860718,429.984406 
	C607.478821,435.833130 605.762085,441.509125 603.969604,447.553528 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M568.583862,446.955994 
	C566.546814,449.277222 564.172913,451.647156 561.442383,454.026886 
	C563.472778,451.692688 565.859863,449.348724 568.583862,446.955994 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M583.269531,421.874786 
	C583.011719,420.903198 583.024841,419.821686 583.074341,418.333435 
	C583.722839,418.417511 584.334900,418.908295 585.005859,419.716919 
	C584.556641,420.611450 584.048523,421.188141 583.269531,421.874786 
z"/>
<path fill="#3F62B9" opacity="1.000000" stroke="none" 
	d="
M558.232483,457.871399 
	C558.000000,457.842957 557.997314,457.667786 557.991089,457.229614 
	C557.987610,456.966614 558.416992,456.820190 558.639526,456.777954 
	C558.728821,457.065338 558.595581,457.394989 558.232483,457.871399 
z"/>
<path fill="#2D2F4C" opacity="1.000000" stroke="none" 
	d="
M524.670593,496.903381 
	C527.104736,496.919098 529.105286,496.976410 531.535767,497.050507 
	C534.099976,502.253693 536.249146,507.434021 538.362427,512.628967 
	C539.447144,515.295593 538.523438,516.965027 535.637878,516.917908 
	C533.092041,516.876343 529.591858,518.270325 529.066284,513.842285 
	C528.891785,512.372742 529.015015,510.867950 529.000122,509.379303 
	C528.974304,506.800323 528.745239,505.328857 525.090698,505.450745 
	C523.135193,505.515991 519.930298,502.351807 519.289062,500.080414 
	C518.145813,496.031097 522.070374,497.309143 524.670593,496.903381 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M588.249390,497.249084 
	C590.407532,496.985107 592.819885,496.991821 595.625977,497.030334 
	C593.744019,500.987000 590.989685,497.049683 588.249390,497.249084 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M524.549683,481.951324 
	C538.618713,485.498718 551.915405,480.693298 565.402954,480.100342 
	C570.657471,479.869293 575.900818,479.383667 581.585083,479.004211 
	C586.053833,479.330139 590.086609,479.665924 594.577515,480.042145 
	C592.474609,484.970184 587.409973,484.343811 583.266724,484.838348 
	C573.919189,485.954071 564.486511,486.403442 555.077515,486.926636 
	C552.297729,487.081238 549.483215,486.540161 546.680725,486.391663 
	C545.560791,486.332306 543.895691,486.011871 543.391724,486.596832 
	C540.247009,490.247070 536.629211,488.804199 532.852783,488.227142 
	C527.476868,487.405701 522.021240,487.106384 516.442261,485.963928 
	C516.538635,483.908844 516.792419,482.473877 517.046143,481.038879 
	C519.415894,481.341919 521.785706,481.644958 524.549683,481.951324 
z"/>
<path fill="#1F2352" opacity="1.000000" stroke="none" 
	d="
M516.614868,481.022278 
	C516.792419,482.473877 516.538635,483.908844 516.137939,485.682983 
	C515.255554,486.025330 514.520020,486.028595 513.076660,486.024628 
	C511.579987,486.331818 510.791138,486.646210 510.002289,486.960632 
	C509.261658,486.954224 508.520996,486.947815 507.312988,486.570251 
	C505.210419,485.818024 503.575226,485.437012 501.940002,485.056030 
	C500.225739,485.354828 498.511475,485.653625 496.331848,485.597778 
	C492.572174,483.831848 489.277863,482.420593 485.983551,481.009338 
	C482.186859,480.845276 478.390137,480.681213 474.301758,480.225403 
	C475.745270,479.620209 477.476013,479.068878 479.216431,479.036499 
	C485.185577,478.925446 491.172607,478.736298 497.125366,479.068573 
	C503.494324,479.424072 509.832214,480.336395 516.614868,481.022278 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M486.033203,481.367981 
	C489.277863,482.420593 492.572174,483.831848 495.951843,485.587158 
	C492.146118,485.886200 488.139862,486.113800 486.033203,481.367981 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M502.041718,485.412842 
	C503.575226,485.437012 505.210419,485.818024 506.942993,486.554138 
	C505.408020,486.529388 503.775726,486.149536 502.041718,485.412842 
z"/>
<path fill="#292D62" opacity="1.000000" stroke="none" 
	d="
M510.200623,487.212158 
	C510.791138,486.646210 511.579987,486.331818 512.708801,486.030334 
	C512.165466,486.516724 511.282196,486.990204 510.200623,487.212158 
z"/>
<path fill="#293381" opacity="1.000000" stroke="none" 
	d="
M605.955811,457.050171 
	C605.962891,456.608002 605.969971,456.165863 605.974487,455.393372 
	C613.398621,452.438995 617.032532,455.412140 616.897217,464.439392 
	C614.481995,465.204681 612.102478,465.561279 609.272400,465.506531 
	C607.866577,462.413513 606.911194,459.731842 605.955811,457.050171 
z"/>
<path fill="#41488D" opacity="1.000000" stroke="none" 
	d="
M605.656189,457.205750 
	C606.911194,459.731842 607.866577,462.413513 608.849487,465.452942 
	C606.556641,466.129425 604.236145,466.448151 601.533569,466.698608 
	C602.553101,463.540680 603.954834,460.451019 605.656189,457.205750 
z"/>
<path fill="#2E4EAE" opacity="1.000000" stroke="none" 
	d="
M498.899994,449.442108 
	C500.911011,451.480164 502.955566,453.875031 504.996765,456.636292 
	C502.973450,454.601410 500.953491,452.200165 498.899994,449.442108 
z"/>

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
